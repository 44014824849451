<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard >
          <template slot="header">
            <h3 class="card-title">Add a New Person</h3>
            <h3 class="description">
              Add a new person to the database.
            </h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-caps-small"></i>
              <p>Person</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
              @child-englishname="englishNameValue"
              @child-englishposition="englishPositionValue"
              @child-country="countryValue"
            ></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-atom"></i>
              <p>Validation</p>
            </template>
            <second-step
              ref="step2"
              v-bind:propValidateData="this.validateData" 
              @on-validated="onStepValidated"
            ></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-pencil"></i>
              <p>Details</p>
            </template>
            <third-step
              ref="step3"
              @on-validated="onStepValidated"
              @child-generallocation="generalLocationValue"
              @child-affiliation="affiliationValue"
              @child-arabicname="arabicNameValue"
              @child-arabicposition="arabicPositionValue"
              v-bind:propCountry="this.country"
            ></third-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p>Save</p>
            </template>
            <fourth-step ref="step4" 
              v-bind:propEnglishName="this.englishName" 
              v-bind:propArabicName="this.arabicName"
              v-bind:propEnglishPosition="this.englishPosition"
              v-bind:propArabicPosition="this.arabicPosition"
              v-bind:propCountry="this.country"
              v-bind:propGeneralLocation="this.generalLocation"
              v-bind:propAffiliation="this.affiliation"
              @child-newSoul="newSoulValue"
              @on-validated="wizardComplete" ></fourth-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from './FirstStep.vue'
import SecondStep from './SecondStep.vue'
import ThirdStep from './ThirdStep.vue'
import FourthStep from './FourthStep.vue'
import swal from 'sweetalert2';
import { SimpleWizard, WizardTab } from 'src/components';
import LinkService from '@/services/LinkService'
import SoulsService from '@/services/SoulsService'
import SearchService from '@/services/SearchService'
import { Loading } from 'element-ui'

export default {
  mounted() {
  },
  data() {
    return {
      wizardModel: {},
      englishName: '',
      country:'',
      arabicName:'',
      englishPosition: '',
      arabicPosition: '',
      generalLocation:'',
      affiliation:'',
      newSoul: '',
      validateData: [],
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    SimpleWizard,
    WizardTab,
  },
  methods: {
    validateStep(ref) {
      if (ref == 'step1') {
        this.validateSoul(this.$refs[ref]._data.selects.country, encodeURI(this.$refs[ref]._data.englishPosition), encodeURI(this.$refs[ref]._data.englishName))
      }
      scroll(0,0)
      return this.$refs[ref].validate();
    },
    englishNameValue: function(params) {
      this.englishName = params;
    },
    arabicNameValue: function(params) {
      this.arabicName = params;
    },
    countryValue: function(params) {
      this.country = params;
    },
    englishPositionValue: function(params) {
      this.englishPosition = params;
    },
    arabicPositionValue: function(params) {
      this.arabicPosition = params;
    },
    generalLocationValue: function(params) {
      this.generalLocation = params;
    },
    affiliationValue: function(params) {
      this.affiliation = params;
    },
    newSoulValue: function(params) {
      this.newSoul = params;
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model }
    },
    async validateSoul(country, position, englishName) {
      let loading = Loading.service()
      let newData = await SearchService.validateSoul({
        country: country,
        position: position,
        englishName: englishName
      })
      if (newData.status != 200) {
        //something has failed
      }
      
      this.validateData = newData.data
      loading.close()
      // if (newData.data.length == 0) {
      //   // if no results were returned we can skip the next step
      //   this.onStepValidated(true, "step2")
      // }
    },
    async createLinkSoulSoul(soulID1, soulID2) {

          await LinkService.createLinkSoulSoul({
            soulID1: soulID1,
            soulID2: soulID2
          })
    
      
    },
    async createLinkEventSoul(eventID, soulID, linkType) {
      await LinkService.createLinkEventSoul({
        eventID: eventID,
        soulID: soulID,
        linkType: linkType
      })
    },
    async createLinkSoulOrganisation(soulID, organisationID) {
      await LinkService.createLinkSoulOrganisation({
        soulID: soulID,
        orgID: organisationID
      })
    },
    wizardComplete() {
      if (this.$route.params.type == "event") {
        swal({
          title: 'Link',
          text: 'Choose Below',
          input: 'radio',
          inputOptions: ({"actor":"Actor", "target":"Target"}),
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == null){
              return "Please select one"
            }
            this.createLinkEventSoul(this.$route.params.id, this.newSoul, value)
          }
        }).then((result) =>
          swal({
            title: 'Person Created',
            text: 'Would you like to view this Person or the original Event?',
            type: 'success',
            showCancelButton: true,
            confirmButtonText: 'View Original Event',
            cancelButtonText: 'View New Person',
            confirmButtonClass: 'btn btn-success btn-fill',
            cancelButtonClass: 'btn btn-info btn-fill',
            buttonsStyling: false,
            allowOutsideClick: false
          })).then((result) => {
            if (result.value) {
              this.$router.push({ name: 'View Event', params: { id: this.$route.params.id}})
            } else if (result.dismiss === swal.DismissReason.cancel) {
              this.$router.push({ name: 'View Person', params: { id: this.newSoul}})
            }
        })
      }
      if (this.$route.params.type == "organisation") {
        swal({
          title: 'Person Created',
          text: 'Would you like to view this Person or the original Organisation?',
          type: 'success',
          showCancelButton: true,
          confirmButtonText: 'View Original Organisation',
          cancelButtonText: 'View New Person',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false
      }).then((result) => {
        // need to link the souls
        this.createLinkSoulOrganisation(this.newSoul, this.$route.params.id)
        if (result.value) {
          this.$router.push({ name: 'View Organisation', params: { id: this.$route.params.id}})
        } else if (result.dismiss === swal.DismissReason.cancel) {
          this.$router.push({ name: 'View Person', params: { id: this.newSoul}})
          }
      })
      }
      if (this.$route.params.type == "soul") {
        swal({
        title: 'Person Created',
        text: 'Would you like to view this Person or the original Person?',
        type: 'success',
        showCancelButton: true,
        confirmButtonText: 'View Original Person',
        cancelButtonText: 'View New Person',
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-info btn-fill',
        buttonsStyling: false,
        allowOutsideClick: false
      }).then((result) => {
        // need to link the souls
        this.createLinkSoulSoul(this.newSoul, this.$route.params.id)
        if (result.value) {
          this.$router.push({ name: 'View Person', params: { id: this.$route.params.id}})
        } else if (result.dismiss === swal.DismissReason.cancel) {
          this.$router.push({ name: 'View Person', params: { id: this.newSoul}})
          }
      })
      }
      if (this.$route.params.type == undefined) {
      swal({
        title: 'Person Created',
        text: 'Would you like to view this Person or add another to the database?',
        type: 'success',
        showCancelButton: true,
        confirmButtonText: 'Add Another',
        cancelButtonText: 'View Person',
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-info btn-fill',
        buttonsStyling: false,
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          let randomNumber = Math.floor(Math.random() * 100000000001)
          this.$router.push({ name: 'Add Person', params: { id: randomNumber}})
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === swal.DismissReason.cancel
        ) {
        //do something else
        this.$router.push({ name: 'View Person', params: { id: this.newSoul}})
        }
      })
      }
    },
  }
}
</script>
