<template>
  <div class="row">
    <div class="col-lg-6 col-md-6" >
      <card class="card">

        <div class="col-12">

            <div class="row-12">
              <p class="card-category">Search</p>
            </div>
            <div class="row">
              <div class="col-10">
                  <base-input v-model="searchBarMust" v-on:keyup.enter="startSearch" placeholder="Enter Search"></base-input>
              </div>
              <div id="advancedSearchMustNot" class="col-10">
                  <base-input v-model="searchBarMustNot" placeholder="Must Not Include" default="NotInUse"></base-input>
              </div>
              <div id="advancedSearchShould" class="col-10">
                  <base-input v-model="searchBarShould" placeholder="Boost"></base-input>
              </div>
              <div class="col-md-8" id="fuzzy">
                <base-input>
                  <el-select
                    class="select-info"
                    size="medium"
                    placeholder="Fuzziness"
                    filterable
                    v-model="selectFuzzy"
                  >
                    <el-option
                      v-for="option in fuzzyOptions"
                      class="select-info"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-2">
                  <base-button class="btn-info" v-model="searchButton" @click="startSearch" size="sm">Go</base-button>
              </div>
            </div>
        </div>

        <a class="nav-link" @click="changeSearch()">
            <i class="tim-icons icon-refresh-02" ></i> Search Options
          </a>

      </card>
    </div>

    <modal :show.sync="modalCountry" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Change Country</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input>
                <el-select
                  class="select-info"
                  size="medium"
                  placeholder=""
                  filterable
                  v-model="newCountry"
                >
                  <el-option
                    v-for="option in newCountries"
                    class="select-info"
                    :value="option.search"
                    :label="option.search"
                    :key="option.search"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalCountry = false">Cancel</base-button>
            <base-button type="success" @click.native="changeCountry()">Save</base-button>
          </div>
        </modal>

    <div class="col-lg-3 col-md-3">
      <stats-card
        :title='country.title'
        :sub-title='country.subTitle'
        :type="country.type"
        :icon="country.icon">
        <div slot="footer">
          <a class="nav-link" @click="modalCountry = true">
            <i class="tim-icons icon-refresh-02" ></i> Change
          </a>
        </div>
      </stats-card>
    </div>
    
    <div class="col-lg-3 col-md-3">
      <stats-card
        :title='toLink.title'
        :sub-title='toLink.subTitle'
        :type="toLink.type"
        :icon="toLink.icon">
        <div slot="footer" class="ml-auto mr-auto">
          <base-button size="sm" class="btn btn-simple btn-info" @click="doneLinking()">Done</base-button>
        </div>
      </stats-card>
    </div>

    <div class="col-md-12">
      <card>
        <tabs square type="info">
          
          <tab-pane :label="soulsLabel"> 
            <div class="form-horizontal"> 
              <div class="row">  
                <div class="col-md-12">
                  <h4>Search Results</h4>
                  <i class="tim-icons icon-alert-circle-exc"></i> Click on a column title to sort (click twice to change between ascending and decending)
                  <data-tables :data="soulsData" :page-size="1" :pagination-props="{ pageSizes: [10], hideOnSinglePage: true, background: true, layout: &quot;prev, pager, next, slot&quot; }" layout="table, pagination">
                    <el-table-column
                      v-for="column in soulsColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                      sortable
                    />
                    <el-table-column :min-width="125" align="right" label="">
                      <div slot-scope="props">
                        <base-button
                          @click.native="linkSoul(props.row._id)"
                          class="btn-simple btn-link"
                          type="info"
                          size="sm"
                        >
                        Link
                        </base-button>
                      </div>
                    </el-table-column>

                  </data-tables>
                  <div class="row">
                    <div class="col-md-4">
                      <base-button class="btn btn-simple btn-info" @click="newSoul">Create New Person</base-button>
                    </div>
                    <!-- <div class="col-form-label ml-auto">
                      {{ soulsFrom + 1 }}-{{ soulsTo }} of {{ soulsTotal }}
                    </div>
                    <base-pagination
                      class="pagination-no-border"
                      v-model="soulsPagination.currentPage"
                      :per-page="soulsPagination.perPage"
                      :total="soulsTotal"
                    /> -->
                  </div>
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane :label="organisationsLabel">
            <div class="form-horizontal"> 
              <div class="row">  
                <div class="col-md-12">
                  <h4>Search Results</h4>
                  <i class="tim-icons icon-alert-circle-exc"></i> Click on a column title to sort (click twice to change between ascending and decending)
                  <data-tables :data="organisationsData" :page-size="1" :pagination-props="{ pageSizes: [10], hideOnSinglePage: true, background: true, layout: &quot;prev, pager, next, slot&quot; }" layout="table, pagination">
                    <el-table-column
                      v-for="column in organisationsColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                      sortable
                    />
                    <el-table-column :min-width="135" align="right" label="">
                      <div slot-scope="props">
                        <base-button
                          @click.native="linkOrganisation(props.row._id)"
                          class="btn-simple btn-link"
                          type="info"
                          size="sm"
                        >
                        Link
                        </base-button>
                      </div>
                    </el-table-column>
                  </data-tables>
                  <div class="row">
                    <div class="col-md-4">
                      <base-button class="btn btn-simple btn-info" @click="newOrganisation">Create New Organisation</base-button>
                    </div>
                    <!-- <div class="col-form-label ml-auto">
                      {{ organisationsFrom + 1 }}-{{ organisationsTo }} of {{ organisationsTotal }}
                    </div>
                    <base-pagination
                      class="pagination-no-border"
                      v-model="organisationsPagination.currentPage"
                      :per-page="organisationsPagination.perPage"
                      :total="organisationsTotal"
                    /> -->
                  </div>
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane :label="eventsLabel">
            <div class="form-horizontal" id="eventsTab"> 
              <div class="row">  
                <div class="col-md-12">
                  <h4>Search Results</h4>
                  <i class="tim-icons icon-alert-circle-exc"></i> Click on a column title to sort (click twice to change between ascending and decending)
                  <data-tables :data="eventsData" :page-size="1" :pagination-props="{ pageSizes: [10], hideOnSinglePage: true, background: true, layout: &quot;prev, pager, next, slot&quot; }" layout="table, pagination">
                    <el-table-column
                      v-for="column in eventsColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                      sortable
                    />
                    <el-table-column :min-width="155" align="right" label="">
                      <div slot-scope="props">
                        <base-button
                          @click.native="linkEvent(props.row._id)"
                          class="btn-simple btn-link"
                          type="info"
                          size="sm"
                        >
                        Link
                        </base-button>
                      </div>
                    </el-table-column>
                  </data-tables>
                  <div class="row">
                    <div class="col-md-4">
                      <base-button class="btn btn-simple btn-info" @click="newEvent">Create New Event</base-button>
                    </div>
                    <!-- <div class="col-form-label ml-auto">
                      {{ eventsFrom + 1 }}-{{ eventsTo }} of {{ eventsTotal }}
                    </div>
                    <base-pagination
                      class="pagination-no-border"
                      v-model="eventsPagination.currentPage"
                      :per-page="eventsPagination.perPage"
                      :total="eventsTotal"
                    /> -->
                  </div>
                </div>
              </div>
            </div>
          </tab-pane>
          <!-- <tab-pane :label="tribesLabel">
            <div class="form-horizontal" id="tribesTab"> 
              <div class="row">  
                <div class="col-md-12">
                  <h4>Search Results</h4>
                  <i class="tim-icons icon-alert-circle-exc"></i> Click on a column title to sort (click twice to change between ascending and decending)
                  <el-table :data="tribes" :default-sort = "{prop: 'title', order: 'descending'}">
                    <el-table-column
                      v-for="column in tribesColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                      sortable
                    />
                    <el-table-column :min-width="135" align="right" label="">
                      <div slot-scope="props">
                        <base-button
                          @click.native="linkTribe(props.row._id)"
                          class="btn-simple btn-link"
                          type="info"
                          size="sm"
                        >
                        Link
                        </base-button>
                      </div>
                    </el-table-column>
                  </el-table>
                  <div class="row">
                    <div class="col-md-4">
                      <base-button class="btn btn-simple btn-info" @click="newTribe">Create New Tribe</base-button>
                    </div>
                    <div class="col-form-label ml-auto">
                      {{ tribesFrom + 1 }}-{{ tribesTo }} of {{ tribesTotal }}
                    </div>
                    <base-pagination
                      class="pagination-no-border"
                      v-model="tribesPagination.currentPage"
                      :per-page="tribesPagination.perPage"
                      :total="tribesTotal"
                    />
                  </div>
                </div>
              </div>
            </div>
          </tab-pane> -->
          
        </tabs>
      </card>
    </div>
  </div>
</template>

<script>
import { TabPane, Tabs, BasePagination } from 'src/components'
import { Table, TableColumn, Select, Option } from 'element-ui'
import inputCard from 'src/components/Cards/inputCard'
import StatsCard from 'src/components/Cards/StatsCard'
import swal from 'sweetalert2'
import POIsService from '@/services/POIsService'
import SoulsService from '@/services/SoulsService'
import OrganisationsService from '@/services/OrganisationsService'
import EventsService from '@/services/EventsService'
import TribesService from '@/services/TribesService'
import SearchService from '@/services/SearchService'
import LinkService from '@/services/LinkService'
import Card from 'src/components/Cards/Card.vue'
import { Loading } from 'element-ui'
import { Modal, BaseAlert } from 'src/components'
import { DataTables, DataTablesServer } from 'vue-data-tables'

export default {
  components: {
    TabPane,
    Tabs,
    inputCard,
    StatsCard,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    BaseAlert,
    DataTables,
  },
  
  // computed: {
  //   souls() {
  //     return this.soulsData.slice(this.soulsFrom, this.soulsTo);
  //   },
  //   soulsTo() {
  //     let highBound = this.soulsFrom + this.soulsPagination.perPage;
  //     if (this.soulsTotal < highBound) {
  //       highBound = this.soulsTotal;
  //     }
  //     return highBound;
  //   },
  //   soulsFrom() {
  //     return this.soulsPagination.perPage * (this.soulsPagination.currentPage - 1);
  //   },
  //   soulsTotal() {
  //     return this.soulsData.length;
  //   },
  //   organisations() {
  //     return this.organisationsData.slice(this.organisationsFrom, this.organisationsTo);
  //   },
  //   organisationsTo() {
  //     let highBound = this.organisationsFrom + this.organisationsPagination.perPage;
  //     if (this.organisationsTotal < highBound) {
  //       highBound = this.organisationsTotal;
  //     }
  //     return highBound;
  //   },
  //   organisationsFrom() {
  //     return this.organisationsPagination.perPage * (this.organisationsPagination.currentPage - 1);
  //   },
  //   organisationsTotal() {
  //     return this.organisationsData.length;
  //   },
  //   events() {
  //     return this.eventsData.slice(this.eventsFrom, this.eventsTo);
  //   },
  //   eventsTo() {
  //     let highBound = this.eventsFrom + this.eventsPagination.perPage;
  //     if (this.eventsTotal < highBound) {
  //       highBound = this.eventsTotal;
  //     }
  //     return highBound;
  //   },
  //   eventsFrom() {
  //     return this.eventsPagination.perPage * (this.eventsPagination.currentPage - 1);
  //   },
  //   eventsTotal() {
  //     return this.eventsData.length;
  //   },
  //   tribes() {
  //     return this.tribesData.slice(this.tribesFrom, this.tribesTo);
  //   },
  //   tribesTo() {
  //     let highBound = this.tribesFrom + this.tribesPagination.perPage;
  //     if (this.tribesTotal < highBound) {
  //       highBound = this.tribesTotal;
  //     }
  //     return highBound;
  //   },
  //   tribesFrom() {
  //     return this.tribesPagination.perPage * (this.tribesPagination.currentPage - 1);
  //   },
  //   tribesTotal() {
  //     return this.tribesData.length;
  //   },
  // },
  data() {
    return {
      modalCountry: false,
      newCountry: '',
      newCountries: [],
      searchBarMust: '',
      searchBarShould: '',
      searchBarMustNot: '',
      searchButton: '',
      countries: {},
      country: {
        title: '', 
        subTitle: 'Country', 
        type: 'warning', 
        icon:'tim-icons icon-world', 
      },
      toLink: {
        title: '', 
        subTitle: 'Being Linked To', 
        type: 'warning', 
        icon:'tim-icons icon-world', 
      },
      soulsPagination: { perPage: 4,currentPage: 1,total: 0 },
      organisationsPagination: { perPage: 4,currentPage: 1,total: 0 },
      eventsPagination: { perPage: 4,currentPage: 1,total: 0 },
      tribesPagination: { perPage: 4,currentPage: 1,total: 0 },
      soulsData:[],
      selectFuzzy: '',
      fuzzyOptions: [
          { value: 'high', label: 'High (Default)' },
          { value: 'low', label: 'Low' },
        ],
      organisationsData:[],
      eventsData:[],
      tribesData:[],
      soulsLabel: 'People',
      organisationsLabel: 'Organisations',
      tribesLabel: 'Tribes',
      eventsLabel: 'Events',
      soulsColumns: [
        { prop: 'nameEnglish', label: 'English Name', minWidth: 200},
        { prop: 'nameArabic', label: 'Arabic Name', minWidth: 200},
        { prop: 'aka[0].englishAKA', label: 'Also Known As', minWidth: 200},
        { prop: 'position[0].positionNameEnglish', label: 'Position', minWidth: 200},
        { prop: 'basedin[0].basedIn', label: 'Based In', minWidth: 200},
      ],
      organisationsColumns: [
        { prop: 'org_en', label: 'English Name', minWidth: 200},
        { prop: 'org_ar', label: 'Arabic Name', minWidth: 200},
        { prop: 'basedin[0].basedIn', label: 'Based In', minWidth: 200},
      ],
      eventsColumns: [
        { prop: 'date', label: 'Date', minWidth: 100},
        { prop: 'type', label: 'Type', minWidth: 100},
        { prop: 'title', label: 'Title', minWidth: 500},
        { prop: 'country', label: 'Location', minWidth: 100},
      ],
      tribesColumns: [
        { prop: 'tribe_en', label: 'Tribe (English)', minWidth: 200},
        { prop: 'tribe_ar', label: 'Tribe (Arabic)', minWidth: 200},
        { prop: 'lastupdated', label: 'Last Updated', minWidth: 200},
      ],
      searchBarCards: [
        {
          title: 'Ben',
          subTitle: 'Hello',
          type: 'info',
          icon: 'tim-icons icon-zoom-split',
          footer: '<i class="tim-icons icon-alert-circle-exc"></i> For advanced search use "+" for AND, "|" for OR, "-" for NOT'
        },
      ],
    };
  },
  mounted(){
    this.pageload()
  },
  activated(){
    this.pageload()
  },
  // watch: {
  //   $route(from, to) {
  //     this.pageload()
  //   }
  // },
  methods:{
    pageload() {
      let loading = Loading.service()
      this.getNations()
      document.getElementById("advancedSearchMustNot").style.display = "none"
      document.getElementById("advancedSearchShould").style.display = "none"
      document.getElementById("fuzzy").style.display = "none"
      if (this.$route.params.recordType == "event") {
        document.getElementById("eventsTab").style.display = "none"
        //document.getElementById("tribesTab").style.display = "none"
      }
      if (this.$route.params.recordType == "organisation") {
        document.getElementById("eventsTab").style.display = "initial"
        //document.getElementById("tribesTab").style.display = "none"
      }
      if (this.$route.params.recordType == "soul") {
        document.getElementById("eventsTab").style.display = "initial"
        //document.getElementById("tribesTab").style.display = "initial"
      }
      this.country.title = this.$route.params.country
      this.toLink.title = this.$route.params.recordName
      loading.close()
    },
    changeCountry() {
      this.country.title = this.newCountry
      this.modalCountry = false
      // swal({
      //     title: 'Change Country',
      //     text: 'Select a country below',
      //     input: 'select',
      //     inputOptions: this.countries,
      //     showCancelButton: true,
      //     confirmButtonText: 'Save',
      //     cancelButtonText: 'Cancel',
      //     confirmButtonClass: 'btn btn-success btn-fill',
      //     cancelButtonClass: 'btn btn-info btn-fill',
      //     buttonsStyling: false,
      //     allowOutsideClick: false,
      //     inputValue: 'this',
      //     inputValidator: (value) => {
      //       this.country.title = value
      //     }
      //   })
    },
    changeSearch() {
      var mustNot = document.getElementById("advancedSearchMustNot")
        if (mustNot.style.display === "none") {
          mustNot.style.display = "block"
        } else {
          mustNot.style.display = "none"
        }
      var should = document.getElementById("advancedSearchShould")
        if (should.style.display === "none") {
          should.style.display = "block"
        } else {
          should.style.display = "none"
        }
      var fuzzy = document.getElementById("fuzzy")
        if (fuzzy.style.display === "none") {
          fuzzy.style.display = "block"
        } else {
          fuzzy.style.display = "none"
        }
    },
    async searchSouls(country, must, mustNot, should, fuzziness) {
      this.soulsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let souls = await SearchService.searchSouls({country: country, must: must, mustNot: mustNot, should: should, fuzziness: fuzziness})
      this.soulsData = souls.data
      this.soulsLabel = "People (" + souls.data.length + ")"
    },
    async searchOrganisations(country, must, mustNot, should, fuzziness) {
      this.organisationsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let organisations = await SearchService.searchOrganisations({country: country, must: must, mustNot: mustNot, should: should, fuzziness: fuzziness})
      this.organisationsData = organisations.data
      this.organisationsLabel = "Organisations (" + organisations.data.length + ")"
    },
    async searchEvents(country, must, mustNot, should, fuzziness) {
      this.eventsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let events = await SearchService.searchEvents({country: country, must: must, mustNot: mustNot, should: should, fuzziness: fuzziness})
      events.data.forEach(event => {
        event.date = new Date(event.date.substring(0, 10)).toDateString()
      });
      this.eventsData = events.data
      this.eventsLabel = "Events (" + events.data.length + ")"
    },
    async getTribes(countryToSearch) {
      this.tribesLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let tribes = await SearchService.fetchTribes({country : countryToSearch})
      this.tribesData = tribes.data
      this.tribesLabel = "Tribes (" + tribes.data.length + ")"
    },
    async getEvents(countryToSearch) {
      this.eventsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let events = await SearchService.fetchEvents({country : countryToSearch})
      events.data.forEach(event => {
        event.date = new Date(event.date.substring(0, 10)).toDateString()
      });
      this.eventsData = events.data
      this.eventsLabel = "Events (" + events.data.length + ")"
    },
    async getSouls(countryToSearch) {
      this.soulsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let souls = await SearchService.fetchSouls({country: countryToSearch})
      this.soulsData = souls.data
      this.soulsLabel = "People (" + souls.data.length + ")"
    },
    async getOrganisations(countryToSearch) {
      this.organisationsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let organisations = await SearchService.fetchOrganisations({country: countryToSearch})
      this.organisationsData = organisations.data
      this.organisationsLabel = "Organisations (" + organisations.data.length + ")"
    },
    async getNations() {
      this.newCountries = window.GLOBALNATIONS
      // activeNations.data.forEach(country => {
      //   const id = country.search
      //   this.countries[id] = country.search
      // });
    },
    async startSearch() {
      // hide the advanced fields if they were used
      document.getElementById("advancedSearchMustNot").style.display = "none"
      document.getElementById("advancedSearchShould").style.display = "none"
      document.getElementById("fuzzy").style.display = "none"
      // need to clear all the results in case not the first search
      this.soulsData=[]
      this.organisationsData=[]
      this.eventsData=[]
      this.tribesData=[]
      // and clear all the labels
      this.soulsLabel = 'People'
      this.organisationsLabel = 'Organisations'
      this.tribesLabel = 'Tribes'
      this.eventsLabel = 'Events'
      // need to check what fields are in use and replace those that aren't with 'NOTinUSEforSEARCH' cause we have a pass a value
      if (this.searchBarMustNot == ""){
        this.searchBarMustNot = "NOTinUSEforSEARCH"
      }
      if (this.searchBarShould == ""){
        this.searchBarShould = "NOTinUSEforSEARCH"
      }
      if (this.selectFuzzy == ""){
        this.selectFuzzy = "high"
      }
      // depending on whether the search bar is used or not there will be two different searches
      // if it is not used then just get records:
      if (this.searchBarMust == "" || this.searchBarMust == null) {
        // Full return search by Country, handle all countries in lambda function
        // depending on what the record type being linked - different searches need to run
        if (this.$route.params.recordType == "soul") {
          this.getTribes(this.country.title)
          this.getEvents(this.country.title)
          this.getSouls(this.country.title)
          this.getOrganisations(this.country.title)
        }
        if (this.$route.params.recordType == "organisation") {
          this.getSouls(this.country.title)
          this.getOrganisations(this.country.title)
          this.getEvents(this.country.title)
        }
        if (this.$route.params.recordType == "event") {
          this.getSouls(this.country.title)
          this.getOrganisations(this.country.title)
        }
        
      } else {
        // Specifc search based on keywords and country
        if (this.$route.params.recordType == "soul") {
          this.searchSouls(this.country.title, encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
          this.searchOrganisations(this.country.title, encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
          this.searchEvents(this.country.title, encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
          this.getTribes(this.country.title)
        }
        if (this.$route.params.recordType == "organisation") {
          this.searchSouls(this.country.title, encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
          this.searchOrganisations(this.country.title, encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
          this.searchEvents(this.country.title, encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
        }
        if (this.$route.params.recordType == "event") {
          this.searchSouls(this.country.title, encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
          this.searchOrganisations(this.country.title, encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
        }
        
      }
      //and if we changed these values lets change em back
      if (this.searchBarMustNot == "NOTinUSEforSEARCH"){
        this.searchBarMustNot = ""
      }
      if (this.searchBarShould == "NOTinUSEforSEARCH"){
        this.searchBarShould = ""
      }
    },
    async viewSoul(soulID) {
      this.$router.push({ name: 'View Person', params: { id: soulID}})
    },
    async viewEvent(eventID) {
      this.$router.push({ name: 'View Event', params: { id: eventID}})
    },
    async viewOrganisation(organisationID) {
      this.$router.push({ name: 'View Organisation', params: { id: organisationID}})
    },
    async viewSpecificLocation(specificLocationID) {
      this.$router.push({ name: 'View Specific Location', params: { id: specificLocationID}})
    },
    async viewTribe(tribeID) {
      this.$router.push({ name: 'View Tribe', params: { id: tribeID}})
    },
    async viewSource(sourceID) {
      this.$router.push({ name: 'View Source', params: { id: sourceID}})
    },
    async linkSoul(newID) {
      // If Linking to an event, have to get user to decide Actor or Target and then link
      if (this.$route.params.recordType == "event"){
        swal({
          title: 'Link',
          text: 'Choose Below',
          input: 'radio',
          inputOptions: ({"actor":"Actor", "target":"Target"}),
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Save',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          reverseButtons: true,
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == null){
              return "Please select one"
            }
            this.createLinkEventSoul(this.$route.params.id, newID, value)
          }
        })
      }
      if (this.$route.params.recordType == "soul"){
        this.createLinkSoulSoul(this.$route.params.id, newID)
        
      }
      if (this.$route.params.recordType == "organisation"){
        this.createLinkSoulOrganisation(newID, this.$route.params.id)
        // swal({
        //   title: 'Link Created!',
        //   confirmButtonText: 'OK',
        //   confirmButtonClass: 'btn btn-success btn-fill',
        //   buttonsStyling: false,
        //   allowOutsideClick: false,
        // })
      }
    },
    notifyFailure() {
      this.$notify({
        message:
          'Action Failed',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "danger"
      });
    },
    async createLinkEventSoul(eventID, soulID, linkType) {
      let loading = Loading.service()
      let count = 0
      while (true) {
        try {
          await LinkService.createLinkEventSoul({
        eventID: eventID,
        soulID: soulID,
        linkType: linkType
      }).catch((e) => {loading.close().then(this.notifyFailure())})
          break
        } catch (error) {
          count++
          if (error.response.status != 500 && error.response.status != 502) {
                loading.close()
                this.notifyFailure()
                break
          }
          if (count == 2){
            loading.close()
            this.notifyFailure()
            break
          }
        }
      }
      loading.close()
      swal({
          title: 'Link Created!',
          confirmButtonText: 'OK',
          confirmButtonClass: 'btn btn-success btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
        })
    },
    async createLinkEventOrganisation(eventID, organisationID, linkType) {
      let loading = Loading.service()
      let count = 0
      while (true) {
        try {
          await LinkService.createLinkEventOrganisation({
        eventID: eventID,
        orgID: organisationID,
        linkType: linkType
      }).catch((e) => {loading.close().then(this.notifyFailure())})
          break
        } catch (error) {
          count++
          if (error.response.status != 500 && error.response.status != 502) {
                loading.close()
                this.notifyFailure()
                break
          }
          if (count == 2){
            loading.close()
            this.notifyFailure()
            break
          }
        }
      }
      loading.close()

      

      swal({
          title: 'Link Created!',
          confirmButtonText: 'OK',
          confirmButtonClass: 'btn btn-success btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
        })
    },
    async createLinkSoulSoul(soulID1, soulID2) {
      let loading = Loading.service()
      let count = 0
      while (true) {
        try {
          await LinkService.createLinkSoulSoul({
        soulID1: soulID1,
        soulID2: soulID2
      }).catch((e) => {loading.close().then(this.notifyFailure())})
          break
        } catch (error) {
          count++
          if (error.response.status != 500 && error.response.status != 502) {
                loading.close()
                this.notifyFailure()
                break
          }
          if (count == 2){
            loading.close()
            this.notifyFailure()
            break
          }
        }
      }
      loading.close()
      
      

      swal({
          title: 'Link Created!',
          confirmButtonText: 'OK',
          confirmButtonClass: 'btn btn-success btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
        })
    },
    async createLinkOrganisationOrganisation(parentID, subID, linkType) {

      let loading = Loading.service()
      let count = 0
      while (true) {
        try {
          await LinkService.createLinkOrganisationOrganisation({
        parentID: parentID,
        subID: subID,
        linkType: linkType
      }).catch((e) => {loading.close().then(this.notifyFailure())})
          break
        } catch (error) {
          count++
          if (error.response.status != 500 && error.response.status != 502) {
                loading.close()
                this.notifyFailure()
                break
          }
          if (count == 2){
            loading.close()
            this.notifyFailure()
            break
          }
        }
      }
      loading.close()
        swal({
          title: 'Link Created!',
          confirmButtonText: 'OK',
          confirmButtonClass: 'btn btn-success btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
        })
    },
    async createLinkSoulOrganisation(soulID, organisationID) {

      let loading = Loading.service()
      let count = 0
      while (true) {
        try {
          await LinkService.createLinkSoulOrganisation({
        soulID: soulID,
        orgID: organisationID
      }).catch((e) => {loading.close().then(this.notifyFailure())})
          break
        } catch (error) {
          count++
          if (error.response.status != 500 && error.response.status != 502) {
                loading.close()
                this.notifyFailure()
                break
          }
          if (count == 2){
            loading.close()
            this.notifyFailure()
            break
          }
        }
      }
      loading.close()

      

      swal({
          title: 'Link Created!',
          confirmButtonText: 'OK',
          confirmButtonClass: 'btn btn-success btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
        })
    },
    async linkOrganisation(newID) {
      // If Linking to an event, have to get user to decide Actor or Target and then link
      if (this.$route.params.recordType == "event"){
        swal({
          title: 'Link',
          text: 'Choose Below',
          input: 'radio',
          inputOptions: ({"actor":"Actor", "target":"Target"}),
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Save',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          reverseButtons: true,
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == null){
              return "Please select one"
            }
            this.createLinkEventOrganisation(this.$route.params.id, newID, value)
        //     swal({
        //   title: 'Link Created!',
        //   confirmButtonText: 'OK',
        //   confirmButtonClass: 'btn btn-success btn-fill',
        //   buttonsStyling: false,
        //   allowOutsideClick: false,
        // })

          }
        })
      }
      if (this.$route.params.recordType == "organisation"){
        swal({
          title: 'Link',
          text: 'Choose Below',
          input: 'radio',
          inputOptions: ({"parent":"Parent", "sub":"Sub Organisation", "linked": "Linked Organisation"}),
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Save',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          reverseButtons: true,
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == null){
              return "Please select one"
            }
            this.createLinkOrganisationOrganisation(this.$route.params.id, newID, value)
        //     swal({
        //   title: 'Link Created!',
        //   confirmButtonText: 'OK',
        //   confirmButtonClass: 'btn btn-success btn-fill',
        //   buttonsStyling: false,
        //   allowOutsideClick: false,
        // })
          }
        })
      }
      // If linking to an organisation, bave to get user to decide if Parent, Sub or Linked Org
      if (this.$route.params.recordType == "soul"){
        this.createLinkSoulOrganisation(this.$route.params.id, newID)
        // swal({
        //   title: 'Link Created!',
        //   confirmButtonText: 'OK',
        //   confirmButtonClass: 'btn btn-success btn-fill',
        //   buttonsStyling: false,
        //   allowOutsideClick: false,
        // })
      }
    },
    async linkEvent(newID) {
      if (this.$route.params.recordType == "soul"){
        swal({
          title: 'Link',
          text: 'Choose Below',
          input: 'radio',
          inputOptions: ({"actor":"Actor", "target":"Target"}),
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Save',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          reverseButtons: true,
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == null){
              return "Please select one"
            }
            this.createLinkEventSoul(newID, this.$route.params.id, value)
        //     swal({
        //   title: 'Link Created!',
        //   confirmButtonText: 'OK',
        //   confirmButtonClass: 'btn btn-success btn-fill',
        //   buttonsStyling: false,
        //   allowOutsideClick: false,
        // })
          }
        })
      }
      if (this.$route.params.recordType == "organisation"){
        swal({
          title: 'Link',
          text: 'Choose Below',
          input: 'radio',
          inputOptions: ({"actor":"Actor", "target":"Target"}),
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Save',
          reverseButtons: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == null){
              return "Please select one"
            }
            this.createLinkEventOrganisation(newID, this.$route.params.id, value)
            swal({
          title: 'Link Created!',
          confirmButtonText: 'OK',
          confirmButtonClass: 'btn btn-success btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
        })
          }
        })
      }
    },
    linkTribe() {
      // dunno if this is really needed
    },
    newSoul() {
      this.$router.push({ name: 'Add Person', params: { id: this.$route.params.id, type: this.$route.params.recordType}})
    },
    newOrganisation() {
      this.$router.push({ name: 'Add Organisation', params: { id: this.$route.params.id, type: this.$route.params.recordType}})
    },
    newEvent() {
      this.$router.push({ name: 'Add Event', params: { id: this.$route.params.id, type: this.$route.params.recordType}})
    },
    newTribe() {
      this.$router.push({ name: 'Add Tribe', params: { id: this.$route.params.id, type: this.$route.params.recordType}})
    },
    doneLinking() {
      if (this.$route.params.recordType == "event") {
        this.$router.push({ name: 'View Event', params: { id: this.$route.params.id}})
      }
      else if (this.$route.params.recordType == "soul") {
        this.$router.push({ name: 'View Person', params: { id: this.$route.params.id}})
      }
      else if (this.$route.params.recordType == "organisation") {
        this.$router.push({ name: 'View Organisation', params: { id: this.$route.params.id}})
      }
      else if (this.$route.params.recordType == null) {
        this.$router.push({ name: 'Search'})
      }
      
    } 
  }
};
</script>

<style lang="scss">
.pagination-select, .search-input {
  width: 200px;}
</style>