<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard >
          <template slot="header">
            <h3 class="card-title">Add a New Tribe</h3>
            <h3 class="description">
              Add a new tribe to the database.
            </h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-caps-small"></i>
              <p>Tribe</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
              @child-englishname="englishNameValue"
              @child-arabicname="arabicNameValue"
              @child-country="countryValue"
            ></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-pencil"></i>
              <p>Details</p>
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
              @child-ethnicity="ethnicityValue"
              @child-confederationenglish="confederationEnglishValue"
              @child-confederationarabic="confederationArabicValue"
              @child-subtribeoneenglish="subTribeOneEnglishValue"
              @child-subtribeonearabic="subTribeOneArabicValue"
              @child-subtribetwoenglish="subTribeTwoEnglishValue"
              @child-subtribetwoarabic="subTribeTwoArabicValue"
              @child-subtribethreeenglish="subTribeThreeEnglishValue"
              @child-subtribethreearabic="subTribeThreeArabicValue"
            ></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p>Save</p>
            </template>
            <third-step ref="step3" 
              v-bind:propEnglishName="this.englishName" 
              v-bind:propArabicName="this.arabicName" 
              v-bind:propCountry="this.country"
              v-bind:propEthnicity="this.ethnicity"
              v-bind:propConfederationEnglish="this.confederationEnglish"
              v-bind:propConfederationArabic="this.confederationArabic"
              v-bind:propSubTribeOneEnglish="this.subTribeOneEnglish"
              v-bind:propSubTribeOneArabic="this.subTribeOneArabic"
              v-bind:propSubTribeTwoEnglish="this.subTribeTwoEnglish"
              v-bind:propSubTribeTwoArabic="this.subTribeTwoArabic"
              v-bind:propSubTribeThreeEnglish="this.subTribeThreeEnglish"
              v-bind:propSubTribeThreeArabic="this.subTribeThreeArabic"
              @child-newTribe="newTribeValue"
              @on-validated="wizardComplete" ></third-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from './FirstStep.vue';
import SecondStep from './SecondStep.vue';
import ThirdStep from './ThirdStep.vue';
import swal from 'sweetalert2';
import { SimpleWizard, WizardTab } from 'src/components';

export default {
  data() {
    return {
      wizardModel: {},
      englishName: '',
      arabicName: '',
      country:'',
      ethnicity:'',
      confederationEnglish:'',
      confederationArabic:'',
      subTribeOneEnglish:'',
      subTribeOneArabic:'',
      subTribeTwoEnglish:'',
      subTribeTwoArabic:'',
      subTribeThreeEnglish:'',
      subTribeThreeArabic:'',
      newTribe:'',
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab,
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    englishNameValue: function(params) {
      this.englishName = params;
    },
    arabicNameValue: function(params) {
      this.arabicName = params;
    },
    countryValue: function(params) {
      this.country = params;
    },
    ethnicityValue: function(params) {
      this.ethnicity = params;
    },
    confederationEnglishValue: function(params) {
      this.confederationEnglish = params;
    },
    confederationArabicValue: function(params) {
      this.confederationArabic = params;
    },
    subTribeOneEnglishValue: function(params) {
      this.subTribeOneEnglish = params;
    },
    subTribeOneArabicValue: function(params) {
      this.subTribeOneArabic = params;
    },
    subTribeTwoEnglishValue: function(params) {
      this.subTribeTwoEnglish = params;
    },
    subTribeTwoArabicValue: function(params) {
      this.subTribeTwoArabic = params;
    },
    subTribeThreeEnglishValue: function(params) {
      this.subTribeThreeEnglish = params;
    },
    subTribeThreeArabicValue: function(params) {
      this.subTribeThreeArabic = params;
    },
    newTribeValue: function(params) {
      this.newTribe = params;
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      if (this.newTribe.status != 200) {
        swal({
          title: 'Creation Failed',
          text: 'Creation Failed. Please check your connection.',
          type: 'error',
          confirmButtonText: 'OK',
          confirmButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false
        })
      } else {
        if (this.$route.params.type == "soul") {
          
        }
        swal({
          title: 'Tribe Created',
          text: 'Would you like to view this Tribe or add another to the database?',
          type: 'success',
          showCancelButton: true,
          confirmButtonText: 'Add Another',
          cancelButtonText: 'View Tribe',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then((result) => {
        if (result.value) {
          //do one thing
          this.$router.push({ name: 'Add Tribe', params: { id: randomNumber}})
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === swal.DismissReason.cancel
        ) {
        //do something else
        this.$router.push({ name: 'View Tribe', params: { id: this.newTribe.data._id}})
        }
        })
      }
    }
  }
};
</script>
