<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard >
          <template slot="header">
            <h3 class="card-title">Add a New Specific Location</h3>
            <h3 class="description">
              Add a new specific location to the database. 
            </h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-caps-small"></i>
              <p>Location</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
              @child-englishName="englishNameValue"
              @child-arabicName="arabicNameValue"
            ></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-pencil"></i>
              <p>Details</p>
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
              @child-latitude="latitudeValue"
              @child-longitude="longitudeValue"
            ></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p>Save</p>
            </template>
            <third-step ref="step3" 
              v-bind:propEnglishName="this.englishName" 
              v-bind:propArabicName="this.arabicName" 
              v-bind:propLatitude="this.latitude" 
              v-bind:propLongitude="this.longitude" 
              @on-validated="wizardComplete" 
              @child-newSpecificLocation="newSpecificLocationValue">
            </third-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from './FirstStep.vue';
import SecondStep from './SecondStep.vue';
import ThirdStep from './ThirdStep.vue';
import swal from 'sweetalert2';
import { SimpleWizard, WizardTab } from 'src/components';
import EventsService from '@/services/EventsService'
import { Loading } from 'element-ui';

export default {
  data() {
    return {
      wizardModel: {},
      englishName: '',
      arabicName: '',
      latitude: '',
      longitude: '',
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab,
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    englishNameValue: function(params) {
      this.englishName = params;
    },
    arabicNameValue: function(params) {
      this.arabicName = params;
    },
    latitudeValue: function(params) {
      this.latitude = params;
    },
    longitudeValue: function(params) {
      this.longitude = params;
    },
    newSpecificLocationValue: function(params) {
      this.newSpecificLocation = params;
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    async addSpecificLocation(location){
      let loading = Loading.service()
      let newDetails = await EventsService.updateEvent({
        id: this.$route.params.id,
        specific_location: location, 
        lastupdated: new Date(),
      }).catch(err => loading.close().then(this.notifyFailure()))
      loading.close()
    },
    wizardComplete() {
      if (this.newSpecificLocation.status != 200) {
        swal({
          title: 'Creation Failed',
          text: 'Creation Failed. Please check your connection.',
          type: 'error',
          confirmButtonText: 'OK',
          confirmButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false
        })
      } else {
          if (this.$route.params.type == "event") {
            this.addSpecificLocation(this.newSpecificLocation.data._id)
            swal({
            title: 'Specific Location Created',
            text: 'Would you like to view this record or the original event?',
            type: 'success',
            showCancelButton: true,
            confirmButtonText: 'View Event',
            cancelButtonText: 'View Specific Location',
            confirmButtonClass: 'btn btn-success btn-fill',
            cancelButtonClass: 'btn btn-info btn-fill',
            buttonsStyling: false
            }).then((result) => {
              if (result.value) {
                // View the Event
                this.$router.push({ name: 'View Event', params: { id: this.$route.params.id}})
              } else if (result.dismiss === swal.DismissReason.cancel) {
                // View the created record
                this.$router.push({ name: 'View Specific Location', params: { id: this.newSpecificLocation.data._id}})
              }
            })
          }
          if (this.$route.params.type != "event") {
            swal({
            title: 'Specific Location Created',
            text: 'Would you like to view this record or add another to the database?',
            type: 'success',
            showCancelButton: true,
            confirmButtonText: 'Add Another',
            cancelButtonText: 'View Specific Location',
            confirmButtonClass: 'btn btn-success btn-fill',
            cancelButtonClass: 'btn btn-info btn-fill',
            buttonsStyling: false
            }).then((result) => {
              if (result.value) {
                // Create Another Record
                this.$router.push({ name: 'Add Specific Location', params: { id: randomNumber}})
              } else if (result.dismiss === swal.DismissReason.cancel) {
                // View the created record
                this.$router.push({ name: 'View Specific Location', params: { id: this.newSpecificLocation.data._id}})
              }
            })
          }
      }
    }
  }
}
</script>
