<template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
    <h5 class="info-text">
      What sort of report do you want to create?
    </h5>
    </div>

    <div class="col-md-8">
      <base-input :error="getError('type')">
        <el-select
          name="type"
          id="type"
                  class="select-info"
                  size="medium"
                  placeholder="Type"
                  filterable
                  v-model="selects.type"
                  v-validate="modelValidations.type"
                >
                  <el-option
                    v-for="option in types"
                    class="select-info"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value"
                  >
                  </el-option>
                </el-select>
        </base-input>
    </div>

  </div>
</template>

<script>
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import SearchService from '@/services/SearchService'

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  mounted() {
    //this.getNations()
  },
  data() {
    return {
      selects: {
        type:'',
      },
      types: [
          { value: 'Event', label: 'Event' },
          { value: 'Event Audit', label: 'Event Audit' },
          { value: 'Organisations', label: 'Organisations' },
          { value: 'People', label: 'People' },
          { value: 'Report', label: 'Report' },
          { value: 'Source', label: 'Source' },
        ],
      title: '',
      modelValidations: {
        type: {required: true}, 
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$emit("child-type", this.selects.type);
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
  },
};
</script>
<style></style>
