<template>
  <div class="row">
    <div class="col-lg-4 col-md-4" >
      <stats-card
        :title='tribe.title'
        :sub-title='tribe.subTitle'
        :type="tribe.type"
        :icon="tribe.icon">
        <div slot="footer" class="row">
          <div class="col-md-10">
            <a class="nav-link" @click="changeName()">
              <i class="tim-icons icon-refresh-02" ></i> {{titleSwap}}
            </a>
          </div>
          <div class="col-md-2 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
            <div v-if="$can('update', 'mandatory')">
              <a class="dropdown-item" @click="editEnglishName">Edit English Name</a>
            </div>
              <a class="dropdown-item" @click="editArabicName">Edit Arabic Name</a>
              <div v-if="$can('delete', 'all')">
              <a class="dropdown-item" @click="deleteRecord">Delete Tribe</a>
              </div>
            </base-dropdown>
          </div>
        </div>
      </stats-card>
    </div>

    <div class="col-lg-4 col-md-4">
      <updated-card
        :title='tribeDate.title'
        :sub-title='tribeDate.subTitle'
        :second-sub-title='tribeDate.secondSubTitle'
        :second-title='tribeDate.secondTitle'
        :type="tribeDate.type"
        :icon="tribeDate.icon">
      </updated-card>
    </div>
    <div class="col-md-8">
      <card >
        <div class="row">
          <div class="col-md-11">
            <h5>Details</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-dropdown
                  title-classes="dropdown-toggle btn tim-icons icon-pencil"     
                >
                  <h6 class="dropdown-header">Details Menu</h6>
                  <a class="dropdown-item" href="#" @click="saveDetails">Save Details</a>
                  <div v-if="$can('update', 'mandatory')">
                  <a class="dropdown-item" href="#" @click="editCountry">Edit Country</a>
                  </div>
                </base-dropdown>
          </div>
        </div>

        <div class="form-horizontal">
          <div class="row">  
            <label class="col-md-4 col-form-label">Country</label>
            <div class="col-md-6">
              <base-input disabled v-model="tribeCountry"/>
            </div>
          </div>

          <br>

          <div class="row">
            <div class="col-md-4 col-form-label">
              <label>Ethnicity</label>
            </div>
            <div class="col-md-6">
              <base-input v-model="ethnicity"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-form-label">
              <label>Confederation (English)</label>
            </div>
            <div class="col-md-6">
              <base-input v-model="confederationEnglish"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-form-label">
              <label>Confederation (Arabic)</label>
            </div>
            <div class="col-md-6">
              <base-input v-model="confederationArabic"/>
            </div>
          </div>

          <br><br>

          <div class="row justify-content-center">
            <div class="col-md-5">
              <h6 slot="header" class="card-title">Sub Tribes - English</h6>
              
              <div class="row justify-content-center">
            <div class="col-form-label">
              <label>1</label>
            </div>
            <div class="col-md-9">
              <base-input
                v-model="subTribeOneEnglish"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-form-label">
              <label>2</label>
            </div>
            <div class="col-md-9">
              <base-input
                v-model="subTribeTwoEnglish"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-form-label">
              <label>3</label>
            </div>
            <div class="col-md-9">
              <base-input
                v-model="subTribeThreeEnglish"
              />
            </div>
          </div>


            </div>
            <div class="col-md-5">
              <h6 slot="header" class="card-title">Sub Tribes - Arabic</h6>
              <div class="row justify-content-center">
            <div class="col-form-label">
              <label>1</label>
            </div>
            <div class="col-md-9">
              <base-input v-model="subTribeOneArabic"/>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-form-label">
              <label>2</label>
            </div>
            <div class="col-md-9">
              <base-input v-model="subTribeTwoArabic"/>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-form-label">
              <label>3</label>
            </div>
            <div class="col-md-9">
              <base-input v-model="subTribeThreeArabic"/>
            </div>
          </div>
            </div>      


            </div>
          </div>
        </card>
      </div>
    </div>
</template>
<script>
import StatsCard from 'src/components/Cards/StatsCard';
import UpdatedCard from 'src/components/Cards/updatedCard';
import swal from 'sweetalert2';
import { Select, Option } from 'element-ui';
import TribesServices from '@/services/TribesService'
import SearchService from '@/services/SearchService'
import DeleteService from '@/services/DeleteService'
import { Modal, BaseAlert } from 'src/components';
import { Loading } from 'element-ui';

export default {
  components: {
    StatsCard,
    UpdatedCard,
    [Option.name]: Option,
    [Select.name]: Select,
    Modal,
    BaseAlert,
  },
  data() {
    return {
      tribeCountry: '',
      countries: [],
      titleSwap: 'Arabic Name',
      englishName: '',
      arabicName: '',
      tribe: 
        {
          title: '',
          subTitle: 'English Name',
          type: 'primary',
          icon: 'tim-icons icon-paper',
        },
      tribeDate:
      {
          title: '',
          subTitle: 'Date Entered',
          secondTitle: '',
          secondSubTitle: 'Last Updated',
          type: 'warning',
          icon: 'tim-icons icon-time-alarm',
      },
        country: '',
        ethnicity: '',
        confederationEnglish: '',
        confederationArabic: '',
        subTribeOneEnglish: '',
        subTribeOneArabic: '',
        subTribeTwoEnglish: '',
        subTribeTwoArabic: '',
        subTribeThreeEnglish: '',
        subTribeThreeArabic: '',
        modelValidations: {
          englishName: {required: true}, 
          arabicName: {required: true},
          country: {required: true}
        }
    }
  },
  mounted() {
    this.getNations()
    this.getTribe()
  },
  // watch: {
  //   $route(to, from) {
  //     // react to route changes...
  //     let loading = Loading.service()
  //     this.getNations()
  //     this.getTribe()
  //     loading.close()
  //   }
  // },
  methods: {
    changeName(){
      if (this.titleSwap == 'Arabic Name') {
        // change to arabic
        this.tribe.title = this.arabicName
        this.tribe.subTitle = "Arabic Name"
        this.titleSwap = "English Name"
      }
      else if (this.titleSwap == 'English Name') {
        // change to english
        this.tribe.title = this.englishName
        this.tribe.subTitle = "English Name"
        this.titleSwap = "Arabic Name"
      }
    },
    editEnglishName() {
      swal({
          title: 'Edit Tribe',
          text: 'Change English Name',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValue: this.englishName,
          inputValidator: (value) => {
            if (!value) {
              return 'You need to write something!'
            }
            if (value != this.englishName) {
              this.updateEnglishName(value)
            }
          }
        })
    },
    editArabicName() {
      swal({
          title: 'Edit Tribe',
          text: 'Change Arabic Name',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValue: this.arabicName,
          inputValidator: (value) => {
            if (!value) {
              return 'You need to write something!'
            }
            if (value != this.arabicName) {
              this.updateArabicName(value)
            }
          }
        })
    },
    deleteRecord() {
      swal({
          title: 'Delete Tribe',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove this record from the database'
            }
            if (value == 'DELETE'){
              this.deleteTribe()
            }
          }
        })
    },
    async getTribe () {
      let loading = Loading.service()
      const tribe = await TribesServices.getTribe({
        id: this.$route.params.id
      })
      this.tribeCountry = tribe.data.country, 
      this.ethnicity = tribe.data.ethnicity, 
      this.englishName = tribe.data.tribe_en,
      this.arabicName = tribe.data.tribe_ar,
      this.confederationEnglish = tribe.data.confederation_en, 
      this.confederationArabic = tribe.data.confederation_ar, 
      this.subTribeOneEnglish = tribe.data.firstsubtribe_en, 
      this.subTribeOneArabic = tribe.data.firstsubtribe_ar, 
      this.subTribeTwoEnglish = tribe.data.secondsubtribe_en, 
      this.subTribeTwoArabic = tribe.data.secondsubtribe_ar, 
      this.subTribeThreeEnglish = tribe.data.thirdsubtribe_en, 
      this.subTribeThreeArabic = tribe.data.thirdsubtribe_ar
      if (tribe.data.lastupdated == null) {
        this.tribeDate.secondTitle = new Date(tribe.data.lastupdated.substring(0, 10)).toDateString()
      } else {
        this.tribeDate.secondTitle = ""
      }
      
      this.tribeDate.title = new Date(parseInt(tribe.data._id.substring(0, 8), 16) * 1000).toDateString()
      if (this.titleSwap == 'Arabic Name') {
        this.tribe.title = tribe.data.tribe_en
      } else {
        this.tribe.title = tribe.data.tribe_ar
      }
      loading.close()
    },
    async deleteTribe() {
      await DeleteService.deleteTribe({
        id: this.$route.params.id,
      })
      this.$router.push({ name: 'Search'})
    },
    async updateEnglishName(newName) {
      let loading = Loading.service()
      let newTribe = await TribesServices.updateTribe({
        id: this.$route.params.id,
        tribe_en: newName,
        lastupdated: new Date()
      })
      this.getTribe()
      loading.close()
      if (newTribe.status != 200) {
        this.notifyFailure()
      } else {
        this.notifySuccess()
      }
    },
    async updateArabicName(newName) {
      let loading = Loading.service()
      let newTribe = await TribesServices.updateTribe({
        id: this.$route.params.id,
        tribe_ar: newName,
        lastupdated: new Date()
      })
      this.getTribe()
      loading.close()
      if (newTribe.status != 200) {
        this.notifyFailure()
      } else {
        this.notifySuccess()
      }
    },
    async getNations() {
      const activeNations = window.GLOBALNATIONS
      activeNations.forEach(country => {
        const id = country.search
        this.countries[id] = country.search
      });
    },
    saveDetails() {
      this.isReadOnly = true
      this.updateTribeDetails()
    },
    editCountry() {
      swal({
          title: 'Change Country',
          text: 'Select a country below',
          input: 'select',
          inputOptions: this.countries,
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == this.sourceCountry) {
              return 'You have chosen the same value. Click Cancel if you do not wish to modify'
            }
            this.updateTribeCountry(value)
          }
        })
    },
    async updateTribeDetails() {
      let loading = Loading.service()
      let newTribe = await TribesServices.updateTribe({
        id: this.$route.params.id,
        ethnicity: this.ethnicity, 
        confederation_en: this.confederationEnglish, 
        confederation_ar: this.confederationArabic, 
        firstsubtribe_en: this.subTribeOneEnglish, 
        firstsubtribe_ar: this.subTribeOneArabic, 
        secondsubtribe_en: this.subTribeTwoEnglish, 
        secondsubtribe_ar: this.subTribeTwoArabic, 
        thirdsubtribe_en: this.subTribeThreeEnglish, 
        thirdsubtribe_ar: this.subTribeThreeArabic, 
        lastupdated: new Date(),
      })
      this.getTribe()
      loading.close()
      if (newTribe.status != 200) {
        this.notifyFailure()
      } else {
        this.notifySuccess()
      }
    },
    async updateTribeCountry(newCountry) {
      let loading = Loading.service()
      let newTribe = await TribesServices.updateTribe({
        id: this.$route.params.id,
        country: newCountry,
        lastupdated: new Date(),
      })
      this.getTribe()
      loading.close()
      if (newTribe.status != 200) {
        this.notifyFailure()
      } else {
        this.notifySuccess()
      }
    },
    notifySuccess() {
      this.$notify({
        message:
          'Record Updated',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "info"
      });
    },
    notifyFailure() {
      this.$notify({
        message:
          'Update Failed',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "danger"
      });
    },
  }
};
</script>
<style lang="scss"></style>
