<template><div>
  <div class="row">
    <div class="col-lg-4 col-md-3" >
      <stats-card
        :title='event.title'
        :sub-title='event.subTitle'
        :type="event.type"
        :icon="event.icon">
        <div slot="footer" class="row">
          <div class="col-md-10">
          </div>
          <div class="col-md-2 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
            <div v-if="$can('update', 'mandatory')">
              <a class="dropdown-item" @click="modalTitle = true">Edit Title</a>
            </div>
              <div v-if="$can('delete', 'all')">
              <a class="dropdown-item" @click="deleteRecord">Delete Event</a>
              </div>
            </base-dropdown>
          </div>
        </div>
      </stats-card>
    </div>

    <modal :show.sync="modalTitle" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Title</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="event.title"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalTitle = false">Cancel</base-button>
            <base-button type="success" @click.native="updateEventTitle()">Save</base-button>
          </div>
        </modal>



    <div class="col-lg-3 col-md-3">
      <updated-card
        :title='eventDateCard.title'
        :sub-title='eventDateCard.subTitle'
        :second-sub-title='eventDateCard.secondSubTitle'
        :second-title='eventDateCard.secondTitle'
        :type="eventDateCard.type"
        :icon="eventDateCard.icon">
      </updated-card>
    </div>
    
    <div class="col-md-2">
      <el-carousel :interval="10000" height="150px">
        <el-carousel-item v-for="item in images" :key="item">
          <img :src="item.image" style="max-height: 150px; width: auto">
        </el-carousel-item>
      </el-carousel>

    </div>
    <div class="col-md-1">
    </div>

    <div class="col-md-2">
      <image-upload @child-change="imageToUpload" @child-save="saveImage" select-text="Add Picture" />
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <card>
        <div class="row">
          <div class="col-md-11">
            <h5>Details</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
              <h6 class="dropdown-header">Details Menu</h6>
              <div v-if="$can('update', 'mandatory')">
              <a class="dropdown-item" @click="modalDate = true">Edit Date</a>
              <a class="dropdown-item" @click="modalType = true">Edit Type</a>
              <a class="dropdown-item" @click="modalCountry = true">Edit Country</a>
              </div>
              <a class="dropdown-item" @click="modalLocationArea = true">Edit Location Area</a>
              <a class="dropdown-item" @click="modalSpecificLocation = true">Edit Specific Location</a>
              <a class="dropdown-item" @click="modalSourceReference = true">Edit Source Reference</a>
              <a class="dropdown-item" @click="modalReportReference = true">Edit Report Reference</a>
            </base-dropdown>
          </div>
        </div>

        <modal :show.sync="modalDate" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Date</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="event.eventDate"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalDate = false">Cancel</base-button>
            <base-button type="success" @click.native="updateEventDate()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalType" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Type</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input>
                <el-select
                  name="selects.type"
                  id="selects.type"
                  class="select-info"
                  size="medium"
                  placeholder=""
                  filterable
                  v-model="event.type"
                >
                  <el-option
                    v-for="option in types"
                    class="select-info"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalType = false">Cancel</base-button>
            <base-button type="success" @click.native="updateEventType()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalCountry" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Country</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input>
                <el-select
                  name="selects.country"
                  id="selects.country"
                  class="select-info"
                  size="medium"
                  placeholder=""
                  filterable
                  v-model="eventCountry"
                >
                  <el-option
                    v-for="option in countries"
                    class="select-info"
                    :value="option.search"
                    :label="option.search"
                    :key="option.search"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalCountry = false">Cancel</base-button>
            <base-button type="success" @click.native="updateEventCountry()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalLocationArea" headerClasses="justify-content-center" class="modal-mini modal-black">
          <h4 slot="header" class="title title-up">Edit Location Area</h4>
          <p>
            Enter location below and click search
          </p>
          <br>
          <div class="row">
            <div class="col-md-9">
              <base-input v-model="searchBarGeneralLocation"/>
            </div> 
            <div class="col-md-3">
              <base-button size="sm" type="info" @click.native="searchGeneralLocations()">Search</base-button>
            </div>
            <div class="col-md-12">
              <el-table :data="searchGeneralLocationData" height="350">
                <el-table-column
                  key="_id"
                  min-width=150
                  prop="search"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="addLocationArea(props.row._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-link-72"></i>
                  </base-button>
                </div>
              </el-table-column>
              </el-table>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalLocationArea = false">Cancel</base-button>
          </div>
        </modal>

        <modal :show.sync="modalSpecificLocation" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Specific Location</h4>
          <p>
            Enter location below and click search
          </p>
          <br>
          <div class="row">
            <div class="col-md-9">
              <base-input v-model="searchBarSpecificLocation"/>
            </div> 
            <div class="col-md-3">
              <base-button size="sm" type="info" @click.native="searchSpecificLocation()">Search</base-button>
            </div>
            <div class="col-md-12">
              <el-table :data="searchSpecificLocationData" height="350">
                <el-table-column
                  key="name_en"
                  min-width=150
                  prop="name_en"
                  label="Name"
                />
                <el-table-column
                  key="latitude"
                  min-width=50
                  prop="latitude"
                  label="Lat"
                />
                <el-table-column
                  key="longitude"
                  min-width=50
                  prop="longitude"
                  label="Long"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="addSpecificLocation(props.row._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-link-72"></i>
                  </base-button>
                </div>
              </el-table-column>
              </el-table>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalSpecificLocation = false">Cancel</base-button>
            <base-button type="info" @click.native="newSpecificLocation">Add New</base-button>
          </div>
        </modal>

        <modal :show.sync="modalReportReference" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Report Reference</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="event.reportReference"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalReportReference = false">Cancel</base-button>
            <base-button type="success" @click.native="updateEventDetails()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalSourceReference" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Source Reference</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="event.sourceReference"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalSourceReference = false">Cancel</base-button>
            <base-button type="success" @click.native="updateEventDetails()">Save</base-button>
          </div>
        </modal>
  
        <div class="form-horizontal justify-content-center"> 
          <div class="row">  
            <label class="col-md-4 col-form-label">Date</label>
            <div class="col-md-6">
              <base-input disabled v-model="event.eventDateHuman"/>
            </div>
          </div>

          <div class="row">  
            <label class="col-md-4 col-form-label">Type</label>
            <div class="col-md-6">
              <base-input disabled v-model="event.type"/>
            </div>
          </div>

          <div class="row">  
            <label class="col-md-4 col-form-label">Country</label>
            <div class="col-md-6">
              <base-input disabled v-model="eventCountry"/>
            </div>
          </div>

          <br>
        
          <div class="row">
            <label class="col-md-3 col-form-label">Location Area</label> 
            <div class="col-md-8">
              <base-input
                name="locationArea"
                id="locationArea"
                placeholder=""
                v-model="event.generalLocation"
                readonly
              />
            
            </div>
          </div>

          <div class="row">
            <label class="col-md-3 col-form-label">Specific Location</label> 
            <div class="col-md-8">
              <base-input
                name="specificLocation"
                id="specificLocation"
                placeholder=""
                v-model="event.specificLocation"
                readonly
              />
            </div>
          </div>

          <div class="row">
            <label class="col-md-3 col-form-label">Source Reference</label> 
            <div class="col-md-8">
              <base-input
                name="sourceReference"
                id="sourceReference"
                placeholder=""
                v-model="event.sourceReference"
                readonly
              />
            </div>
          </div>

          <div class="row">
            <label class="col-md-3 col-form-label">Report Reference</label> 
            <div class="col-md-8">
              <base-input
                name="reportReference"
                id="reportReference"
                placeholder=""
                v-model="event.reportReference"
                readonly
              />
            </div>
          </div>
        </div>
      </card>

      <card >
        <div class="row">
          <div class="col-md-11">
            <h5>Event Description</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-button
                    @click.native="modalNote = true"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-add"></i>
                  </base-button>
          </div>
        </div>

        <modal :show.sync="modalNote" headerClasses="justify-content-center" class="modal-mini modal-black">
          <h4 slot="header" class="title title-up">Add Note</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <textarea class="form-control" rows=8 v-model="newNote"></textarea>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalNote = false">Cancel</base-button>
            <base-button type="success" @click.native="createNote()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalNoteReplace" headerClasses="justify-content-center" class="modal-mini modal-black">
          <h4 slot="header" class="title title-up">Edit Note</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <textarea class="form-control" rows=8 v-model="replaceNoteText"></textarea>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalNoteReplace = false">Cancel</base-button>
            <base-button type="success" @click.native="replaceNote()">Save</base-button>
          </div>
        </modal>
  
        <div class="form-horizontal"> 
          <div class="row">  
            <div class="col-md-11">
              <el-table :data="notes" :show-header="false">
                <el-table-column
                  v-for="column in notesColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="editNote(props.$index, props.row)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="col-form-label ml-auto">
                {{ notesFrom + 1 }}-{{ notesTo }} of {{ notesTotal }}
            </div>
          
                <base-pagination
                  class="pagination-no-border"
                  v-model="notesPagination.currentPage"
                  :per-page="notesPagination.perPage"
                  :total="notesTotal"
                >
                </base-pagination>
          </div>
        </div>      
      </div>
    </card>
    </div>

    <div class="col-md-6">
      <card>
        <div class="row">
          <div class="col-md-11">
            <h5>Links</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-button
                    @click.native="addLink()"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-link-72"></i>
                  </base-button>
          </div>
        </div>

        <tabs square type="info">
            <tab-pane label="Actor">
              <div class="form-horizontal"> 
          <div class="row">  
            <div class="col-md-6">
              <el-table :data="linkActorOrg">
                <el-table-column
                  v-for="column in linkActorOrgColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewOrganisation(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkEventOrgActor(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              </el-table>
              <div id="linkActorOrg" class="row">
                <div class="col-form-label ml-auto">
                  {{ linkActorOrgFrom + 1 }}-{{ linkActorOrgTo }} of {{ linkActorOrgTotal }}
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="linkActorOrgPagination.currentPage"
                  :per-page="linkActorOrgPagination.perPage"
                  :total="linkActorOrgTotal"
                />
              </div>
            </div>

            <div class="col-md-6">
              <el-table :data="linkActorSoul">
                <el-table-column
                  v-for="column in linkActorSoulColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewSoul(props.row.soulID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkEventSoulActor(props.row.soulID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              </el-table>
              <div id="linkActorSoul" class="row">
                <div class="col-form-label ml-auto">
                  {{ linkActorSoulFrom + 1 }}-{{ linkActorSoulTo }} of {{ linkActorSoulTotal }}
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="linkActorSoulPagination.currentPage"
                  :per-page="linkActorSoulPagination.perPage"
                  :total="linkActorSoulTotal"
                />
              </div>
            </div>
          </div>  
        </div>
      </tab-pane>
            
      <tab-pane label="Target">
        <div class="form-horizontal"> 
          <div class="row">  
            <div class="col-md-6">
              <el-table :data="linkTargetOrg">
                <el-table-column
                  v-for="column in linkTargetOrgColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewOrganisation(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkEventOrgTarget(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              </el-table>
              <div id="linkTargetOrg" class="row">
                <div class="col-form-label ml-auto">
                  {{ linkTargetOrgFrom + 1 }}-{{ linkTargetOrgTo }} of {{ linkTargetOrgTotal }}
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="linkTargetOrgPagination.currentPage"
                  :per-page="linkTargetOrgPagination.perPage"
                  :total="linkTargetOrgTotal"
                />
              </div>
            </div>

            <div class="col-md-6">
              <el-table :data="linkTargetSoul">
                <el-table-column
                  v-for="column in linkTargetSoulColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewSoul(props.row.soulID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkEventSoulTarget(props.row.soulID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              </el-table>
              <div id="linkTargetSoul" class="row">
                <div class="col-form-label ml-auto">
                  {{ linkTargetSoulFrom + 1 }}-{{ linkTargetSoulTo }} of {{ linkTargetSoulTotal }}
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="linkTargetSoulPagination.currentPage"
                  :per-page="linkTargetSoulPagination.perPage"
                  :total="linkTargetSoulTotal"
                />
              </div>
            </div>
          </div>  
        </div>
            </tab-pane>
            
          </tabs>
      </card>


      <card class="col-md-6">
        <div class="row">
          <div class="col-md-10">
            <h5>Statistics</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-button
                    @click.native="modalStatistics = true"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
          </div>


        <div class="form-horizontal"> 
          <div class="row">  
            <div class="col-md-12">
              <el-table :data="statisticsTable" :show-header="false">
                <el-table-column
                  v-for="column in statisticsColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
              </el-table>
            </div>
          </div>      
        </div>

    <modal :show.sync="modalStatistics" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Statistics</h4>
          <br>
          <div class="row">
            <div class="form-horizontal justify-content-center"> 
                <div class="row">
                  <label class="col-md-6 col-form-label">Arrested</label> 
                  <div class="col-md-6">
                    <base-input v-model="statistics.arrested"/>
                  </div>
                </div>
                <div class="row">
                  <label class="col-md-6 col-form-label">Dead</label> 
                  <div class="col-md-6">
                    <base-input v-model="statistics.dead"/>
                  </div>
                </div>
                <div class="row">
                  <label class="col-md-6 col-form-label">Injured</label> 
                  <div class="col-md-6">
                    <base-input v-model="statistics.injured"/>
                  </div>
                </div>
                <div class="row">
                  <label class="col-md-6 col-form-label">Kidnapped</label> 
                  <div class="col-md-6">
                    <base-input v-model="statistics.kidnapped"/>
                  </div>
                </div>
                <div class="row">
                  <label class="col-md-6 col-form-label">Migrants</label> 
                  <div class="col-md-6">
                    <base-input v-model="statistics.migrants"/>
                  </div>
                </div>
                <div class="row">
                  <label class="col-md-6 col-form-label">Protestors</label> 
                  <div class="col-md-6">
                    <base-input v-model="statistics.protestors"/>
                  </div>
                </div>
                <div class="row">
                  <label class="col-md-6 col-form-label">Surrendered</label> 
                  <div class="col-md-6">
                    <base-input v-model="statistics.surrendered"/>
                  </div>
                </div>
                <div class="row">
                  <label class="col-md-6 col-form-label">Weapons Found</label> 
                  <div class="col-md-6">
                    <base-input v-model="statistics.weaponsFound"/>
                  </div>
                </div>
                <div class="row">
                  <label class="col-md-6 col-form-label">IEDs</label> 
                  <div class="col-md-6">
                    <base-input v-model="statistics.IEDs"/>
                  </div>
                </div>
                <div class="row">
                  <label class="col-md-6 col-form-label">Locations Targeted</label> 
                  <div class="col-md-6">
                    <base-input v-model="statistics.locationsTargeted"/>
                  </div>
                </div>
              </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalStatistics = false">Cancel</base-button>
            <base-button type="success" @click.native="updateStatistics()">Save</base-button>
          </div>
        </modal>


        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import StatsCard from 'src/components/Cards/StatsCard';
import UpdatedCard from 'src/components/Cards/updatedCard';
import swal from 'sweetalert2';
import { DatePicker, TimeSelect, Table, TableColumn, Select, Option, Carousel, CarouselItem, Loading } from 'element-ui';
import { BasePagination, TabPane, Tabs } from 'src/components';
import { ImageUpload } from 'src/components/index';
import EventsService from '@/services/EventsService'
import SearchService from '@/services/SearchService'
import { Modal, BaseAlert } from 'src/components'
import DeleteService from '@/services/DeleteService'

export default {
  components: {
    TabPane,
    Tabs,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    StatsCard,
    UpdatedCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    BasePagination,
    ImageUpload,
    Modal,
    BaseAlert,
  },
  computed: {
    notes() {
      return this.notesData.slice(this.notesFrom, this.notesTo);
    },
    notesTo() {
      let highBound = this.notesFrom + this.notesPagination.perPage;
      if (this.notesTotal < highBound) {
        highBound = this.notesTotal;
      }
      return highBound;
    },
    notesFrom() {
      return this.notesPagination.perPage * (this.notesPagination.currentPage - 1);
    },
    notesTotal() {
      return this.notesData.length;
    },
    linkActorOrg() {
      return this.linkActorOrgData.slice(this.linkActorOrgFrom, this.linkActorOrgTo);
    },
    linkActorOrgTo() {
      let highBound = this.linkActorOrgFrom + this.linkActorOrgPagination.perPage;
      if (this.linkActorOrgTotal < highBound) {
        highBound = this.linkActorOrgTotal;
      }
      return highBound;
    },
    linkActorOrgFrom() {
      return this.linkActorOrgPagination.perPage * (this.linkActorOrgPagination.currentPage - 1);
    },
    linkActorOrgTotal() {
      return this.linkActorOrgData.length;
    },
    linkActorSoul() {
      return this.linkActorSoulData.slice(this.linkActorSoulFrom, this.linkActorSoulTo);
    },
    linkActorSoulTo() {
      let highBound = this.linkActorSoulFrom + this.linkActorSoulPagination.perPage;
      if (this.linkActorSoulTotal < highBound) {
        highBound = this.linkActorSoulTotal;
      }
      return highBound;
    },
    linkActorSoulFrom() {
      return this.linkActorSoulPagination.perPage * (this.linkActorSoulPagination.currentPage - 1);
    },
    linkActorSoulTotal() {
      return this.linkActorSoulData.length;
    },

    linkTargetOrg() {
      return this.linkTargetOrgData.slice(this.linkTargetOrgFrom, this.linkTargetOrgTo);
    },
    linkTargetOrgTo() {
      let highBound = this.linkTargetOrgFrom + this.linkTargetOrgPagination.perPage;
      if (this.linkTargetOrgTotal < highBound) {
        highBound = this.linkTargetOrgTotal;
      }
      return highBound;
    },
    linkTargetOrgFrom() {
      return this.linkTargetOrgPagination.perPage * (this.linkTargetOrgPagination.currentPage - 1);
    },
    linkTargetOrgTotal() {
      return this.linkTargetOrgData.length;
    },
    linkTargetSoul() {
      return this.linkTargetSoulData.slice(this.linkTargetSoulFrom, this.linkTargetSoulTo);
    },
    linkTargetSoulTo() {
      let highBound = this.linkTargetSoulFrom + this.linkTargetSoulPagination.perPage;
      if (this.linkTargetSoulTotal < highBound) {
        highBound = this.linkTargetSoulTotal;
      }
      return highBound;
    },
    linkTargetSoulFrom() {
      return this.linkTargetSoulPagination.perPage * (this.linkTargetSoulPagination.currentPage - 1);
    },
    linkTargetSoulTotal() {
      return this.linkTargetSoulData.length;
    },
  },
  // mounted() {
  //   this.getNations()
  //   this.getEvent()
  // },
  activated() {
    this.getEvent()
    this.getNations()
  },
  data() {
    return {
      searchBarGeneralLocation: '',
      searchGeneralLocationData: [],
      searchBarSpecificLocation: '',
      searchSpecificLocationData: [],
      modalTitle: false,
      modalDate: false,
      modalType: false,
      modalCountry: false,
      modalLocationArea: false,
      modalSpecificLocation: false,
      modalSourceReference: false,
      modalReportReference: false,
      modalStatistics: false,
      modalNote: false,
      modalNoteReplace: false,
      newNote: '',
      replaceNoteText: '',
      replaceNoteID: '',
      isReadOnly: true,
      newImage: '',
      images: [],
      selectedFile: null,
      selects: {
        specificLocation: null,
        generalLocation: null,
        type: null,
      },
      event: {
        title: '',
        subTitle: 'Event Title',
        type: 'primary',
        icon: 'tim-icons icon-sound-wave',
        eventDate: '',
        eventDateHuman: '',
        type: '',
        sourceReference: '',
        reportReference: '',
        specificLocation: '',
        locationArea: '',
      },
      statistics: {
        arrested: '',
        dead: '',
        injured: '',
        kidnapped: '',
        migrants: '',
        protestors: '',
        surrendered: '',
        weaponsFound: '',
        IEDs: '',
        locationsTargeted: '',
      },
      statisticsTable: [],
      eventCountry: '',
      types: [
          { value: 'Air Strike', label: 'Air Strike' },
          { value: 'Armed Attack', label: 'Armed Attack' },
          { value: 'Arrest', label: 'Arrest' },
          { value: 'IED', label: 'IED' },
          { value: 'VBIED', label: 'VBIED' },
          { value: 'Event', label: 'Event' },
          { value: 'Communication', label: 'Communication' },
          { value: 'Sighting', label: 'Sighting' },
          { value: 'Migration', label: 'Migration' },
          { value: 'Protest', label: 'Protest' },
          { value: 'Worker Strike', label: 'Worker Strike' },
          { value: 'Weapons Find', label: 'Weapons Find' },
          { value: 'Surrender', label: 'Surrender' },
          { value: 'Kidnapping', label: 'Kidnapping' },
          { value: 'Smuggling', label: 'Smuggling' },
          { value: 'Attack Planning', label: 'Attack Planning' },
          { value: 'Movement', label: 'Movement' },
          { value: 'Human Rights', label: 'Human Rights' },
          { value: 'Military Operation', label: 'Military Operation' },
          { value: 'Military Training', label: 'Military Training' },
          { value: 'Ground Control', label: 'Ground Control' },
        ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      eventDateCard:{
        title: '',
        subTitle: 'Date Entered',
        secondTitle: '',
        secondSubTitle: 'Last Updated',          
        type: 'warning',
        icon: 'tim-icons icon-time-alarm',
      },
      notesData:          [],
      linkActorOrgData:   [],
      linkActorSoulData:  [],
      linkTargetOrgData:  [],
      linkTargetSoulData: [],
      countries:          [],
      generalLocations:   [],
      specificLocations:  [],
      notesPagination:          { perPage: 5, currentPage: 1, total: 0 },
      linkActorOrgPagination:   { perPage: 5, currentPage: 1, total: 0 },
      linkActorSoulPagination:  { perPage: 5, currentPage: 1, total: 0 },
      linkTargetOrgPagination:  { perPage: 5, currentPage: 1, total: 0 },
      linkTargetSoulPagination: { perPage: 5, currentPage: 1, total: 0 },
      linkActorOrgColumns:    [{ prop: 'orgID.org_en', label: 'Organisations', minWidth: 200 }],
      linkActorSoulColumns:   [{ prop: 'soulID.nameEnglish', label: 'People', minWidth: 200 }],
      linkTargetOrgColumns:   [{ prop: 'orgID.org_en', label: 'Organisations', minWidth: 200 }],
      linkTargetSoulColumns:  [{ prop: 'soulID.nameEnglish', label: 'People', minWidth: 200 }],
      notesColumns:           [{ prop: 'note', label: 'Note', minWidth: 250 }],
      statisticsColumns:      [{ prop: 'category', minWidth: 200 },{ prop: 'number', minWidth: 100 }],
    }
  },
  // watch: {
  //   $route(from, to) {
  //     if (this.$route.name == "View Event") {
  //       this.getEvent()
  //     }
  //   }
  // },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async getEvent () {
      let loading = Loading.service()
      this.statisticsTable = []
      const event = await EventsService.getEvent({
        id: this.$route.params.id
      })
      this.event.eventDate = event.data.date
      this.event.eventDateHuman = new Date(event.data.date.substring(0, 10)).toDateString()
      this.eventCountry = event.data.country
      this.event.type = event.data.type
      if (event.data.specific_location != null) {
        this.event.specificLocation = event.data.specific_location.name_en
      }
      if (event.data.general_location != null) {
        this.event.generalLocation = event.data.general_location.search
      }
      this.notesData = event.data.notes
      this.event.sourceReference = event.data.sourceref
      this.event.reportReference = event.data.reportref
      this.images = event.data.image
      this.linkActorOrgData = event.data.actorOrg
      this.linkActorSoulData = event.data.actorSoul
      this.linkTargetOrgData = event.data.targetOrg
      this.linkTargetSoulData = event.data.targetSoul
      if (event.data.lastupdated != null) {
        this.eventDateCard.secondTitle = new Date(event.data.lastupdated.substring(0, 10)).toDateString()
      }
      this.eventDateCard.title = new Date(parseInt(event.data._id.substring(0, 8), 16) * 1000).toDateString()
      this.event.title = event.data.title
      if (event.data.actorOrg.length == 0){
        document.getElementById("linkActorOrg").style.display = "none"
      } else{
        document.getElementById("linkActorOrg").style.display = "flex"
      }
      if (event.data.actorSoul.length == 0){
        document.getElementById("linkActorSoul").style.display = "none"
      } else{
        document.getElementById("linkActorSoul").style.display = "flex"
      }
      if (event.data.targetOrg.length == 0){
        document.getElementById("linkTargetOrg").style.display = "none"
      } else {
        document.getElementById("linkTargetOrg").style.display = "flex"
      }
      if (event.data.targetSoul.length == 0){
        document.getElementById("linkTargetSoul").style.display = "none"
      } else{
        document.getElementById("linkTargetSoul").style.display = "flex"
      }
      this.statisticsTable = []
      if(event.data.arrested != null){
        this.statistics.arrested = event.data.arrested
        this.statisticsTable.push({category: "Arrested", number: event.data.arrested})
      }
      if(event.data.dead != null){
        this.statistics.dead = event.data.dead
        this.statisticsTable.push({category: "Dead", number: event.data.dead})
      }
      if(event.data.injured != null){
        this.statistics.injured = event.data.injured
        this.statisticsTable.push({category: "Injured", number: event.data.injured})
      }
      if(event.data.kidnapped != null){
        this.statistics.kidnapped = event.data.kidnapped
        this.statisticsTable.push({category: "Kidnapped", number: event.data.kidnapped})
      }
      if(event.data.migrants != null){
        this.statistics.migrants = event.data.migrants
        this.statisticsTable.push({category: "Migrants", number: event.data.migrants})
      }
      if(event.data.protestors != null){
        this.statistics.protestors = event.data.protestors
        this.statisticsTable.push({category: "Protestors", number: event.data.protestors})
      }
      if(event.data.surrendered != null){
        this.statistics.surrendered = event.data.surrendered
        this.statisticsTable.push({category: "Surrendered", number: event.data.surrendered})
      }
      if(event.data.weaponsFound != null){
        this.statistics.weaponsFound = event.data.weaponsFound
        this.statisticsTable.push({category: "Weapons Found", number: event.data.weaponsFound})
      }
      if(event.data.ieds != null){
        this.statistics.IEDs = event.data.ieds
        this.statisticsTable.push({category: "IEDs", number: event.data.ieds})
      }
      if(event.data.locationsTargeted != null){
        this.statistics.locationsTargeted = event.data.locationsTargeted
        this.statisticsTable.push({category: "Locations Targeted", number: event.data.locationsTargeted})
      }
      loading.close()
    },
    async updateStatistics(){
      this.modalStatistics = false
      let loading = Loading.service()
      let newDetails = await EventsService.updateEvent({
        id: this.$route.params.id,
        arrested: this.statistics.arrested,
        dead: this.statistics.dead,
        injured: this.statistics.injured,
        kidnapped: this.statistics.kidnapped,
        migrants: this.statistics.migrants,
        protestors: this.statistics.protestors,
        surrendered: this.statistics.surrendered,
        weaponsFound: this.statistics.weaponsFound,
        ieds: this.statistics.IEDs,
        locationsTargeted: this.statistics.locationsTargeted,
        lastupdated: new Date(),
      }).catch(err => loading.close().then(this.notifyFailure()))
      //this.statisticsTable = []
      loading.close()
      this.notifySuccess()
      this.getEvent()
    },
    async searchGeneralLocations() {
      let loading = Loading.service()
      const searchedLocations = await SearchService.searchGeneralLocations({searchTerms: this.searchBarGeneralLocation})
      this.searchGeneralLocationData = searchedLocations.data
      loading.close()
    },
    async searchSpecificLocation() {
      let loading = Loading.service()
      const searchedLocations = await SearchService.searchSpecificLocations({searchTerms: this.searchBarSpecificLocation})
      this.searchSpecificLocationData = searchedLocations.data
      loading.close()
    },
    async updateEventDetails(){
      let loading = Loading.service()
      let newDetails = await EventsService.updateEvent({
        id: this.$route.params.id,
        date: this.event.eventDate, 
        country: this.eventCountry, 
        type: this.event.type, 
        //specific_location: this.selects.specificLocation, 
        //general_location: this.selects.generalLocation, 
        sourceref: this.event.sourceReference, 
        reportref: this.event.reportReference, 
        lastupdated: new Date(),
      }).catch(err => loading.close().then(this.notifyFailure()))
      this.getEvent()
      this.modalReportReference = false
      this.modalSourceReference = false
      loading.close()
      this.notifySuccess()
    },
    async removeLinkEventSoulTarget(soulID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkEventSoulTargetDB(soulID)
            }
          }
        })
    },
    async removeLinkEventSoulTargetDB(soulID) {
      let loading = Loading.service()
      DeleteService.removeLinkEventSoulTarget({soul : soulID, event: this.$route.params.id})
      this.getEvent()
      loading.close()
    },
    async removeLinkEventSoulActor(soulID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkEventSoulActorDB(soulID)
            }
          }
        })
    },
    async removeLinkEventSoulActorDB(soulID) {
      let loading = Loading.service()
      await DeleteService.removeLinkEventSoulActor({soul : soulID, event: this.$route.params.id})
      await this.getEvent()
      loading.close()
    },
    async removeLinkEventOrgTarget(orgID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkEventOrgTargetDB(orgID)
            }
          }
        })
    },
    async removeLinkEventOrgTargetDB(orgID) {
      let loading = Loading.service()
      await DeleteService.removeLinkEventOrgTarget({org : orgID, event: this.$route.params.id})
      await this.getEvent()
      loading.close()
    },
    async removeLinkEventOrgActor(orgID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkEventOrgActorDB(orgID)
            }
          }
        })
    },
    async removeLinkEventOrgActorDB(orgID) {
      let loading = Loading.service()
      await DeleteService.removeLinkEventOrgActor({org : orgID, event: this.$route.params.id})
      await this.getEvent()
      loading.close()
    },
    async addLocationArea(location){
      let loading = Loading.service()
      let newDetails = await EventsService.updateEvent({
        id: this.$route.params.id,
        general_location: location, 
        lastupdated: new Date(),
      }).catch(err => loading.close().then(this.notifyFailure()))
      await this.getEvent()
      this.modalLocationArea = false
      loading.close()
      this.notifySuccess()
    },
    async addSpecificLocation(location){
      let loading = Loading.service()
      let newDetails = await EventsService.updateEvent({
        id: this.$route.params.id,
        specific_location: location, 
        lastupdated: new Date(),
      }).catch(err => loading.close().then(this.notifyFailure()))
      await this.getEvent()
      this.modalSpecificLocation = false
      loading.close()
      this.notifySuccess()
    },
    async updateEventTitle(){
      this.modalTitle = false
      let loading = Loading.service()
      let newEventTitle = await EventsService.updateEvent({
        id: this.$route.params.id,
        title: this.event.title, 
        lastupdated: new Date(),
      }).catch(err => loading.close().then(this.notifyFailure()))
      loading.close()
      this.notifySuccess()
      this.getEvent()
    },
    async createNote(){
      this.modalNote = false
      let loading = Loading.service()
      let newEventNote = await EventsService.createNote({
        id: this.$route.params.id,
        note: this.newNote, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      
      loading.close()
      this.notifySuccess()
      this.updateEventDetails()
      this.getEvent()
      this.newNote = ""
    },
    async replaceNote(){
      this.modalNoteReplace = false
      let loading = Loading.service()
      let newEventNote = await EventsService.replaceNote({
        id: this.$route.params.id,
        sid: this.replaceNoteID,
        note: this.replaceNoteText, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      loading.close()
      this.updateEventDetails()
      this.notifySuccess()
      this.getEvent()
    },
    async getNations() {
      this.countries = window.GLOBALNATIONS
      // const activeNations = await SearchService.fetchNations()
      // activeNations.data.forEach(country => {
      //   const id = country.search
      //   this.countries[id] = country.search
      // });
    },
    async viewSoul(soulID) {
      this.$router.push({ name: 'View Person', params: { id: soulID}})
    },
    async viewOrganisation(organisationID) {
      this.$router.push({ name: 'View Organisation', params: { id: organisationID}})
    },
    async getGeneralLocations(countrySelected) {
      const activeGeneralLocations = await SearchService.fetchLocations({country: countrySelected})
      this.generalLocations = activeGeneralLocations.data
    },
    async getSpecificLocations() {
      const activeSpecificLocations = await SearchService.fetchPOIs()
      this.specificLocations = activeSpecificLocations.data
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async updateEventDate() {
      let loading = Loading.service()
      let newEvent = await EventsService.updateEvent({
        id: this.$route.params.id,
        date: newDate,
        lastupdated: new Date()
      }).catch(err => loading.close().then(this.notifyFailure()))
      loading.close()
      this.updateEventDetails()
      this.notifySuccess()
      this.getEvent()
    },
    async updateEventType() {
      this.modalType = false
      let loading = Loading.service()
      let newEvent = await EventsService.updateEvent({
        id: this.$route.params.id,
        type: this.event.type,
        lastupdated: new Date()
      }).catch(err => loading.close().then(this.notifyFailure()))
      this.updateEventDetails()
      loading.close()
      this.notifySuccess()
      this.getEvent()
    },
    async updateEventCountry() {
      this.modalCountry = false
      let loading = Loading.service()
      let newEvent = await EventsService.updateEvent({
        id: this.$route.params.id,
        country: this.eventCountry,
        lastupdated: new Date()
      }).catch(err => loading.close().then(this.notifyFailure()))
      this.updateEventDetails()
      loading.close()
      this.notifySuccess()
      this.getEvent()
    },
    saveDetails() {
      this.updateEventDetails()
      this.isReadOnly = true
    },
    addLink() {
      this.$router.push({name: 'Link', params: {id: this.$route.params.id, recordType: 'event', country: this.eventCountry, recordName: this.event.title}})
    },
    imageToUpload: function(params) {
      let reader = new FileReader()
      reader.readAsDataURL(params)
      reader.onload = e => {
        this.selectedFile = e.target.result
      }
    },
    saveImage: function(params) {
      this.uploadImage()
    },
    async uploadImage() {
      let loading = Loading.service()
      let newImage = await EventsService.updateEventImage({
        id: this.$route.params.id,
        image: this.selectedFile
      }).catch(err => loading.close().then(this.notifyFailure()))
      loading.close()
      this.updateEventDetails()
      this.getEvent()
      
      this.notifySuccess()
    },
    editNote(index, row) {
      this.modalNoteReplace = true
      this.replaceNoteText = this.notesData[index].note
      this.replaceNoteID = this.notesData[index]._id
      // swal({
      //     title: 'Edit Note',
      //     input: 'textarea',
      //     showCancelButton: true,
      //     confirmButtonText: 'Save',
      //     cancelButtonText: 'Cancel',
      //     confirmButtonClass: 'btn btn-success btn-fill',
      //     cancelButtonClass: 'btn btn-info btn-fill',
      //     buttonsStyling: false,
      //     allowOutsideClick: false,
      //     inputValue: this.notesData[index].note,
      //     inputValidator: (value) => {
      //       if (!value) {
      //         return 'You need to write something!'
      //       }
      //       if (value != this.notesData[index].note) {
      //         // its different so we need to update it
      //         this.replaceNote(this.notesData[index]._id,value)
      //       }
      //     }
      //   })
    },
    notifySuccess() {
      this.$notify({
        message:
          'Record Updated',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "info"
      });
    },
    notifyFailure() {
      this.$notify({
        message:
          'Update Failed',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "danger"
      });
    },
    deleteRecord() {
      swal({
          title: 'Delete Event',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove this record from the database'
            }
            if (value == 'DELETE'){
              this.deleteEvent()
            }
          }
        })
    },
    async deleteEvent() {
      let loading = Loading.service()
      await DeleteService.deleteEvent({
        id: this.$route.params.id,
      })
      this.$router.push({ name: 'Search'})
      loading.close()
    },
    async newSpecificLocation() {
      this.modalSpecificLocation = false
      this.$router.push({ name: 'Add Specific Location', params: { id: this.$route.params.id, type: "event"}})
    },
  }
};
</script>
<style lang="scss">
.pagination-select, .search-input {
  width: 200px;

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
}</style>
