<template>
  <div class="row justify-content-center">
    <div class="col-md-6 justify-content-center">
      <h5 class="info-text">
        Add more details
      </h5>
    </div>

    <div class="col-md-12 justify-content-center">
      <div class="form-horizontal justify-content-center"> 
        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Based In</label> 
          <div class="col-md-6">
            <base-input>
              <el-select
                name="generalLocation"
                id="generalLocation"
                class="select-info"
                size="medium"
                placeholder=""
                filterable
                v-model="selects.generalLocation"
              >
                <el-option
                  v-for="option in generalLocations"
                  class="select-info"
                  :value="option.search"
                  :label="option.search"
                  :key="option.search"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div> -->

        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Affiliation</label> 
          <div class="col-md-6">
            <base-input
              name="affiliation"
              id="affiliation"
              placeholder=""
              v-model="affiliation"
            />
          </div>
        </div> -->

        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Name (Arabic)</label> 
          <div class="col-md-6">
            <base-input
              name="arabicName"
              id="arabicName"
              placeholder=""
              v-model="arabicName"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Position (Arabic)</label> 
          <div class="col-md-6">
            <base-input
              name="arabicPosition"
              id="arabicPosition"
              placeholder=""
              v-model="arabicPosition"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { TimeSelect, DatePicker, Select, Option } from 'element-ui'
import SearchService from '@/services/SearchService'
import POIsService from '@/services/POIsService'

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props:['propCountry'],
  data() {
    return {
      affiliation: '',
      arabicName: '',
      arabicPosition: '',
      selects: {
        generalLocation: null,
      },
      generalLocations: [],
    };
  },
  // watch: {
  //   propCountry:function(value){
  //     this.getGeneralLocations(value)
  //   }
  // },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$emit("child-generallocation", this.selects.generalLocation)
      this.$emit("child-affiliation", this.affiliation)
      this.$emit("child-arabicname", this.arabicName)
      this.$emit("child-arabicposition", this.arabicPosition)
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model)
        return res;
      });
    },
    async getGeneralLocations(countrySelected) {
      const activeGeneralLocations = await SearchService.fetchLocations({country: countrySelected})
      this.generalLocations = activeGeneralLocations.data
    },
  },
};
</script>
<style></style>
