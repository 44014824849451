<template>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <h5 class="info-text">All done?</h5>
    </div>

    <div class="col-md-12 justify-content-center">

      <div class="form-horizontal justify-content-center"> 

    <div class="row justify-content-center">
        <label class="col-md-2 col-form-label">English Name</label> 
        <div class="col-md-6">
          <base-input
            name="englishName"
            id="englishName"
            v-model="propEnglishName"
            v-validate="modelValidations.englishName"
            :error="getError('englishName')"
          />
        </div>
        
      </div><div class="row justify-content-center">
        <label class="col-md-2 col-form-label">Arabic Name</label> 
        <div class="col-md-6">
          <base-input
            name="arabicName"
            id="arabicName"
            v-model="propArabicName"
            v-validate="modelValidations.arabicName"
            :error="getError('arabicName')"
          />
        </div>
        
      </div>
      <div class="row justify-content-center">
        <label class="col-md-2 col-form-label">Latitude</label> 
        <div class="col-md-6">
          <base-input
            name="latitude"
            id="latitude"
            v-model="propLatitude"
            v-validate="modelValidations.latitude"
            :error="getError('latitude')"
          />
        </div>
        
      </div>
      <div class="row justify-content-center">
        <label class="col-md-2 col-form-label">Longitude</label> 
        <div class="col-md-6">
          <base-input
            name="longitude"
            id="longitude"
            v-model="propLongitude"
            v-validate="modelValidations.longitude"
            :error="getError('longitude')"
          />
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option } from 'element-ui';
import POIsService from '@/services/POIsService'
import { Loading } from 'element-ui'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  props:['propEnglishName', 'propArabicName', 'propLatitude', 'propLongitude'],
  data() {
    return {
      modelValidations: {
        propEnglishName: {
          required: true,
          min: 1
        },
        propArabicName: {
          required: true,
          min: 1
        },
        latitude: {
          required: true,
        },
        longitude: {
          required: true,
        }
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async validate() {
      await this.addSpecificLocation()
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async addSpecificLocation () {
      let loading = Loading.service()
      const newSpecificLocation = await POIsService.addPOI({
        name_en: this.propEnglishName, 
        name_ar: this.propArabicName, 
        latitude: parseFloat(this.propLatitude), 
        longitude: parseFloat(this.propLongitude), 
        lastupdated: new Date(),
        notes: ''
      })
      loading.close()
      this.$emit("child-newSpecificLocation", newSpecificLocation)
    },
  }
};
</script>
<style></style>
