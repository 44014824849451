<template>
  <div class="row justify-content-center">
    <div class="col-md-6 justify-content-center">
      <h5 class="info-text">
        All done?
      </h5>
    </div>

    <div class="col-md-12 justify-content-center">
      <div class="form-horizontal justify-content-center"> 
        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input
              name="englishName"
              id="englishName"
              placeholder="English Name"
              v-model="propEnglishName"
              v-validate="modelValidations.englishName"
              :error="getError('englishName')"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input
              name="arabicName"
              id="arabicName"
              placeholder="Arabic Name"
              v-model="propArabicName"
              v-validate="modelValidations.arabicName"
              :error="getError('arabicName')"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input :error="getError('country')">
              <el-select
                name="country"
                id="country"
                class="select-info"
                size="medium"
                placeholder="Country"
                v-model="propCountry"
                v-validate="modelValidations.country"
              >
                <el-option
                  v-for="option in countries"
                  class="select-info"
                  :value="option.search"
                  :label="option.search"
                  :key="option.search"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input
              name="ethnicity"
              id="ethnicity"
              placeholder="Ethnicity"
              v-model="propEthnicity"
            />
          </div>
        </div>
        
      </div>
      <div class="row justify-content-center">
        <label class="col-md-2 col-form-label">Confederation - English</label> 
        <div class="col-md-4">
          <base-input
            name="confederationEnglish"
            id="confederationEnglish"
            placeholder=""
            v-model="propConfederationEnglish"
          />
        </div>
        <label class="col-md-2 col-form-label">Confederation - Arabic</label> 
        <div class="col-md-4">
          <base-input
            name="confederationArabic"
            id="confederationArabic"
            placeholder=""
            v-model="propConfederationArabic"
          />
        </div>
      </div>
      
      <div class="row justify-content-center">
        <label class="col-md-2 col-form-label">Sub Tribe 1 - English</label>
        <div class="col-md-4">
          <base-input
            name="subTribeOneEnglish"
            id="subTribeOneEnglish"
            placeholder=""
            v-model="propSubTribeOneEnglish"
          />
        </div>

          <label class="col-md-2 col-form-label">Sub Tribe 1 - Arabic</label> 

        <div class="col-md-4">
          <base-input
            name="subTribeOneArabic"
            id="subTribeOneArabic"
            placeholder=""
            v-model="propSubTribeOneArabic"
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <label class="col-md-2 col-form-label">Sub Tribe 2 - English</label> 
        <div class="col-md-4">
          <base-input
            name="subTribeTwoEnglish"
            id="subTribeTwoEnglish"
            placeholder=""
            v-model="propSubTribeTwoEnglish"
          />
        </div>
        <label class="col-md-2 col-form-label">Sub Tribe 2 - Arabic</label> 
        <div class="col-md-4">
          <base-input
            name="subTribeTwoArabic"
            id="subTribeTwoArabic"
            placeholder=""
            v-model="propSubTribeTwoArabic"
          />
        </div>
      </div>
            <div class="row justify-content-center">
        <label class="col-md-2 col-form-label">Sub Tribe 3 - English</label> 
        <div class="col-md-4">
          <base-input
            name="subTribeThreeEnglish"
            id="subTribeThreeEnglish"
            placeholder=""
            v-model="propSubTribeThreeEnglish"
          />
        </div>
        <label class="col-md-2 col-form-label">Sub Tribe 3 - Arabic</label> 
        <div class="col-md-4">
          <base-input
            name="subTribeThreeArabic"
            id="subTribeThreeArabic"
            placeholder=""
            v-model="propSubTribeThreeArabic"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option } from 'element-ui'
import TribesService from '@/services/TribesService'
import SearchService from '@/services/SearchService'
import { Loading } from 'element-ui'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  props:['propEnglishName', 'propArabicName', 'propCountry', 'propEthnicity', 'propConfederationEnglish', 'propConfederationArabic', 
    'propSubTribeOneEnglish', 'propSubTribeOneArabic','propSubTribeTwoEnglish', 'propSubTribeTwoArabic','propSubTribeThreeEnglish', 
    'propSubTribeThreeArabic'],
  data() {
    return {
      selects: {
        country:'',
      },
      ethnicity: '',
      confederationEnglish: '',
      confederationArabic: '',
      subTribeOneEnglish: '',
      subTribeOneArabic: '',
      subTribeTwoEnglish: '',
      subTribeTwoArabic: '',
      subTribeThreeEnglish: '',
      subTribeThreeArabic: '',
      countries: [],
      modelValidations: {
        englishName: {required: true}, 
        arabicName: {required: false},
        country: {required: true}
      }
    };
  },
  mounted(){
    this.getNations()
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.addTribe()
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async addTribe () {
      let loading = Loading.service()
      const newTribe = await TribesService.addTribe({
        country: this.propCountry, 
        ethnicity: this.propEthnicity, 
        tribe_en: this.propEnglishName,
        tribe_ar: this.propArabicName,
        confederation_en: this.propConfederationEnglish, 
        confederation_ar: this.propConfederationArabic, 
        firstsubtribe_en: this.propSubTribeOneEnglish, 
        firstsubtribe_ar: this.propSubTribeOneArabic, 
        secondsubtribe_en: this.propSubTribeTwoEnglish, 
        secondsubtribe_ar: this.propSubTribeTwoArabic, 
        thirdsubtribe_en: this.propSubTribeThreeEnglish, 
        thirdsubtribe_ar: this.propSubTribeThreeArabic, 
        lastupdated: new Date(),
      })
      loading.close()
      this.$emit("child-newTribe", newTribe)
    },
    async getNations() {
      this.countries = window.GLOBALNATIONS
    }
  }
};
</script>
<style></style>
