<template>
  <div class="row">
    
    <div class="col-md-3"><user-card> </user-card></div>
    <div class="col-md-8"><edit-profile-form> </edit-profile-form></div>
  </div>
</template> 
<script>
import EditProfileForm from './EditProfileForm.vue';
import UserCard from './UserCard.vue';

export default {
  components: {
    EditProfileForm,
    UserCard
  }
};
</script>
<style></style>
