<template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
    <h5 class="info-text">
      Lets start with mandatory details:
    </h5>
    </div>
    <div class="col-md-8">
      <base-input
        name="englishName"
        id="englishName"
        placeholder="English Name"
        v-model="englishName"
        v-validate="modelValidations.englishName"
        :error="getError('englishName')"
      >
      </base-input>
    </div>
    <div class="col-md-8">
      <base-input
        name="arabicName"
        id="arabicName"
        placeholder="Arabic Name"
        v-model="arabicName"
        v-validate="modelValidations.arabicName"
        :error="getError('arabicName')"
      >
      </base-input>
    </div>
    <div class="col-md-8">
      <base-input :error="getError('country')">
        <el-select
          name="country"
          id="country"
          class="select-info"
          size="medium"
          placeholder="Country"
          filterable
          v-model="selects.country"
          v-validate="modelValidations.country"
        >
          <el-option
            v-for="option in countries"
            class="select-info"
            :value="option.search"
            :label="option.search"
            :key="option.search"
          >
          </el-option>
        </el-select>
      </base-input>
    </div>
  </div>
</template>
<script>
import { Select, Option } from 'element-ui';
import SearchService from '@/services/SearchService'

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      englishName: '',
      arabicName:'',
      selects: {
        country:'',
      },
      countries: [],
      modelValidations: {
        englishName: {required: true}, 
        arabicName: {required: false},
        country: {required: true}
      }
    };
  },
  mounted() {
    this.getNations()
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$emit("child-englishname", this.englishName);
      this.$emit("child-arabicname", this.arabicName);
      this.$emit("child-country", this.selects.country);
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async getNations() {
      this.countries = window.GLOBALNATIONS
    }
  },
};
</script>
<style></style>
