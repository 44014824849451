<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard >
          <template slot="header">
            <h3 class="card-title">Add a New Event</h3>
            <h3 class="description">
              Add a new event to the database.
            </h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-caps-small"></i>
              <p>Event</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
              @child-eventdate="eventDateValue"
              @child-title="titleValue"
              @child-type="typeValue"
              @child-country="countryValue"
            ></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-pencil"></i>
              <p>Details</p>
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
              @child-generallocation="generalLocationValue"
              @child-specificlocation="specificLocationValue"
              @child-sourcereference="sourceReferenceValue"
              @child-reportreference="reportReferenceValue"
              @child-eventdescription="eventDescriptionValue"
              v-bind:propCountry="this.country"
            ></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p>Save</p>
            </template>
            <third-step ref="step3" 
              v-bind:propEventDate="this.eventDate" 
              v-bind:propTitle="this.title" 
              v-bind:propType="this.type"
              v-bind:propCountry="this.country"
              v-bind:propGeneralLocation="this.generalLocation"
              v-bind:propSpecificLocation="this.specificLocation"
              v-bind:propSourceReference="this.sourceReference"
              v-bind:propReportReference="this.reportReference"
              v-bind:propEventDescription="this.eventDescription"
              @child-newEvent="newEventValue"
              @on-validated="wizardComplete" ></third-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from './FirstStep.vue'
import SecondStep from './SecondStep.vue'
import ThirdStep from './ThirdStep.vue'
import swal from 'sweetalert2'
import { SimpleWizard, WizardTab } from 'src/components'
import LinkService from '@/services/LinkService'
import { Loading } from 'element-ui';

export default {
  data() {
    return {
      wizardModel: {},
      eventDate: '',
      title: '',
      country:'',
      type:'',
      generalLocation:'',
      specificLocation:'',
      sourceReference:'',
      reportReference:'',
      eventDescription:'',
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab,
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    eventDateValue: function(params) {
      this.eventDate = params;
    },
    titleValue: function(params) {
      this.title = params;
    },
    countryValue: function(params) {
      this.country = params;
    },
    typeValue: function(params) {
      this.type = params;
    },
    generalLocationValue: function(params) {
      this.generalLocation = params;
    },
    specificLocationValue: function(params) {
      this.specificLocation = params;
    },
    sourceReferenceValue: function(params) {
      this.sourceReference = params;
    },
    reportReferenceValue: function(params) {
      this.reportReference = params;
    },
    eventDescriptionValue: function(params) {
      this.eventDescription = params;
    },
    newEventValue: function(params) {
      this.newEvent = params;
    },
    async createLinkEventSoul(eventID, soulID, linkType) {
      await LinkService.createLinkEventSoul({
        eventID: eventID,
        soulID: soulID,
        linkType: linkType
      })
    },
    async createLinkEventOrganisation(eventID, organisationID, linkType) {
      await LinkService.createLinkEventOrganisation({
        eventID: eventID,
        orgID: organisationID,
        linkType: linkType
      })
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      if (this.$route.params.type == "soul") {
        swal({
          title: 'Link',
          text: 'Choose Below',
          input: 'radio',
          inputOptions: ({"actor":"Actor", "target":"Target"}),
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == null){
              return "Please select one"
            }
            this.createLinkEventSoul(this.newEvent.data._id, this.$route.params.id, value)
          }
        }).then((result) =>
          swal({
            title: 'Event Created',
            text: 'Would you like to view this Event or the original Person?',
            type: 'success',
            showCancelButton: true,
            confirmButtonText: 'View Original Person',
            cancelButtonText: 'View New Event',
            confirmButtonClass: 'btn btn-success btn-fill',
            cancelButtonClass: 'btn btn-info btn-fill',
            buttonsStyling: false,
            allowOutsideClick: false
          })).then((result) => {
            if (result.value) {
              this.$router.push({ name: 'View Person', params: { id: this.$route.params.id}})
            } else if (result.dismiss === swal.DismissReason.cancel) {
              this.$router.push({ name: 'View Event', params: { id: this.newEvent.data._id}})
            }
        })
      }
      if (this.$route.params.type == "organisation") {
        swal({
          title: 'Link',
          text: 'Choose Below',
          input: 'radio',
          inputOptions: ({"actor":"Actor", "target":"Target"}),
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == null){
              return "Please select one"
            }
            this.createLinkEventOrganisation(this.newEvent.data._id, this.$route.params.id, value)
          }
        }).then((result) =>
          swal({
            title: 'Event Created',
            text: 'Would you like to view this Event or the original Organisation?',
            type: 'success',
            showCancelButton: true,
            confirmButtonText: 'View Original Organisation',
            cancelButtonText: 'View New Event',
            confirmButtonClass: 'btn btn-success btn-fill',
            cancelButtonClass: 'btn btn-info btn-fill',
            buttonsStyling: false,
            allowOutsideClick: false
          })).then((result) => {
            if (result.value) {
              this.$router.push({ name: 'View Organisation', params: { id: this.$route.params.id}})
            } else if (result.dismiss === swal.DismissReason.cancel) {
              this.$router.push({ name: 'View Event', params: { id: this.newEvent.data._id}})
            }
        })
      }
      if (this.$route.params.type == undefined) {
        // swal({
        //   title: 'Event Created',
        //   text: 'Would you like to view this Event or add another to the database?',
        //   type: 'success',
        //   showCancelButton: true,
        //   confirmButtonText: 'Add Another',
        //   cancelButtonText: 'View Event',
        //   confirmButtonClass: 'btn btn-success btn-fill',
        //   cancelButtonClass: 'btn btn-info btn-fill',
        //   buttonsStyling: false,
        //   allowOutsideClick: false
        // }).then((result) => {
        // if (result.value) {
        //   //do one thing
        //   this.$router.push({ name: 'Add Event', params: { id: randomNumber}})
        // } else if (
        //   /* Read more about handling dismissals below */
        //   result.dismiss === swal.DismissReason.cancel
        //   ) {
        //   //do something else
          this.$router.push({ name: 'View Event', params: { id: this.newEvent.data._id}})
        //   }
        // })
      }
    }
  }
};
</script>
