<template>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <h5 class="info-text">
        Add coordinates
      </h5>
    </div>

    <div class="col-md-12 justify-content-center">
      <div class="form-horizontal justify-content-center"> 

        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Latitude</label> 
          <div class="col-md-6">
            <base-input
            id="latitudeDecimal"
            name="latitudeDecimal"
              placeholder=""
              v-model="latitudeDecimal"
              v-validate="modelValidations.latitudeDecimal"
              :error="getError('latitudeDecimal')"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Longitude</label> 
          <div class="col-md-6">
            <base-input
              id="longitudeDecimal"
              name="longitudeDecimal"
              placeholder=""
              v-model="longitudeDecimal"
              v-validate="modelValidations.longitudeDecimal"
              :error="getError('longitudeDecimal')"
            />
          </div>
        </div>
        




        <!-- <div class="row justify-content-center"> 
          <div class="col-md-2 justify-content-center">
            <base-radio name="dec" v-model="decimalDMS">DMS String</base-radio>
          </div>
          <div class="col-md-2 justify-content-center">
            <base-radio name="dms" v-model="decimalDMS">DMS</base-radio>
          </div>
        </div>

        <br> -->

        <!-- <div class="row justify-content-center"> 
          <label class="col-md-2 col-form-label">DMS String</label> 
          <div class="col-md-4">
            <base-input
              name="decimalString"
              id="decimalString"
              v-model="decimalString"
              :readonly="isHiddenDecimal"
              v-validate="modelValidations.decimalString"
              :error="getError('decimalString')"
            />
          </div>
        </div> -->

        <!-- <div class="row justify-content-center">
          <p class="text-muted">Must be of the format 38°7'14.6''S 144°50'37.5''E OR fill out individual coordinates below</p>
        </div>

        <br> -->

        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Longitude - Degrees</label> 
          <div class="col-md-4">
            <base-input
              name="longitudeDegrees"
              id="longitudeDegrees"
              placeholder=""
              :readonly="isHiddenDMS"
              v-model="longitudeDegrees"
              v-validate="modelValidations.longitudeDegrees"
              :error="getError('longitudeDegrees')"
            />
          </div>
        
          <label class="col-md-2 col-form-label">Latitude - Degrees</label> 
          <div class="col-md-4">
            <base-input
              name="latitudeDegrees"
              id="latitudeDegrees"
              placeholder=""
              :readonly="isHiddenDMS"
              v-model="latitudeDegrees"
              v-validate="modelValidations.latitudeDegrees"
              :error="getError('latitudeDegrees')"
            />
          </div>
        </div> -->
      
        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Longitude - Minutes</label> 
          <div class="col-md-4">
            <base-input
              name="longitudeMinutes"
              id="longitudeMinutes"
              placeholder=""
              :readonly="isHiddenDMS"
              v-model="longitudeMinutes"
              v-validate="modelValidations.longitudeMinutes"
              :error="getError('longitudeMinutes')"
            />
          </div>
          <label class="col-md-2 col-form-label">Latitude - Minutes</label> 
          <div class="col-md-4">
            <base-input
              name="latitudeMinutes"
              id="latitudeMinutes"
              placeholder=""
              :readonly="isHiddenDMS"
              v-model="latitudeMinutes"
              v-validate="modelValidations.latitudeMinutes"
              :error="getError('latitudeMinutes')"
            />
          </div>
        </div> -->

        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Longitude - Seconds</label> 
          <div class="col-md-4">
            <base-input
              name="longitudeSeconds"
              id="longitudeSeconds"
              placeholder=""
              :readonly="isHiddenDMS"
              v-model="longitudeSeconds"
              v-validate="modelValidations.longitudeSeconds"
              :error="getError('longitudeSeconds')"
            />
          </div>
        
          <label class="col-md-2 col-form-label">Latitude - Seconds</label> 
          <div class="col-md-4">
            <base-input
              name="latitudeSeconds"
              id="latitudeSeconds"
              placeholder=""
              :readonly="isHiddenDMS"
              v-model="latitudeSeconds"
              v-validate="modelValidations.latitudeSeconds"
              :error="getError('latitudeSeconds')"
            />
          </div>
        </div> -->

        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Longitude - Direction</label> 
          <div class="col-md-4">
            <base-input
              name="longitudeDirection"
              id="longitudeDirection"
              placeholder=""
              :readonly="isHiddenDMS"
              v-model="longitudeDirection"
              v-validate="modelValidations.longitudeDirection"
              :error="getError('longitudeDirection')"
            />
          </div>
          <label class="col-md-2 col-form-label">Latitude - Direction</label> 
          <div class="col-md-4">
            <base-input
              name="latitudeDirection"
              id="latitudeDirection"
              placeholder=""
              :readonly="isHiddenDMS"
              v-model="latitudeDirection"
              v-validate="modelValidations.latitudeDirection"
              :error="getError('latitudeDirection')"
            />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { BaseRadio } from 'src/components/index'
export default {
  components: {
    BaseRadio
  },
  data() {
    return {
      decimalString: '',
      latitudeDegrees: '',
      latitudeMinutes: '',
      latitudeSeconds: '',
      latitudeDirection: '',
      longitudeDegrees: '',
      longitudeMinutes: '',
      longitudeSeconds: '',
      longitudeDirection: '',
      latitude: '',
      longitude: '',
      latitudeDecimal: '',
      longitudeDecimal: '',
      isHiddenDecimal: false,
      isHiddenDMS: true,
      decimalDMS: 'dec',
      modelValidations: {
        latitudeDecimal: {
          required:true,
          regex:/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
          //type:"number"
        },
        longitudeDecimal: {
          required:true,
          regex:/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
          //type:"number"
        },
        decimalString: {
          required: false,
          regex: /\d{1,3}\S\d{1,2}\S\d{1,2}\.\d{1,5}\S\S[SNsn]\s\d{1,3}\S\d{1,2}\S\d{1,2}\.\d{1,5}\S\S\w$/
        },
        latitudeDirection: {
          required: false,
          regex: /^[NSns]$/
        },
        longitudeDirection: {
          required: false,
          regex: /^[EWew]$/
        },
        longitudeDegrees: {
          required: false,
          regex: /^\d{1,3}$/
        },
        longitudeMinutes: {
          required: false,
          regex: /^\d{1,2}$/
        },
        latitudeDegrees: {
          required: false,
          regex: /^\d{1,3}$/
        },
        latitudeMinutes: {
          required: false,
          regex: /^\d{1,2}$/
        },
        latitudeSeconds: {
          required: false,
          regex: /^\d{1,5}$/
        },
        longitudeSeconds: {
          required: false,
          regex: /^\d{1,5}$/
        },
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async validate() {
      try {
        if (this.decimalDMS == 'dms') {
          this.latitude = await this.convertDMSToDD(this.latitudeDegrees, this.latitudeMinutes, this.latitudeSeconds, this.latitudeDirection)
          this.longitude = await this.convertDMSToDD(this.longitudeDegrees, this.longitudeMinutes, this.longitudeSeconds, this.longitudeDirection)
        } else {
            var parts = this.decimalString.split(/[^\d\w/.]+/)
            this.latitude = this.convertDMSToDD(parts[0], parts[1], parts[2], parts[3])
            this.longitude = this.convertDMSToDD(parts[4], parts[5], parts[6], parts[7])
        }
      } catch (error) {
        
      } finally {
        this.$emit('child-latitude', this.latitudeDecimal);
        this.$emit('child-longitude', this.longitudeDecimal);
        return this.$validator.validateAll().then(res => {
          this.$emit('on-validated', res, this.model);
          return res;
        })
      }
    },
    convertDMSToDD (degrees, minutes, seconds, direction) {
      var dd = Number(degrees) + Number(minutes/60) + Number(seconds/3600)
      if (direction == "S" || direction == "W") {
        dd = dd * -1
      } //Don't do anything for N or E
      return dd;
    },
    async convertDMS() {
      this.latitude = await this.convertDMSToDD(this.latitudeDegrees, this.latitudeMinutes, this.latitudeSeconds, this.latitudeDirection)
      this.longitude = await this.convertDMSToDD(this.longitudeDegrees, this.longitudeMinutes, this.longitudeSeconds, this.longitudeDirection)
    },
    async parseDMS() {
      var parts = this.decimalString.split(/[^\d\w/.]+/)
      this.latitude = this.convertDMSToDD(parts[0], parts[1], parts[2], parts[3])
      this.longitude = this.convertDMSToDD(parts[4], parts[5], parts[6], parts[7])
    },
  },
  watch: {
    decimalDMS: function() {
      // Emit this information to the parent component
      if (this.decimalDMS == "dms") {
        // hide all the decimal things
        this.isHiddenDecimal = true
        this.isHiddenDMS = false
      }
      else if (this.decimalDMS == "dec") {
        //this
        this.isHiddenDecimal = false
        this.isHiddenDMS = true
      }
    }
  }
};
</script>
<style lang="scss"></style>
