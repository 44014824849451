import Api from '@/services/Api'

const options = {
  headers: {'Authorization': window.GLOBALIDTOKEN}
}

export default {
  deleteSoul (params) {
    return Api().delete('delete/soul/' + params.id + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  deleteOrganisation (params) {
    return Api().delete('delete/organisation/' + params.id + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  deleteEvent (params) {
    return Api().delete('delete/event/' + params.id + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  deleteTribe (params) {
    return Api().delete('delete/tribe/' + params.id + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  deletePOI (params) {
    return Api().delete('delete/poi/' + params.id + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  deleteSource (params) {
    return Api().delete('delete/source/' + params.id + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  removeLinkSoulOrg (params) {
    return Api().delete('delete/link/soulorg/' + params.soul + '/' + params.org + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  removeLinkSoulSoul (params) {
    return Api().delete('delete/link/soulsoul/' + params.soul1 + '/' + params.soul2 + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  removeLinkOrgOrg (params) {
    return Api().delete('delete/link/orgorg/' + params.org1 + '/' + params.org2 + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  removeLinkParentSub (params) {
    return Api().delete('delete/link/parentsub/' + params.parent + '/' + params.sub + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  removeLinkEventSoulTarget (params) {
    return Api().delete('delete/link/eventsoultarget/' + params.event + '/' + params.soul + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  removeLinkEventSoulActor (params) {
    return Api().delete('delete/link/eventsoulactor/' + params.event + '/' + params.soul + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  removeLinkEventSoul (params) {
    return Api().delete('delete/link/eventsoul/' + params.event + '/' + params.soul + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  removeLinkEventOrg (params) {
    return Api().delete('delete/link/eventorg/' + params.event + '/' + params.org + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  removeLinkEventOrgTarget (params) {
    return Api().delete('delete/link/eventorgtarget/' + params.event + '/' + params.org + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  removeLinkEventOrgActor (params) {
    return Api().delete('delete/link/eventorgactor/' + params.event + '/' + params.org + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },


}