<template>
  <div class="row">
    <div class="col-lg-8 col-md-8" >
      <stats-card
        :title='reportTitle.title'
        :sub-title='reportTitle.subTitle'
        :type="reportTitle.type"
        :icon="reportTitle.icon">
      </stats-card>
    </div>
    <div class="col-lg-4 col-md-4">

    <stats-card
        :title='reportDate.title'
        :sub-title='reportDate.subTitle'
        :type="reportDate.type"
        :icon="reportDate.icon">
      </stats-card>
    


    </div>
      <div class="col-md-12">
        <card >
           <div style="white-space: pre-wrap;"><p>{{reportText}}</p></div>
        </card>
      </div>

      

    </div>

</template>
<script>
import StatsCard from 'src/components/Cards/StatsCard';
import ReportsService from '@/services/ReportsService'
import { Loading } from 'element-ui'

export default {
  components: {
     StatsCard,
  },
  data() {
    return {
      reportTitle: 
        {
          title: '',
          subTitle: 'Report Title',
          type: 'primary',
          icon: 'tim-icons icon-paper',
        },
      reportDate:
      {
          title: '',
          subTitle: 'Date Entered',
          type: 'warning',
          icon: 'tim-icons icon-time-alarm',
      },
      reportText: '',
    };
  },
  mounted() {
    this.getReport()
  },
  methods: {
    async getReport () {
      let loading = Loading.service()
      let report = await ReportsService.getReport({
        id: this.$route.params.id
      })
      this.reportText = report.data.text
      this.reportTitle.title = report.data.name
      this.reportDate.title = new Date(parseInt(report.data._id.substring(0, 8), 16) * 1000).toDateString()
      loading.close()
    },
  }
};
</script>
<style lang="scss"></style>
