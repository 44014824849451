<template>
  <div class="row">
    <div class="col-lg-4 col-md-4" >
      <stats-card
        :title='source.title'
        :sub-title='source.subTitle'
        :type="source.type"
        :icon="source.icon">
        <div slot="footer" class="row">
          <div class="col-md-10" />
          <div class="col-md-2 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
            <div v-if="$can('update', 'mandatory')">
              <a class="dropdown-item" @click="editSourceName">Edit Source Name</a>
            </div>
              <div v-if="$can('delete', 'all')">
              <a class="dropdown-item" @click="deleteRecord">Delete Source</a>
              </div>
            </base-dropdown>
          </div>
        </div>
      </stats-card>
    </div>

    <div class="col-lg-4 col-md-4">
      <updated-card
        :title='sourceDate.title'
        :sub-title='sourceDate.subTitle'
        :second-sub-title='sourceDate.secondSubTitle'
        :second-title='sourceDate.secondTitle'
        :type="sourceDate.type"
        :icon="sourceDate.icon">
      </updated-card>
    </div>

    <div class="col-md-8">
      <card >
        <div class="row">
          <div class="col-md-11">
            <h5>Details</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil"     >
              <h6 class="dropdown-header">Details Menu</h6>
              <a class="dropdown-item" href="#" @click="saveDetails">Save Details</a>
              <div v-if="$can('update', 'mandatory')">
              <a class="dropdown-item" href="#" @click="editCountry">Edit Country</a>
              </div>
            </base-dropdown>
          </div>
        </div>
  
        <div class="form-horizontal">
          <div class="row">  
            <label class="col-md-4 col-form-label">Country</label>
            <div class="col-md-6">
              <base-input disabled v-model="sourceCountry"/>
            </div>
          </div>

          <br>
 
          <div class="row">
            <label class="col-md-4 col-form-label">Type</label>
            <div class="col-md-6">
              <base-input>
                <el-select
                  name="type"
                  id="type"
                  class="select-info"
                  size="medium"
                  placeholder=""
                  v-model="selects.type"
                >
                  <el-option
                  v-for="option in types"
                  class="select-info"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <div class="row">  
            <label class="col-md-4 col-form-label">Affiliation</label>
            <div class="col-md-6">
              <base-input v-model="affiliation"/>
            </div>
          </div>

          <div class="row">   
            <label class="col-md-4 col-form-label">YouTube</label> 
            <div class="col-md-6">
              <base-input v-model="youtube"/>
            </div>
          </div>

          <div class="row">   
            <label class="col-md-4 col-form-label">Instagram</label> 
            <div class="col-md-6">
              <base-input v-model="instagram"/>
            </div>
          </div>

          <div class="row">   
            <label class="col-md-4 col-form-label">Twitter</label> 
            <div class="col-md-6">
              <base-input v-model="twitter"/>
            </div>
          </div>

          <div class="row">   
            <label class="col-md-4 col-form-label">Website</label> 
            <div class="col-md-6">
              <base-input v-model="website"/>
            </div>
          </div>

          <div class="row">   
            <label class="col-md-4 col-form-label">Facebook</label> 
            <div class="col-md-6">
              <base-input v-model="facebook"/>
            </div>
          </div>

        </div>
      </card>
    </div>
    <modal :show.sync="modalNote" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Note</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <textarea class="form-control" rows=4 v-model="notes"></textarea>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalNote = false">Cancel</base-button>
            <base-button type="success" @click.native="updateNotes()">Save</base-button>
          </div>
        </modal>
    <div class="col-md-8">
      <card >
        <div class="row">
          <div class="col-md-11">
            <h5>Notes</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
              <h6 class="dropdown-header">Details Menu</h6>
              <a class="dropdown-item" href="#" @click="modalNote = true">Edit Note</a>
            </base-dropdown>
          </div>
        </div>
  
        <div class="form-horizontal"> 
          <div class="row">  
            <label class="col-md-4 col-form-label">Notes</label>
            <div class="col-md-8">
              <base-input
              >
              <textarea autosize rows=5 class="form-control" aria-label="With textarea" readonly v-model="notes"></textarea>
              </base-input>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import StatsCard from 'src/components/Cards/StatsCard'
import UpdatedCard from 'src/components/Cards/updatedCard'
import swal from 'sweetalert2'
import { Select, Option } from 'element-ui'
import SourcesService from '@/services/SourcesService'
import SearchService from '@/services/SearchService'
import DeleteService from '@/services/DeleteService'
import { Modal, BaseAlert } from 'src/components'
import { Loading } from 'element-ui'

export default {
  components: {
    StatsCard,
    UpdatedCard,
    [Option.name]: Option,
    [Select.name]: Select,
    Modal,
    BaseAlert,
  },
  data() {
    return {
      selects: {
        type:'',
      },
      modalNote: false,
      sourceCountry: '',
      countries: [],
      source: {
        title: '',
        subTitle: 'Source Name',
        type: 'primary',
        icon: 'tim-icons icon-paper',
      },
      sourceDate:{
        title: '',
        subTitle: 'Date Entered',
        secondTitle: '',
        secondSubTitle: 'Last Updated',
        type: 'warning',
        icon: 'tim-icons icon-time-alarm',
      },
      notes: '',
      facebook: '',
      twitter: '',
      website: '',
      instagram: '',
      youtube: '',
      affiliation: '',
      types: [
        { value: 'News Outlet', label: 'News Outlet' },
        { value: 'Pro-Government Social Media', label: 'Pro-Government Social Media' },
        { value: 'Anti-Government Social Media', label: 'Anti-Government Social Media' },
        { value: 'Opposition Party', label: 'Opposition Party' },
        { value: 'Government', label: 'Government' },
        { value: 'Military', label: 'Military' },
        { value: 'Extremist', label: 'Extremist' },
        { value: 'Social Activist/NGO', label: 'Social Activist/NGO' },
        { value: 'Religous', label: 'Religous' },
        { value: 'Prominent Figure', label: 'Prominent Figure' },
      ],
    }
  },
  mounted() {
    this.getSource()
    this.getNations()
  },
  // watch: {
  //   $route(to, from) {
  //     // react to route changes...
  //     let loading = Loading.service()
  //     this.getSource()
  //   this.getNations()
  //     loading.close()
  //   }
  // },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async getNations() {
      const activeNations = window.GLOBALNATIONS
      activeNations.forEach(country => {
        const id = country.search
        this.countries[id] = country.search
      });
    },
    async getSource() {
      let loading = Loading.service()
      const source = await SourcesService.getSource({
        id: this.$route.params.id
      })
      this.source.title = source.data.name
      if (source.data.lastupdated != null) {
        this.sourceDate.secondTitle = new Date(source.data.lastupdated.substring(0, 10)).toDateString()
      }
      this.sourceDate.title = new Date(parseInt(source.data._id.substring(0, 8), 16) * 1000).toDateString()
      this.website = source.data.website
      this.affiliation = source.data.affiliation
      this.twitter = source.data.twitter
      this.instagram = source.data.instagram
      this.selects.type = source.data.type
      this.facebook = source.data.facebook
      this.youtube = source.data.youtube
      this.sourceCountry = source.data.country
      loading.close()
    },
    editSourceName() {
      swal({
          title: 'Edit Source',
          text: 'Change ' + this.source.subTitle,
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValue: this.source.title,
          inputValidator: (value) => {
            if (!value) {
              return 'You need to write something!'
            }
            if (value != this.source.title) {
              this.updateSourceName(value)
            }
          }
        })
    },
    async updateSourceName(newName) {
      let loading = Loading.service()
      let newSource = await SourcesService.updateSource({
        id: this.$route.params.id,
        name: newName,
        lastupdated: new Date()
      }).catch(loading.close(), this.notifyFailure())
      this.getSource()
      loading.close()
      this.notifySuccess()
    },
    notifySuccess() {
      this.$notify({
        message:
          'Record Updated',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "info"
      });
    },
    notifyFailure() {
      this.$notify({
        message:
          'Update Failed',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "danger"
      });
    },
    async updateSourceCountry(newCountry) {
      let loading = Loading.service()
      let newSource = await SourcesService.updateSource({
        id: this.$route.params.id,
        country: newCountry,
        lastupdated: new Date()
      }).catch(loading.close(), this.notifyFailure())
      this.getSource()
      this.notifySuccess()
    },
    async updateSource() {
      let loading = Loading.service()
      let newSource = await SourcesService.updateSource({
        id: this.$route.params.id,
        facebook: this.facebook,
        youtube: this.youtube,
        instagram: this.instagram,
        website: this.website,
        twitter: this.twitter,
        type: this.selects.type,
        affiliation: this.affiliation,
        lastupdated: new Date(),
      }).catch(loading.close(), this.notifyFailure())
      this.getSource()
      loading.close()
      this.notifySuccess()
    },
    async updateNotes() {
      let loading = Loading.service()
      let newSource = await SourcesService.updateSource({
        id: this.$route.params.id,
        notes: this.notes,
        lastupdated: new Date()
      })
      this.getSource()
      this.modalNote = false
      loading.close()
      if (newSource.status != 200) {
        this.notifyFailure()
      } else {
        this.notifySuccess()
      }
    },
    saveDetails() {
      this.updateSource()
    },
    editCountry() {
        swal({
          title: 'Change Country',
          text: 'Select a country below',
          input: 'select',
          inputOptions: this.countries,
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == this.sourceCountry) {
              return 'You have chosen the same value. Click Cancel if you do not wish to modify'
            }
            this.updateSourceCountry(value)
          }
        })
    },
    deleteRecord() {
      swal({
          title: 'Delete Source',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove this record from the database'
            }
            if (value == 'DELETE'){
              this.deleteSource()
            }
          }
        })
    },
    async deleteSource() {
      await DeleteService.deleteSource({
        id: this.$route.params.id,
      })
      this.$router.push({ name: 'Search'})
    },
  }
};
</script>
<style lang="scss">
</style>
