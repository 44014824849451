<template>
  <div class="row justify-content-center">
    <h5 class="info-text">
      What is the name of the report?
    </h5>
      <div class="col-sm-10">
        <base-input
          name="title"
          id="title"
          placeholder="Title"
          v-model="title"
          v-validate="modelValidations.title"
          :error="getError('title')"
        >
        </base-input>
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: '',
      modelValidations: {
        title: {
          required: true,
          min: 1
        },
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    }
  },
  watch: {
    title: function() {
      // Emit this information to the parent component
      this.$emit("child-title", this.title);
    }
  }
};
</script>
<style></style>
