// This Service is for exporting of data

import Api from '@/services/Api'

const options = {
  headers: {'Authorization': window.GLOBALIDTOKEN}
}

export default {
  exportEventsDate (params) {
    return Api().post('export/events/date/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  exportEventsLastUpdated (params) {
    return Api().post('export/events/lastupdated/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getSourcesByCountryAndDate (params) {
    return Api().get('export/sources/date/' + params.country + '/' + params.startDate + '/' + params.endDate + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getSourcesByCountry (params) {
    return Api().get('export/sources/country/' + params.country + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getReports () {
    return Api().get('export/reports/all' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getReportsByDate (params) {
    return Api().get('export/reports/date/' + params.startDate + '/' + params.endDate + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getOrganisationsByCountryLastUpdated (params) {
    return Api().get('export/organisations/lastupdated/' + params.country + '/' + params.startDate + '/' + params.endDate + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getOrganisationsByCountry (params) {
    return Api().get('export/organisations/country/' + params.country + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getSoulsByCountryLastUpdated (params) {
    return Api().get('export/souls/lastupdated/' + params.country + '/' + params.startDate + '/' + params.endDate + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getSoulsByCountry (params) {
    return Api().get('export/souls/country/' + params.country + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  
}
