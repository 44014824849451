<template><div>
  <div class="row">
    <div class="col-lg-4 col-md-3" >
      <stats-card
        :title='soul.title'
        :sub-title='soul.subTitle'
        :type="soul.type"
        :icon="soul.icon">
        <div slot="footer" class="row">
          <div class="col-md-10">
            <a class="nav-link" @click="changeName()">
              <i class="tim-icons icon-refresh-02" ></i> {{titleSwap}}
            </a>
          </div>
          <div class="col-md-2 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
            <div v-if="$can('update', 'mandatory')">
              <a class="dropdown-item" @click="modalEnglishName = true">Edit English Name</a>
            </div>
              <a class="dropdown-item" @click="modalArabicName = true">Edit Arabic Name</a>
              <div v-if="$can('delete', 'all')">
              <a class="dropdown-item" @click="deleteRecord">Delete Person</a>
              </div>
            
            </base-dropdown>
          </div>
        </div>
      </stats-card>
    </div>

    <modal :show.sync="modalEnglishName" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit English Name</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="englishName"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalEnglishName = false">Cancel</base-button>
            <base-button type="success" @click.native="updateEnglishName(englishName)">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalArabicName" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Arabic Name</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="arabicName"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalArabicName = false">Cancel</base-button>
            <base-button type="success" @click.native="updateArabicName(arabicName)">Save</base-button>
          </div>
        </modal>

    <div class="col-lg-3 col-md-3">
      <updated-card
        :title='soulDateCard.title'
        :sub-title='soulDateCard.subTitle'
        :second-sub-title='soulDateCard.secondSubTitle'
        :second-title='soulDateCard.secondTitle'
        :type="soulDateCard.type"
        :icon="soulDateCard.icon">
      </updated-card>
    </div>
    
    <div class="col-md-2">
      <el-carousel :interval="10000" height="150px">
        <el-carousel-item v-for="image in images" :key="image.id">
          <img :src="image.image" style="max-height: 150px; width: auto;">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="col-md-1">
    </div>

    <div class="col-md-2">
      <image-upload @child-change="imageToUpload" @child-save="saveImage" select-text="Add Picture" />
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <card>
        <div class="row">
          <div class="col-md-11">
            <h5>Details</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
              <h6 class="dropdown-header">Details Menu</h6>
              
              <a class="dropdown-item" @click="modalPosition = true">Add Position</a>
              <a class="dropdown-item" @click="modalRank = true">Add Rank</a>
              <a class="dropdown-item" @click="modalAKA = true">Add AKA</a>
              <div v-if="$can('update', 'mandatory')">
              <a class="dropdown-item" @click="modalBasedIn = true">Add Based In</a>
              </div>
              <a class="dropdown-item" @click="modalSocialMedia = true">Add Social Media</a>
              <a class="dropdown-item" @click="modalTribe = true">Add Tribe</a>
              <a class="dropdown-item" @click="modalAffiliation = true">Edit Affiliation</a>
              <a class="dropdown-item" @click="modalBornIn = true">Edit Born In</a>
              <a class="dropdown-item" @click="modalDateOfBirth = true">Edit Date of Birth</a>
              <!-- <a class="dropdown-item" @click="saveDetails">Save Details</a> -->
            </base-dropdown>
          </div>
        </div>

        <modal :show.sync="modalBasedIn" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Add Based In</h4>
          <p>
            Enter location below and click search
          </p>
          <br>
          <div class="row">
            <div class="col-md-9">
              <base-input v-model="searchBarGeneralLocation"/>
            </div> 
            <div class="col-md-3">
              <base-button size="sm" type="info" @click.native="searchGeneralLocations()">Search</base-button>
            </div>
            <div class="col-md-12">
              <el-table :data="searchGeneralLocationData" height="350">
                <el-table-column
                  key="_id"
                  min-width=150
                  prop="search"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="addGeneralLocation(props.row.search)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-link-72"></i>
                  </base-button>
                </div>
              </el-table-column>
              </el-table>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalBasedIn = false">Cancel</base-button>
          </div>
        </modal>

        <modal :show.sync="modalPosition" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Add Position</h4>
          <br>
          <div class="row">
            <label class="col-md-3 col-form-label">English</label> 
            <div class="col-md-8">
              <base-input v-model="newPositionEnglish"/>
            </div>
          </div>
          <div class="row">
            <label class="col-md-3 col-form-label">Arabic</label> 
            <div class="col-md-8">
              <base-input v-model="newPositionArabic"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalPosition = false">Cancel</base-button>
            <base-button type="success" @click.native="addPosition()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalPositionReplace" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Position</h4>
          <br>
          <div class="row">
            <label class="col-md-3 col-form-label">English</label> 
            <div class="col-md-8">
              <base-input v-model="replacePositionEnglish"/>
            </div>
          </div>
          <div class="row">
            <label class="col-md-3 col-form-label">Arabic</label> 
            <div class="col-md-8">
              <base-input v-model="replacePositionArabic"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalPositionReplace = false">Cancel</base-button>
            <base-button type="success" @click.native="replacePosition(replacePositionID, replacePositionEnglish, replacePositionArabic)">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalAKA" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Add AKA</h4>
          <br>
          <div class="row">
            <label class="col-md-3 col-form-label">English</label> 
            <div class="col-md-8">
              <base-input v-model="newAKAEnglish"/>
            </div>
          </div>
          <div class="row">
            <label class="col-md-3 col-form-label">Arabic</label> 
            <div class="col-md-8">
              <base-input v-model="newAKAArabic"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalAKA = false">Cancel</base-button>
            <base-button type="success" @click.native="addAKA()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalAKAReplace" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit AKA</h4>
          <br>
          <div class="row">
            <label class="col-md-3 col-form-label">English</label> 
            <div class="col-md-8">
              <base-input v-model="replaceAKAEnglish"/>
            </div>
          </div>
          <div class="row">
            <label class="col-md-3 col-form-label">Arabic</label> 
            <div class="col-md-8">
              <base-input v-model="replaceAKAArabic"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalAKAReplace = false">Cancel</base-button>
            <base-button type="success" @click.native="replaceAKA(replaceAKAID, replaceAKAEnglish, replaceAKAArabic)">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalSocialMediaReplace" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Social Media</h4>
          <br>
          <div class="row">
            
            <div class="col-md-12">
              <base-input v-model="replaceSocialMediaValue"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalSocialMediaReplace = false">Cancel</base-button>
            <base-button type="success" @click.native="replaceSocialMedia(replaceSocialMediaID, replaceSocialMediaValue)">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalRank" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Add Rank</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input>
                <el-select
                  name="type"
                  id="type"
                  class="select-info"
                  size="medium"
                  placeholder="Rank"
                  filterable
                  v-model="selects.rank"
                >
                  <el-option
                    v-for="option in ranks"
                    class="select-info"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <br>

          <div class="modal-footer">
            <base-button type="info" @click.native="modalRank = false">Cancel</base-button>
            <base-button type="success" @click.native="addRank()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalTribe" headerClasses="justify-content-center" class="modal-mini modal-black">
          <h4 slot="header" class="title title-up">Add Tribe</h4>
          <br>
          <div class="row">
            <div class="col-md-10">
              <base-input v-model="searchBarTribe" />
            </div> 
            <div class="col-md-2">
              <base-button size="sm" type="info" @click.native="getTribes()">Search</base-button>
            </div>
            <div class="col-md-12">
              <el-table :data="tribes">
                <el-table-column key="country" min-width=100 prop="country" />
                <el-table-column key="ethnicity" min-width=100 prop="ethnicity" />
                <el-table-column key="tribe_en" min-width=100 prop="tribe_en" />
                <el-table-column key="confederation_en" min-width=100 prop="confederation_en" />
                <el-table-column key="firstsubtribe_en" min-width=100 prop="firstsubtribe_en" />
                <el-table-column key="secondsubtribe_en" min-width=100 prop="secondsubtribe_en" />
                <el-table-column key="thirdsubtribe_en" min-width=100 prop="thirdsubtribe_en" />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="addTribe(props.row)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-link-72"></i>
                  </base-button>
                </div>
              </el-table-column>
              </el-table>
            </div>
          </div>

          <br>
          <base-button type="info" @click.native="modalTribe = false">Cancel</base-button>

        </modal>


        <modal :show.sync="modalSocialMedia" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Add Social Media</h4>
          <br>
          <div class="row">
            
            <div class="col-md-12">
              <base-input v-model="newSocialMedia"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalSocialMedia = false">Cancel</base-button>
            <base-button type="success" @click.native="addSocialMedia()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalAffiliation" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Affiliation</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="soul.affiliation"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalAffiliation = false">Cancel</base-button>
            <base-button type="success" @click.native="updateSoulDetails()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalBornIn" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Born In</h4>
          <p>
            Enter location below and click search
          </p>
          <br>
          <div class="row">
            <div class="col-md-9">
              <base-input v-model="searchBarGeneralLocation"/>
            </div> 
            <div class="col-md-3">
              <base-button size="sm" type="info" @click.native="searchGeneralLocations()">Search</base-button>
            </div>
            <div class="col-md-12">
              <el-table :data="searchGeneralLocationData">
                <el-table-column
                  key="_id"
                  min-width=150
                  prop="search"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="editBornIn(props.row.search)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-link-72"></i>
                  </base-button>
                </div>
              </el-table-column>
              </el-table>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalBornIn = false">Cancel</base-button>
          </div>
        </modal>

        <modal :show.sync="modalDateOfBirth" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Date of Birth</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="soul.dateOfBirth"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalDateOfBirth = false">Cancel</base-button>
            <base-button type="success" @click.native="updateSoulDetails()">Save</base-button>
          </div>
        </modal>
  

          


        <div class="form-horizontal justify-content-center"> 
          <div class="col-md-11">
          <collapse>
            <collapse-item :title="titlePositions">
              <div class="col-md-12">
                <el-table :data="positionData" :show-header="false">
                  <div slot="empty">No Positions's found</div>
                  <el-table-column
                  v-for="column in positionColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="editPosition(props.row)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </div>
              </el-table-column>
                </el-table>
              </div>
            </collapse-item>

            <collapse-item :title="titleRanks">
              <div class="col-md-12">
                <el-table :data="rankData" :show-header="false">
                  <div slot="empty">No Rank's found</div>
                  <el-table-column
                  v-for="column in rankColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                </el-table>
              </div>
            </collapse-item>

            <collapse-item :title="titleAKA">
              <div class="col-md-12">
                <el-table :data="akaData" :show-header="false">
                  <div slot="empty">No AKA's found</div>
                  <el-table-column
                  v-for="column in akaColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="editAKA(props.row)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </div>
              </el-table-column>
                </el-table>
              </div>
            </collapse-item>

            <collapse-item :title="titleBasedIn" :active="true">
              <div class="col-md-12">
                <el-table :data="basedInData" :show-header="false">
                  <div slot="empty">No Locations found</div>
                  <el-table-column
                    key="_id"
                    min-width=150
                    prop="basedIn"
                  />
                </el-table>
              </div>
            </collapse-item>

            <collapse-item :title="titleSocialMedia">
              <div class="col-md-12">
                <el-table :data="socialMediaData" :show-header="false">
                  <div slot="empty">No Social Media found</div>
                  <el-table-column
                  v-for="column in socialMediaColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                  <el-table-column :min-width="45" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="editSocialMedia(props.row)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </div>
              </el-table-column>
                </el-table>
              </div>
            </collapse-item>
            <collapse-item :title="titleTribe">
              <div class="col-md-12">
                <el-table :data="tribeData" :show-header="false">
                  <div slot="empty">No Tribes found</div>
                  <el-table-column
                    key="_id"
                    min-width=150
                    prop="tribe"
                  />
                </el-table>
              </div>
            </collapse-item>
          </collapse>
          </div>



          <br>

          <div class="row">
            <label class="col-md-3 col-form-label">Affiliation</label> 
            <div class="col-md-8">
              <base-input readonly v-model="soul.affiliation"/>
            </div>
          </div>

          <div class="row">
            <label class="col-md-3 col-form-label">Born In</label> 
            <div class="col-md-8">
              <base-input readonly v-model="soul.bornIn"/>
            </div>
          </div>
          <div class="row">
            <label class="col-md-3 col-form-label">Date of Birth</label> 
            <div class="col-md-8">
              <base-input readonly v-model="soul.dateOfBirth"/>
            </div>
          </div>

          
            
        </div>
      </card>

      <card>
        <div class="row">
          <div class="col-md-11">
            <h5>Notes</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-button
                    @click.native="modalNote = true"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-add"></i>
                  </base-button>
          </div>
        </div>

        <modal :show.sync="modalNote" headerClasses="justify-content-center" class="modal-mini modal-black">
          <h4 slot="header" class="title title-up">Add Note</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <textarea class="form-control" rows=8 v-model="newNote"></textarea>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalNote = false">Cancel</base-button>
            <base-button type="success" @click.native="createNote()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalNoteReplace" headerClasses="justify-content-center" class="modal-mini modal-black">
          <h4 slot="header" class="title title-up">Edit Note</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <textarea class="form-control" rows=8 v-model="replaceNoteText"></textarea>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalNoteReplace = false">Cancel</base-button>
            <base-button type="success" @click.native="replaceNote(replaceNoteID,replaceNoteText)">Save</base-button>
          </div>
        </modal>
  
        <div class="form-horizontal"> 
          <div class="row">  
            <div class="col-md-11">
              <el-table :data="notes" :show-header="false">
                <div slot="empty">No notes found</div>
                <el-table-column
                  v-for="column in notesColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="editNote(props.$index, props.row)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="col-form-label ml-auto">
                {{ notesFrom + 1 }}-{{ notesTo }} of {{ notesTotal }}
            </div>
          
                <base-pagination
                  class="pagination-no-border"
                  v-model="notesPagination.currentPage"
                  :per-page="notesPagination.perPage"
                  :total="notesTotal"
                >
                </base-pagination>
          </div>
        </div>      
      </div>
    </card>
    </div>

    <div class="col-md-6">
      <card>
        <div class="row">
          <div class="col-md-11">
            <h5>Links</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-button
                    @click.native="addLink()"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-link-72"></i>
                  </base-button>
          </div>
        </div>

        <div class="form-horizontal"> 
          <div class="row">  
            <div class="col-md-6">
              <el-table :data="linkOrganisations">
                <div slot="empty">None</div>
                <el-table-column
                  v-for="column in linkOrganisationsColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewOrganisation(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkSoulOrg(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              
              </el-table>
              <div id="linkOrganisations" class="row">
                <div class="col-form-label ml-auto">
                  {{ linkOrganisationsFrom + 1 }}-{{ linkOrganisationsTo }} of {{ linkOrganisationsTotal }}
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="linkOrganisationsPagination.currentPage"
                  :per-page="linkOrganisationsPagination.perPage"
                  :total="linkOrganisationsTotal"
                />
              </div>
            </div>

            <div class="col-md-6">
              <el-table :data="linkSouls">
                <div slot="empty">None</div>
                <el-table-column
                  v-for="column in linkSoulsColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewSoul(props.row.soulID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkSoulSoul(props.row.soulID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              </el-table>
              <div id="linkSouls" class="row">
                <div class="col-form-label ml-auto">
                  {{ linkSoulsFrom + 1 }}-{{ linkSoulsTo }} of {{ linkSoulsTotal }}
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="linkSoulsPagination.currentPage"
                  :per-page="linkSoulsPagination.perPage"
                  :total="linkSoulsTotal"
                />
              </div>
            </div>
          </div>  
        </div>

        <div class="form-horizontal"> 
          <div class="row">  
            <div class="col-md-6">
              <el-table :data="linkEvents">
                <div slot="empty">None</div>
                <el-table-column
                  v-for="column in linkEventsColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewEvent(props.row.eventID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkEventSoul(props.row.eventID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              </el-table>
              <div id="linkEvents" class="row">
                <div class="col-form-label ml-auto">
                  {{ linkEventsFrom + 1 }}-{{ linkEventsTo }} of {{ linkEventsTotal }}
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="linkEventsPagination.currentPage"
                  :per-page="linkEventsPagination.perPage"
                  :total="linkEventsTotal"
                />
              </div>
            </div>
          </div>  
          
        </div>
      </card>
    </div>
  </div>
</div>

</template>

<script>
import StatsCard from 'src/components/Cards/StatsCard';
import UpdatedCard from 'src/components/Cards/updatedCard';
import swal from 'sweetalert2';
import { DatePicker, TimeSelect, Table, TableColumn, Select, Option, Carousel, CarouselItem } from 'element-ui';
import { BasePagination, TabPane, Tabs, Modal, BaseAlert, Collapse, CollapseItem } from 'src/components';
import { ImageUpload } from 'src/components/index';
import SoulsService from '@/services/SoulsService'
import SearchService from '@/services/SearchService'
import POIsService from '@/services/POIsService'
import { Loading } from 'element-ui'
import TribesService from '@/services/TribesService'
import DeleteService from '@/services/DeleteService'

export default {
  components: {
    TabPane,
    Tabs,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    StatsCard,
    UpdatedCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    BasePagination,
    ImageUpload,
    Modal,
    BaseAlert,
    Collapse,
    CollapseItem
  },
  computed: {
    country() {
      let recentBasedIn = this.basedInData[this.basedInData.length - 1].basedIn
      let country = recentBasedIn.split(';')
      let countryString = country[0]
      return countryString
    },
    notes() {
      return this.notesData.slice(this.notesFrom, this.notesTo);
    },
    notesTo() {
      let highBound = this.notesFrom + this.notesPagination.perPage;
      if (this.notesTotal < highBound) {
        highBound = this.notesTotal;
      }
      return highBound;
    },
    notesFrom() {
      return this.notesPagination.perPage * (this.notesPagination.currentPage - 1);
    },
    notesTotal() {
      return this.notesData.length;
    },
    linkOrganisations() {
      return this.linkOrganisationsData.slice(this.linkOrganisationsFrom, this.linkOrganisationsTo);
    },
    linkOrganisationsTo() {
      let highBound = this.linkOrganisationsFrom + this.linkOrganisationsPagination.perPage;
      if (this.linkOrganisationsTotal < highBound) {
        highBound = this.linkOrganisationsTotal;
      }
      return highBound;
    },
    linkOrganisationsFrom() {
      return this.linkOrganisationsPagination.perPage * (this.linkOrganisationsPagination.currentPage - 1);
    },
    linkOrganisationsTotal() {
      return this.linkOrganisationsData.length;
    },
    linkEvents() {
      return this.linkEventsData.slice(this.linkEventsFrom, this.linkEventsTo);
    },
    linkEventsTo() {
      let highBound = this.linkEventsFrom + this.linkEventsPagination.perPage;
      if (this.linkEventsTotal < highBound) {
        highBound = this.linkEventsTotal;
      }
      return highBound;
    },
    linkEventsFrom() {
      return this.linkEventsPagination.perPage * (this.linkEventsPagination.currentPage - 1);
    },
    linkEventsTotal() {
      return this.linkEventsData.length;
    },
    linkSouls() {
      return this.linkSoulsData.slice(this.linkSoulsFrom, this.linkSoulsTo);
    },
    linkSoulsTo() {
      let highBound = this.linkSoulsFrom + this.linkSoulsPagination.perPage;
      if (this.linkSoulsTotal < highBound) {
        highBound = this.linkSoulsTotal;
      }
      return highBound;
    },
    linkSoulsFrom() {
      return this.linkSoulsPagination.perPage * (this.linkSoulsPagination.currentPage - 1);
    },
    linkSoulsTotal() {
      return this.linkSoulsData.length;
    },
  },
  // mounted() {
  //   this.getSoul()
  // },
  activated() {
    this.getSoul()
  },
  data() {
    return {
      searchBarTribe: '',
      modalBasedIn: false,
      modalPosition: false,
      modalPositionReplace: false,
      modalRank: false,
      modalSocialMedia: false,
      modalSocialMediaReplace: false,
      modalAKA: false,
      modalAKAReplace: false,
      modalTribe: false,
      modalAffiliation: false,
      modalBornIn: false,
      modalDateOfBirth: false,
      modalNote: false,
      modalNoteReplace: false,
      modalEnglishName: false,
      modalArabicName: false,
      newPositionArabic: '',
      newPositionEnglish: '',
      replacePositionEnglish: '',
      replacePositionArabic: '',
      replacePositionID: '',
      replaceAKAEnglish: '',
      replaceAKAArabic: '',
      replaceAKAID: '',
      replaceSocialMediaValue: '',
      replaceSocialMediaID: '',
      newAKAEnglish: '',
      newAKAArabic: '',
      newSocialMedia: '',
      titleBasedIn: 'Based In',
      titlePositions: 'Positions',
      titleRanks: 'Ranks',
      titleAKA: 'Also Known As',
      titleSocialMedia: 'Social Media',
      titleTribe: 'Tribe',
      item:'', //delete this eventually
      searchBarGeneralLocation: '',
      searchGeneralLocationData: null,
      titleSwap: 'Arabic Name',
      newImage: '',
      englishName: '',
      arabicName: '',
      tribes:[],
      basedInData: [],
      tribeData: [],
      socialMediaData: [],
      positionData: [],
      rankData: [],
      akaData: [],
      images: [],
      newNote: '',
      replaceNoteText: '',
      replaceNoteID: '',
      selects: {
        rank: '',
        generalLocation: null,
        tribe: '',
      },
      soul: {
        title: '',
        subTitle: 'English Name',
        type: 'primary',
        icon: 'tim-icons icon-single-02',
        soulDate: '',
        type: '',
        affiliation: '',
        ideology: '',
        bornIn: '',
        dateOfBirth: '',
      },
      soulCountry: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      soulDateCard:{
        title: '',
        subTitle: 'Date Entered',
        secondTitle: '',
        secondSubTitle: 'Last Updated',          
        type: 'warning',
        icon: 'tim-icons icon-time-alarm',
      },
      ranks: [
        { value: 'Admiral', label: '' },
        { value: 'Air Chief Marshal', label: '' },
        { value: 'Air Commodore', label: '' },
        { value: 'Air Marshal', label: '' },
        { value: 'Air Vice Marshal', label: '' },    
        { value: 'Brigadier', label: '' },
        { value: 'Captain', label: '' },
        { value: 'Colonel', label: '' },
        { value: 'Commodore', label: '' },
        { value: 'Commander', label: '' },
        { value: 'Corporal', label: '' },
        { value: 'Doctor', label: '' },
        { value: 'Field Marshal', label: '' },
        { value: 'Flight Lieutenant', label: '' },
        { value: 'General', label: '' },
        { value: 'Group Captain', label: '' },
        { value: 'Lieutenant', label: '' },
        { value: '2nd Lieutenant', label: '' },
        { value: 'Lieutenant Colonel', label: '' },
        { value: 'Lieutenant Commander', label: '' },
        { value: 'Lieutenant General', label: '' },
        { value: 'Major', label: '' },
        { value: 'Major General', label: '' },
        { value: 'Professor', label: '' },
        { value: 'Rear Admiral', label: '' },
        { value: 'Sergeant', label: '' },
        { value: 'Sheikh', label: '' },
        { value: 'Squadron Leader', label: '' },
        { value: 'Staff Sergeant', label: '' },
        { value: 'Vice Admiral', label: '' },
        { value: 'Warrant Officer', label: '' },  
        { value: 'Wing Commander', label: '' },
        ],
      notesData:              [],
      linkOrganisationsData:  [],
      linkEventsData:         [],
      linkSoulsData:          [],
      generalLocations:       [],
      notesPagination:                { perPage: 5, currentPage: 1, total: 0 },
      linkOrganisationsPagination:    { perPage: 5, currentPage: 1, total: 0 },
      linkEventsPagination:           { perPage: 5, currentPage: 1, total: 0 },
      linkSoulsPagination:            { perPage: 5, currentPage: 1, total: 0 },
      linkOrganisationsColumns:     [{ prop: 'orgID.org_en', label: 'Organisations', minWidth: 200 }],
      linkEventsColumns:            [{ prop: 'eventID.title', label: 'Events', minWidth: 200 }],
      linkSoulsColumns:             [{ prop: 'soulID.nameEnglish', label: 'People', minWidth: 200 }],
      notesColumns:                 [{ prop: 'note', label: '', minWidth: 250 }],
      socialMediaColumns:           [{ prop: 'media', label: 'Social Media', minWidth: 250 }],
      basedInColumns:               [{ prop: 'basedin', label: 'Based In', minWidth: 250 }],
      rankColumns:                  [{ prop: 'rankName', label: 'Ranks', minWidth: 250 }],
      positionColumns:              [{ prop: 'positionNameEnglish', label: 'Position (English)', minWidth: 200 }, { prop: 'positionNameArabic', label: 'Position (Arabic)', minWidth: 200 }],
      akaColumns:                   [{ prop: 'englishAKA', label: 'Also Known As (English)', minWidth: 200 },{ prop: 'arabicAKA', label: 'Also Known As (Arabic)', minWidth: 200 }],
      tribeColumns:                 [{ prop: 'tribe', label: 'Tribe', minWidth: 250 }],
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    changeName(){
      if (this.titleSwap == 'Arabic Name') {
        // change to arabic
        this.soul.title = this.arabicName
        this.soul.subTitle = "Arabic Name"
        this.titleSwap = "English Name"
      }
      else if (this.titleSwap == 'English Name') {
        // change to english
        this.soul.title = this.englishName
        this.soul.subTitle = "English Name"
        this.titleSwap = "Arabic Name"
      }
    },
    async getSoul () {
      let loading = Loading.service()
      const soul = await SoulsService.getSoul({
        id: this.$route.params.id
      })
      this.soulDateCard.secondTitle = new Date(soul.data.lastupdated.substring(0, 10)).toDateString()
      this.soulDateCard.title = new Date(parseInt(soul.data._id.substring(0, 8), 16) * 1000).toDateString()
      this.englishName = soul.data.nameEnglish
      this.arabicName = soul.data.nameArabic
      this.images = soul.data.image
      this.basedInData = soul.data.basedin
      this.notesData = soul.data.notes
      this.notesData.reverse()
      this.positionData = soul.data.position
      this.rankData = soul.data.ranks
      this.akaData = soul.data.aka
      this.tribeData = soul.data.tribe
      this.socialMediaData = soul.data.socialmedia
      this.soul.affiliation = soul.data.affiliation
      this.linkSoulsData = soul.data.linkedsouls
      this.linkOrganisationsData = soul.data.linkedorganisations
      this.linkEventsData = soul.data.linkedevents
      this.soul.bornIn = soul.data.bornin
      this.dateOfBirth = soul.data.dob
      if (this.titleSwap == 'Arabic Name') {
        this.soul.title = soul.data.nameEnglish
      } else {
        this.soul.title = soul.data.nameArabic
      }
      if (this.basedInData.length != 0){
        this.titleBasedIn = "Based In - " + this.basedInData[this.basedInData.length - 1].basedIn + " (" + this.basedInData.length + ")"
      }
      if (this.positionData.length != 0){
        this.titlePositions = "Position - " + this.positionData[this.positionData.length - 1].positionNameEnglish + " (" + this.positionData.length + ")"
      }
      if (this.rankData.length != 0){
        this.titleRanks = "Rank - " + this.rankData[this.rankData.length - 1].rankName + " (" + this.rankData.length + ")"
      }
      if (this.akaData.length != 0){
        this.titleAKA = "Also Known As - " + this.akaData[this.akaData.length - 1].englishAKA + " (" + this.akaData.length + ")"
      }
      if (this.socialMediaData.length != 0){
        this.titleSocialMedia = "Social Media - " + this.socialMediaData[this.socialMediaData.length - 1].media + " (" + this.socialMediaData.length + ")"
      }
      if (this.tribeData.length != 0){
        this.titleTribe = "Tribe - " + this.tribeData[this.tribeData.length - 1].tribe + " (" + this.tribeData.length + ")"
      }
      if (soul.data.linkedorganisations.length == 0){
        document.getElementById("linkOrganisations").style.display = "none"
      } else{
        document.getElementById("linkOrganisations").style.display = "flex"
      }
      if (soul.data.linkedsouls.length == 0){
        document.getElementById("linkSouls").style.display = "none"
      } else{
        document.getElementById("linkSouls").style.display = "flex"
      }
      if (soul.data.linkedevents.length == 0){
        document.getElementById("linkEvents").style.display = "none"
      } else{
        document.getElementById("linkEvents").style.display = "flex"
      }
      loading.close()
    },
    async updateSoulDetails(){
      let loading = Loading.service()
      await SoulsService.updateSoul({
        id: this.$route.params.id,
        affiliation: this.soul.affiliation, 
        // bornin: this.bornIn,
        // borninDate: new Date(),
        dob: this.dateOfBirth,
        dobDate: new Date(),
        lastupdated: new Date(),
      })
      this.modalAffiliation = false
      //this.modalBornIn = false
      this.modalDateOfBirth = false
      this.getSoul()
      loading.close()
    },
    async editBornIn(location){
      let loading = Loading.service()
      await SoulsService.updateSoul({
        id: this.$route.params.id,
        bornin: location,
        borninDate: new Date(),
        lastupdated: new Date(),
      })
      this.modalBornIn = false
      this.modalDateOfBirth = false
      this.getSoul()
      loading.close()
    },
    async updateEnglishName(newName){
      this.modalEnglishName = false
      let loading = Loading.service()
      await SoulsService.updateSoul({
        id: this.$route.params.id,
        nameEnglish: newName, 
        lastupdated: new Date(),
      }).catch(err => loading.close().then(this.notifyFailure()))
      loading.close()
      this.notifySuccess()
      this.getSoul()
      
    },
    async updateArabicName(newName){
      this.modalArabicName = false
      let loading = Loading.service()
      await SoulsService.updateSoul({
        id: this.$route.params.id,
        nameArabic: newName, 
        lastupdated: new Date(),
      }).catch(err => loading.close().then(this.notifyFailure()))
      loading.close()
      this.notifySuccess()
      this.getSoul()
      
    },
    async searchGeneralLocations() {
      let loading = Loading.service()
      const searchedLocations = await SearchService.searchGeneralLocations({searchTerms: this.searchBarGeneralLocation})
      this.searchGeneralLocationData = searchedLocations.data
      loading.close()
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    imageToUpload: function(params) {
      let reader = new FileReader()
      reader.readAsDataURL(params)
      reader.onload = e => {
        this.selectedFile = e.target.result
      }
    },
    saveImage: function(params) {
      this.uploadImage()
    },
    async uploadImage() {
      let loading = Loading.service()
      await SoulsService.updateSoulImage({
        id: this.$route.params.id,
        image: this.selectedFile
      })
      loading.close()
      this.getSoul()
    },
    async viewSoul(soulID) {
      this.$router.push({ name: 'View Person', params: { id: soulID}})
    },
    async viewEvent(eventID) {
      this.$router.push({ name: 'View Event', params: { id: eventID}})
    },
    async viewOrganisation(organisationID) {
      this.$router.push({ name: 'View Organisation', params: { id: organisationID}})
    },
    async addGeneralLocation(newBasedIn) {
      this.modalBasedIn = false
      let loading = Loading.service()
      let newSoulBasedIn = await SoulsService.updateBasedIn({
        id: this.$route.params.id,
        basedIn: newBasedIn, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      this.getSoul()
      loading.close()
      this.notifySuccess()
    },
    addSocialMedia() {
      this.modalSocialMedia = false
      this.createSocialMedia(this.newSocialMedia)
    },
    async createSocialMedia(newSocialMedia){
      let loading = Loading.service()
      let newSoulSocialMedia = await SoulsService.updateSocialMedia({
        id: this.$route.params.id,
        media: newSocialMedia, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      loading.close()
      this.notifySuccess()
      this.getSoul()
      
    },
    async createNote(){
      this.modalNote = false
      let loading = Loading.service()
      let newSoulNote = await SoulsService.createNote({
        id: this.$route.params.id,
        note: this.newNote, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      loading.close()
      this.notifySuccess()
      this.getSoul()
      this.newNote = ""
      
      
    },
    addPosition() {
      this.createPosition(this.newPositionEnglish, this.newPositionArabic)
    },
    addAKA() {
      this.createAKA(this.newAKAEnglish, this.newAKAArabic)
    },
    addRank() {
      this.createRank(this.selects.rank)
    },
    async createPosition(english, arabic){
      this.modalPosition = false
      let loading = Loading.service()
      let newSoulPosition = await SoulsService.updatePosition({
        id: this.$route.params.id,
        positionNameEnglish: english,
        positionNameArabic: arabic
      }).catch(err => loading.close().then(this.notifyFailure()))
      
      loading.close()
      this.notifySuccess()
      this.getSoul()
    },
    async createRank(newRank){
      this.modalRank = false
      let loading = Loading.service()
      let newSoulRank = await SoulsService.updateRank({
        id: this.$route.params.id,
        rankName: newRank,
      }).catch(err => loading.close().then(this.notifyFailure()))
      
      loading.close()
      this.notifySuccess()
      this.getSoul()
    },
    async createAKA(english, arabic){
      this.modalAKA = false
      let loading = Loading.service()
      let newSoulAKA = await SoulsService.updateAKA({
        id: this.$route.params.id,
        englishAKA: english,
        arabicAKA: arabic
      }).catch(err => loading.close().then(this.notifyFailure()))
      
      loading.close()
      this.notifySuccess()
      this.getSoul()
    },
    async replaceNote(){
      this.modalNoteReplace = false
      let loading = Loading.service()
      let newSoulNote = await SoulsService.replaceNote({
        id: this.$route.params.id,
        sid: this.replaceNoteID,
        note: this.replaceNoteText, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      
      loading.close()
      this.notifySuccess()
      this.getSoul()
    },
    editPosition(data) {
      this.modalPositionReplace = true
      this.replacePositionEnglish = data.positionNameEnglish
      this.replacePositionArabic = data.positionNameArabic
      this.replacePositionID = data._id
    },
    editAKA(data) {
      this.modalAKAReplace = true
      this.replaceAKAEnglish = data.englishAKA
      this.replaceAKAArabic = data.arabicAKA
      this.replaceAKAID = data._id
    },
    editSocialMedia(data) {
      this.modalSocialMediaReplace = true
      this.replaceSocialMediaValue = data.media
      this.replaceSocialMediaID = data._id
    },
    async replacePosition(positionID, english, arabic){
      this.modalPositionReplace = false
      let loading = Loading.service()
      let newSoulPosition = await SoulsService.replacePosition({
        id: this.$route.params.id,
        sid: positionID,
        positionNameEnglish: english, 
        positionNameArabic: arabic, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      
      loading.close()
      this.notifySuccess()
      this.getSoul()
    },
    async replaceAKA(akaID, english, arabic){
      this.modalAKAReplace = false
      let loading = Loading.service()
      let newSoulPosition = await SoulsService.replaceAKA({
        id: this.$route.params.id,
        sid: akaID,
        englishAKA: english, 
        arabicAKA: arabic, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      
      loading.close()
      this.notifySuccess()
      this.getSoul()
    },
    async replaceSocialMedia(socialMediaID, media){
      this.modalSocialMediaReplace = false
      let loading = Loading.service()
      let newSoulPosition = await SoulsService.replaceSocialMedia({
        id: this.$route.params.id,
        sid: socialMediaID,
        media: media, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      
      loading.close()
      this.notifySuccess()
      this.getSoul()
    },
    async getTribes() {
      let loading = Loading.service()
      let searchedTribes = await SearchService.searchTribes({searchTerms : this.searchBarTribe})
      this.tribes = searchedTribes.data
      loading.close()
    },
    async removeLinkSoulOrg(orgID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkSoulOrgDB(orgID)
            }
          }
        })
    },
    async removeLinkSoulOrgDB(orgID) {
      let loading = Loading.service()
      await DeleteService.removeLinkSoulOrg({soul : this.$route.params.id, org: orgID})
      await this.getSoul()
      loading.close()
    },
    async removeLinkSoulSoul(soulID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkSoulSoulDB(soulID)
            }
          }
        })
    },
    async removeLinkSoulSoulDB(soulID) {
      let loading = Loading.service()
      await DeleteService.removeLinkSoulSoul({soul1 : this.$route.params.id, soul2: soulID})
      await this.getSoul()
      loading.close()
    },
    async removeLinkEventSoul(eventID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkEventSoulDB(eventID)
            }
          }
        })
    },
    async removeLinkEventSoulDB(eventID) {
      let loading = Loading.service()
      await DeleteService.removeLinkEventSoul({soul : this.$route.params.id, event: eventID})
      await this.getSoul()
      loading.close()
    },
    async addTribe(newTribe) {
      this.modalTribe = false
      let loading = Loading.service()
      let newSoulTribe = await SoulsService.updateTribe({
        id: this.$route.params.id,
        tribe: [newTribe.tribecountry, newTribe.ethnicity, newTribe.confederation_en, newTribe.confederation_ar, newTribe.tribe_en, newTribe.tribe_ar, newTribe.firstsubtribe_en, newTribe.firstsubtribe_ar, newTribe.secondsubtribe_en, newTribe.secondsubtribe_ar, newTribe.thirdsubtribe_en, newTribe.thirdsubtribe_ar, void 0].join(';'),
      }).catch(err => loading.close().then(this.notifyFailure()))
      
      loading.close()
      this.tribeData = null
      this.notifySuccess()
      this.getSoul()
    },
    addLink() {
      this.$router.push({name: 'Link', params: {id: this.$route.params.id, country: this.country, recordType: 'soul', recordName: this.soul.title}})
    },
    editNote(index, row) {
      this.modalNoteReplace = true
      this.replaceNoteText = this.notesData[index].note
      this.replaceNoteID = this.notesData[index]._id
      // swal({
      //     title: 'Edit Note',
      //     input: 'textarea',
      //     showCancelButton: true,
      //     confirmButtonText: 'Save',
      //     cancelButtonText: 'Cancel',
      //     confirmButtonClass: 'btn btn-success btn-fill',
      //     cancelButtonClass: 'btn btn-info btn-fill',
      //     buttonsStyling: false,
      //     allowOutsideClick: false,
      //     inputValue: this.notesData[index].note,
      //     inputValidator: (value) => {
      //       if (!value) {
      //         return 'You need to write something!'
      //       }
      //       if (value != this.notesData[index].note) {
      //         // its different so we need to update it
      //         this.replaceNote(this.notesData[index]._id,value)
      //       }
      //     }
      //   })
    },
    notifySuccess() {
      this.$notify({
        message:
          'Record Updated',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "info"
      });
    },
    notifyFailure() {
      this.$notify({
        message:
          'Update Failed',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "danger"
      });
    },
    deleteRecord() {
      swal({
          title: 'Delete Person',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove this record from the database'
            }
            if (value == 'DELETE'){
              this.deleteSoul()
            }
          }
        })
    },
    async deleteSoul() {
      let loading = Loading.service()
      await DeleteService.deleteSoul({
        id: this.$route.params.id,
      })
      loading.close()
      this.$router.push({ name: 'Search'})
    },
  }


};
</script>
<style lang="scss">
.pagination-select, .search-input {
  width: 200px;

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
}</style>
