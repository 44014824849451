<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="row">
      <div class="col-2" v-if="$slots.icon || icon">
        <div class="info-icon text-center" :class="`icon-${type}`">
          <slot name="icon"> <i :class="icon"></i> </slot>
        </div>
      </div>

      <div class="col-10">
        <slot>
            <div class="row-12">
              <p class="card-category">{{ subTitle }}</p>
            </div>
            <div class="row">
              <div class="col-10">
                  <base-input :placeholder="`${textBox}`"></base-input>
              </div>
              <div class="col-2">
                  <base-button type="info" size="sm">{{ button }}</base-button>
                </div>

            </div>
        </slot>
     </div>

    </div>
    <div class="stats" slot="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </card>
</template>
<script>
import Card from './Card.vue';

export default {
  name: 'stats-card',
  components: {
    Card
  },
  props: {
    type: {
      type: String,
      default: 'info'
    },
    icon: String,
    subTitle: String,
    textBox: String,
    button: String
  }
};
</script>
<style></style>
