// This Service is CRU for organisations (fetch for all)

import Api from '@/services/Api'

const options = {
  headers: {'Authorization': window.GLOBALIDTOKEN}
}

export default {
  addOrganisation (params) {
    return Api().post('organisation/add' + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  updateOrganisation (params) {
    return Api().put('organisation/update/details/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getOrganisation (params) {
    return Api().get('organisation/get/' + params.id + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  updateOrganisationImage (params) {
    return Api().put('organisation/create/image/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // Add a new note to the array
  createNote (params) {
    return Api().put('organisation/create/note/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // Modify & replace a specific note that already exists
  replaceNote (params) {
    return Api().put('organisation/replace/note/' + params.id + '/' + params.sid + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  createSocialMedia (params) {
    return Api().put('organisation/update/social/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // Push a new value into the basedIn array
  updateBasedIn (params) {
    return Api().put('organisation/update/basedin/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  replaceSocial (params) {
    return Api().put('organisation/social/replace/' + params.id + '/' + params.sid + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
}