<template>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <h5 class="info-text">
        What are the details of the source?
      </h5>
    </div>
    
    <div class="col-md-12 justify-content-center">

      <div class="form-horizontal justify-content-center"> 
        <div class="row  justify-content-center">
          <div class="col-md-8">
            <base-input
              name="title"
              placeholder="title"
              v-model="propTitle"
              :error="getError('title')"
              v-validate="modelValidations.title"
            >
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input :error="getError('country')">
              <el-select
                name="country"
                id="country"
                class="select-info"
                size="medium"
                placeholder="Country"
                v-model="propCountry"
                filterable
                v-validate="modelValidations.country"
              >
                <el-option
                  v-for="option in countries"
                  class="select-info"
                  :value="option.search"
                  :label="option.search"
                  :key="option.search"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input :error="getError('type')">
              <el-select
                name="type"
                id="type"
                class="select-info"
                size="medium"
                filterable
                placeholder="Type"
                v-model="propType"
                v-validate="modelValidations.type"
              >
                <el-option
                  v-for="option in types"
                  class="select-info"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="row  justify-content-center">
          <div class="col-md-8">
            <base-input
              name="affiliation"
              id="affiliation"
              placeholder="Affiliation"
              v-model="propAffiliation"
              v-validate="modelValidations.affiliation"
              :error="getError('affiliation')"
            />
          </div>
        </div>

        <div class="row justify-content-center">   
          <label class="col-md-2 col-form-label">YouTube</label> 
          <div class="col-md-6">
            <base-input
              name="youtube"
              placeholder=""
              v-model="propYoutube"
              addon-left-icon="fab fa-youtube"
            />
          </div>
        </div>

        <div class="row justify-content-center">   
          <label class="col-md-2 col-form-label">Instagram</label> 
          <div class="col-md-6">
            <base-input
              name="instagram"
              placeholder=""
              v-model="propInstagram"
              addon-left-icon="fab fa-instagram"
            />
          </div>
        </div>

        <div class="row justify-content-center">   
          <label class="col-md-2 col-form-label">Twitter</label> 
          <div class="col-md-6">
            <base-input
              name="twitter"
              placeholder=""
              v-model="propTwitter"
              addon-left-icon="fab fa-twitter"
            />
          </div>
        </div>

        <div class="row justify-content-center">   
          <label class="col-md-2 col-form-label">Website</label> 
          <div class="col-md-6">
            <base-input
              name="website"
              placeholder=""
              v-model="propWebsite"
              addon-left-icon="tim-icons icon-laptop"
            />
          </div>
        </div>

        <div class="row justify-content-center">   
          <label class="col-md-2 col-form-label">Facebook</label> 
          <div class="col-md-6">
            <base-input
              name="facebook"
              placeholder=""
              v-model="propFacebook"
              addon-left-icon="fab fa-facebook"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
import SourcesService from '@/services/SourcesService'
import SearchService from '@/services/SearchService'
import { Loading } from 'element-ui'
import Vue from "vue"

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  props:['propTitle', 'propContent', 'propCountry', 'propType', 'propAffiliation', 'propWebsite', 'propInstagram', 
    'propTwitter', 'propFacebook', 'propYoutube'],
  data() {
    return {
      affiliation: '',
      selects: {
        type:'',
        country:'',
      },
      types: [
          { value: 'News Outlet', label: 'News Outlet' },
          { value: 'Pro-Government Social Media', label: 'Pro-Government Social Media' },
          { value: 'Anti-Government Social Media', label: 'Anti-Government Social Media' },
          { value: 'Opposition Party', label: 'Opposition Party' },
          { value: 'Government', label: 'Government' },
          { value: 'Military', label: 'Military' },
          { value: 'Extremist', label: 'Extremist' },
          { value: 'Social Activist/NGO', label: 'Social Activist/NGO' },
          { value: 'Religous', label: 'Religous' },
          { value: 'Prominent Figure', label: 'Prominent Figure' },
        ],
        countries: [],
      modelValidations: {
        title: {
          required: true,
          min: 1
        },
        content: {
          required: true,
          min: 1
        },
      }
    };
  },
  mounted () {
    this.getNations()
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async validate() {
      await this.addSource()
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async addSource () {
      let loading = Loading.service()
      //let userInfo = await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
      const newSource = await SourcesService.addSource({
        country: this.propCountry,
        name: this.propTitle,
        facebook: this.propFacebook,
        youtube: this.propYoutube,
        instagram: this.propInstagram,
        website: this.propWebsite,
        twitter: this.propTwitter,
        type: this.propType,
        affiliation: this.propAffiliation,
        lastupdated: new Date(),
        //user: userInfo.attributes
      })
      loading.close()
      this.$emit("child-newSource", newSource)
    },
    async getNations() {
      this.countries = window.GLOBALNATIONS
    },
  }
};
</script>
<style></style>
