<template>
  <div class="row justify-content-center">
    <div class="col-md-6 justify-content-center">
      <h5 class="info-text">
        How did you want the file(s) to be exported?
      </h5>
    </div>

    <div class="col-md-12 justify-content-center">
      <div class="row justify-content-center">
        <label class="form-check-label">
            <input class="form-check-input" v-model="oneFile" type="checkbox" value="">
            <p>Export as a single file</p>
            <span class="form-check-sign">
                <span class="check"></span>
            </span>
        </label>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
  },
  data() {
    return {
      oneFile: true,
    }
  },
  methods: {
    async validate() {
      this.$emit("child-singlefile", this.oneFile);
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
  }
};
</script>

<style></style>
