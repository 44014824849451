<template>
  <div class="row justify-content-center">
    <div class="col-md-6 justify-content-center">
      <h5 class="info-text">
        All done?
      </h5>
    </div>

    <div class="col-md-12 justify-content-center">
      <div class="form-horizontal justify-content-center"> 

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input
              name="englishName"
              id="englishName"
              v-model="propEnglishName"
              v-validate="modelValidations.englishName"
              :error="getError('englishName')">
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input
              name="arabicName"
              id="arabicName"
              v-model="propArabicName"
              v-validate="modelValidations.arabicName"
              :error="getError('arabicName')">
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input :error="getError('country')">
              <el-select
                name="country"
                id="country"
                class="select-info"
                size="medium"
                filterable
                v-model="propCountry"
                v-validate="modelValidations.country">
                <el-option
                  v-for="option in countries"
                  class="select-info"
                  :value="option.search"
                  :label="option.search"
                  :key="option.search">
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>
      </div>

        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Based In</label> 
          <div class="col-md-6">
            <base-input>
              <el-select
                name="basedIn"
                id="basedIn"
                class="select-info"
                size="medium"
                placeholder=""
                filterable
                v-model="propGeneralLocation"
              >
                <el-option
                  v-for="option in generalLocations"
                  class="select-info"
                  :value="option.search"
                  :label="option.search"
                  :key="option.search"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div> -->

        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Affiliation</label> 
          <div class="col-md-6">
            <base-input
              name="affiliation"
              id="affiliation"
              placeholder=""
              v-model="propAffiliation"
            />
          </div>
        </div> -->

        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Position</label> 
          <div class="col-md-6">
            <base-input
              placeholder=""
              v-model="propEnglishPosition"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Position (Arabic)</label> 
          <div class="col-md-6">
            <base-input
              name="arabicPosition"
              id="arabicPosition"
              placeholder=""
              v-model="propArabicPosition"
            />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import SoulsService from '@/services/SoulsService'
import SearchService from '@/services/SearchService'
import { Loading } from 'element-ui'

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props:['propEnglishName', 'propArabicName', 'propCountry', 'propGeneralLocation', 'propAffiliation', 'propEnglishPosition', 'propArabicPosition'],
  mounted() {
    this.getNations()
  },
  data() {
    return {
      selects: {
        country:'',
      },
      countries: [],
      englishName: '',
      arabicName: '',
      affiliation: '',
      englishPosition: '',
      arabicPosition: '',
      basedIn: 'empty',
      generalLocations: [],
      modelValidations: {
        englishName: {required: true, min: 1},
        arabicName: {required: false}, 
        country: {required: true},
      }
    }
  },
  // watch: {
  //   propCountry:function(value){
  //     this.getGeneralLocations(value)
  //   }
  // },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async validate() {
      await this.addSoul()
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async addSoul () {
      let loading = Loading.service()
      let newSoul = null
      let count = 0
      if (this.propGeneralLocation == "" || this.propGeneralLocation == null){
        this.basedIn = this.propCountry
      } else {
        this.basedIn = this.propGeneralLocation
      }
      
      
      while (true) {
        try {
          newSoul = await SoulsService.addSoul({
            nameEnglish: this.propEnglishName, 
            nameArabic: this.propArabicName, 
            //affiliation: this.propAffiliation, 
            basedin: [{basedIn: this.basedIn, basedInAdded: new Date()}],
            position: [{positionNameEnglish: this.propEnglishPosition, positionNameArabic: this.propArabicPosition, positionAdded: new Date()}],
            lastupdated: new Date(),
          })
          break
        } catch (error) {
          count++
          if (error.response.status != 500 && error.response.status != 502) {
                loading.close()
                this.notifyFailure()
                break
          }
          if (count == 2){
            loading.close()
            this.notifyFailure()
            break
          }
        }
      }
      
      loading.close()
      this.$emit("child-newSoul", newSoul.data._id)
    },
    async getNations() {
      this.countries = window.GLOBALNATIONS
    },
    async getGeneralLocations(countrySelected) {
      const activeGeneralLocations = await SearchService.fetchLocations({country: countrySelected})
      this.generalLocations = activeGeneralLocations.data
    },
    notifyFailure() {
      this.$notify({
        message:
          'Action Failed',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "danger"
      });
    },
  }
};
</script>

<style></style>
