<template>
  <div class="row justify-content-center">
    <div class="col-md-6 justify-content-center">
      <h5 class="info-text">
        All done?
      </h5>
    </div>

    <div class="col-md-12 justify-content-center">
      <div class="form-horizontal justify-content-center"> 
        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input :error="getError('eventDate')">
              <el-date-picker
                name="eventDate"
                id="eventDate"
                type="datetime"
                placeholder="Date Time Picker"
                v-model="propEventDate"
                v-validate="modelValidations.eventDate"            
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input
              name="title"
              id="title"
              placeholder="Title"
              v-model="propTitle"
              v-validate="modelValidations.title"
              :error="getError('title')"
            >
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
      <base-input :error="getError('type')">
        <el-select
          name="type"
          id="type"
                  class="select-info"
                  size="medium"
                  placeholder="Type"
                  filterable
                  v-model="propType"
                  v-validate="modelValidations.type"
                >
                  <el-option
                    v-for="option in types"
                    class="select-info"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value"
                  >
                  </el-option>
                </el-select>
        </base-input>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
      <base-input :error="getError('country')">
        <el-select
          name="country"
          id="country"
          class="select-info"
          size="medium"
          placeholder="Country"
          filterable
          v-model="propCountry"
          v-validate="modelValidations.country"
        >
          <el-option
            v-for="option in countries"
            class="select-info"
            :value="option.search"
            :label="option.search"
            :key="option.search"
          >
          </el-option>
        </el-select>
      </base-input>
          </div>
        </div>
        
      </div>
        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Location Area</label> 
          <div class="col-md-6">
            <base-input>
              <el-select
                name="locationArea"
                id="locationArea"
                class="select-info"
                size="medium"
                placeholder=""
                filterable
                v-model="propGeneralLocation"
              >
                <el-option
                  v-for="option in generalLocations"
                  class="select-info"
                  :value="option._id"
                  :label="option.search"
                  :key="option._id"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div> -->

        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Specific Location</label> 
          <div class="col-md-6">
            <base-input>
              <el-select
                name="specificLocation"
                id="specificLocation"
                class="select-info"
                size="medium"
                placeholder=""
                filterable
                v-model="propSpecificLocation"
              >
                <el-option
                  v-for="option in specificLocations"
                  class="select-info"
                  :value="option._id"
                  :label="option.name_en"
                  :key="option._id"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div> -->

        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Source Reference</label> 

          <div class="col-md-6">
            <base-input
              name="sourceReference"
              id="sourceReference"
              placeholder=""
              v-model="propSourceReference"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Report Reference</label> 

          <div class="col-md-6">
            <base-input
              name="reportReference"
              id="reportReference"
              placeholder=""
              v-model="propReportReference"
            />
          </div>
        </div>


    </div>
  </div>
</template>

<script>
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import EventsService from '@/services/EventsService'
import SearchService from '@/services/SearchService'
import POIsService from '@/services/POIsService'
import { Loading } from 'element-ui'

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props:['propEventDate', 'propTitle', 'propType', 'propCountry', 'propGeneralLocation', 'propSpecificLocation', 
  'propSourceReference', 'propReportReference','propEventDescription'],
  mounted() {
    this.getNations()
    //this.getSpecificLocations()
    
  },
  data() {
    return {
      eventDate: '',
      type: '',
      datetime:'',
      selects: {
        type:'',
        country:'',
        generalLocation:'',
        specificLocation:'',
      },
      types: [
          { value: 'Air Strike', label: 'Air Strike' },
          { value: 'Armed Attack', label: 'Armed Attack' },
          { value: 'Arrest', label: 'Arrest' },
          { value: 'IED', label: 'IED' },
          { value: 'VBIED', label: 'VBIED' },
          { value: 'Event', label: 'Event' },
          { value: 'Communication', label: 'Communication' },
          { value: 'Sighting', label: 'Sighting' },
          { value: 'Migration', label: 'Migration' },
          { value: 'Protest', label: 'Protest' },
          { value: 'Worker Strike', label: 'Worker Strike' },
          { value: 'Weapons Find', label: 'Weapons Find' },
          { value: 'Surrender', label: 'Surrender' },
          { value: 'Kidnapping', label: 'Kidnapping' },
          { value: 'Smuggling', label: 'Smuggling' },
          { value: 'Attack Planning', label: 'Attack Planning' },
          { value: 'Movement', label: 'Movement' },
          { value: 'Human Rights', label: 'Human Rights' },
          { value: 'Military Operation', label: 'Military Operation' },
          { value: 'Military Training', label: 'Military Training' },
          { value: 'Ground Control', label: 'Ground Control' },
        ],
      countries: [],
      sourceReference: '',
      reportReference: '',
      eventDescription: '',
      generalLocations: [],
      specificLocations: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      title: '',
      modelValidations: {
        title: {required: true, min: 1},
        type: {required: true}, 
        eventDate: {required: true},
        country: {required: true}
      },
    }
  },
  // watch: {
  //   propCountry:function(value){
  //     this.getGeneralLocations(value)
  //   }
  // },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async validate() {
      await this.addEvent()
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async addEvent () {
      let loading = Loading.service()
      let newEvent = null
      let count = 0
      while (true) {
        try {
          newEvent = await EventsService.addEvent({
          type: this.propType, 
          date: this.propEventDate, 
          title: this.propTitle,
          sourceref: this.propSourceReference,
          reportref: this.propReportReference, 
          country: this.propCountry, 
          general_location: this.propGeneralLocation, 
          specific_location: this.propSpecificLocation, 
          lastupdated: new Date(),
      })
          break
        } catch (error) {
          count++
          if (error.response.status != 500 && error.response.status != 502) {
                loading.close()
                this.notifyFailure()
                break
          }
          if (count == 2){
            loading.close()
            this.notifyFailure()
            break
          }
        }
      }
      loading.close()
      this.$emit("child-newEvent", newEvent)
    },
    async getNations() {
      this.countries = window.GLOBALNATIONS
    },
    async getGeneralLocations(countrySelected) {
      const activeGeneralLocations = await SearchService.fetchLocations({country: countrySelected})
      this.generalLocations = activeGeneralLocations.data
    },
    async getSpecificLocations() {
      const activeSpecificLocations = await SearchService.fetchPOIs()
      this.specificLocations = activeSpecificLocations.data
    },
    notifyFailure() {
      this.$notify({
        message:
          'Action Failed',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "danger"
      });
    },
  }
};
</script>

<style></style>
