<template>
  <div class="row">
    <div class="col-lg-6 col-md-6" >
      <card class="card">

        <div class="col-12">

            <div class="row-12">
              <h4 >Search</h4>
            </div>
            <div class="row">
              <div class="col-10">
                  <i class="tim-icons icon-bulb-63" ></i>Tip: Use AND, OR and () for grouping terms together
              </div>
              <div class="col-10">
                  <base-input v-model="searchBarMust" v-on:keyup.enter="startSearch" placeholder="Enter Search"></base-input>
              </div>
              
              
              <div id="advancedSearchMustNot" class="col-10">
                  <base-input v-model="searchBarMustNot" placeholder="Must Not Include - any term found will exclude the record from results" default="NotInUse"></base-input>
              </div>
              <div id="advancedSearchShould" class="col-10">
                  <base-input v-model="searchBarShould" placeholder="Boost - records with these terms will appear higher in the results" ></base-input>
              </div>
              <div id="fuzzy" class="col-md-8">
                <base-input>
                  <el-select
                    class="select-info"
                    size="medium"
                    placeholder="Fuzziness"
                    filterable
                    v-model="selectFuzzy"
                  >
                    <el-option
                      v-for="option in fuzzyOptions"
                      class="select-info"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-2">
                  <base-button v-loading="searchLoading" class="btn-info" v-model="searchButton" @click="startSearch" size="sm">Go</base-button>
                  
              </div>
            </div>
        </div>

        <a class="nav-link" @click="changeSearch()">
            <i class="tim-icons icon-refresh-02" ></i> Search Options
          </a>

      </card>
    </div>

    <modal :show.sync="modalCountry" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Change Country</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input>
                <el-select
                  class="select-info"
                  size="medium"
                  placeholder=""
                  filterable
                  v-model="newCountry"
                >
                  <el-option
                    v-for="option in newCountries"
                    class="select-info"
                    :value="option.search"
                    :label="option.search"
                    :key="option.search"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalCountry = false">Cancel</base-button>
            <base-button type="success" @click.native="changeCountry()">Save</base-button>
          </div>
        </modal>

    <div class="col-lg-4 col-md-6">
      <stats-card
        :title='country.title'
        :sub-title='country.subTitle'
        :type="country.type"
        :icon="country.icon">
        <div slot="footer">
          <a class="nav-link" @click="modalCountry = true">
            <i class="tim-icons icon-refresh-02" ></i> Change
          </a>
        </div>
      </stats-card>
    </div>

    <div class="col-md-12">
      <card>
        <tabs square type="info">
          <tab-pane :label="reportsLabel">
            <div class="form-horizontal"> 
              <div class="row">  
                <div class="col-md-11">
                  <h4>Search Results</h4>
                  <i class="tim-icons icon-alert-circle-exc"></i> Click on a column title to sort (click twice to change between ascending and decending)
                  <data-tables :data="reportsData" :pagination-props="{ pageSizes: [10], hideOnSinglePage: true, background: true, layout: &quot;prev, pager, next, slot&quot; }" layout="table, pagination">
                    <!-- <el-table-column v-for="column in reportsColumns" :prop="column.prop" :label="column.label" :key="column.label" sortable="custom">
                    </el-table-column> -->
                    <el-table-column
                      key="Name"
                      prop="name"
                      min-width=100
                      label="Name"
                      sortable
                    >
                    <template slot-scope="scope">
                      <span v-html="scope.row.name"/>
                    </template>
                    </el-table-column>

                    <el-table-column
                      key="Text"
                      prop="text"
                      min-width=300
                      label="Text"
                      sortable
                    >
                    <template slot-scope="scope">
                      <span v-html="scope.row.highlights"/>
                    </template>
                    </el-table-column>
                    <el-table-column :min-width="50" align="right" label="">
                      <div slot-scope="props">
                        <base-button
                          @click.native="viewReport(props.row._id)"
                          class="btn-simple btn-link"
                          type="info"
                          size="sm"
                        >
                        View
                        </base-button>
                      </div>
                    </el-table-column>
                    </data-tables>
                
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane :label="soulsLabel"> 
            <div class="form-horizontal"> 
              <div class="row">  
                <div class="col-md-11">
                  <h4>Search Results</h4>
                  <i class="tim-icons icon-alert-circle-exc"></i> Click on a column title to sort (click twice to change between ascending and decending)
                </div>
                <div class="col-md-11">
                  <br>
                </div>
                <div class="col-md-11">
                  <data-tables :data="soulsData" :page-size="1" :pagination-props="{ pageSizes: [10], hideOnSinglePage: true, background: true, layout: &quot;prev, pager, next, slot&quot; }" layout="table, pagination">
                    <el-table-column
                      v-for="column in soulsColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                      sortable
                    />
                    <el-table-column :min-width="135" align="right" label="">
                      <div slot-scope="props">
                        <base-button
                          @click.native="viewSoul(props.row._id)"
                          class="btn-simple btn-link"
                          type="info"
                          size="sm"
                        >
                        View
                        </base-button>
                      </div>
                    </el-table-column>
                  </data-tables>
                  <!-- <div class="row">
                    <div class="col-form-label ml-auto">
                      {{ soulsFrom + 1 }}-{{ soulsTo }} of {{ soulsTotal }}
                    </div>
                    <base-pagination
                      class="pagination-no-border"
                      v-model="soulsPagination.currentPage"
                      :per-page="soulsPagination.perPage"
                      :total="soulsTotal"
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane :label="organisationsLabel">
            <div class="form-horizontal"> 
              <div class="row">  
                <div class="col-md-11">
                  <h4>Search Results</h4>
                  <i class="tim-icons icon-alert-circle-exc"></i> Click on a column title to sort (click twice to change between ascending and decending)
                  <data-tables :data="organisationsData" :page-size="1" :pagination-props="{ pageSizes: [10], hideOnSinglePage: true, background: true, layout: &quot;prev, pager, next, slot&quot; }" layout="table, pagination">
                    <el-table-column
                      v-for="column in organisationsColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                      sortable
                    />
                    <el-table-column :min-width="135" align="right" label="">
                      <div slot-scope="props">
                        <base-button
                          @click.native="viewOrganisation(props.row._id)"
                          class="btn-simple btn-link"
                          type="info"
                          size="sm"
                        >
                        View
                        </base-button>
                      </div>
                    </el-table-column>
                  </data-tables>
                  <!-- <div class="row">
                    <div class="col-form-label ml-auto">
                      {{ organisationsFrom + 1 }}-{{ organisationsTo }} of {{ organisationsTotal }}
                    </div>
                    <base-pagination
                      class="pagination-no-border"
                      v-model="organisationsPagination.currentPage"
                      :per-page="organisationsPagination.perPage"
                      :total="organisationsTotal"
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane :label="eventsLabel">
            <div class="form-horizontal"> 
              <div class="row">  
                <div class="col-md-11">
                  <h4>Search Results</h4>
                  <i class="tim-icons icon-alert-circle-exc"></i> Click on a column title to sort (click twice to change between ascending and decending)
                  <data-tables :data="eventsData" :page-size="1" :pagination-props="{ pageSizes: [10], hideOnSinglePage: true, background: true, layout: &quot;prev, pager, next, slot&quot; }" layout="table, pagination">
                    <el-table-column
                      v-for="column in eventsColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                      sortable
                    />
                    <el-table-column :min-width="135" align="right" label="">
                      <div slot-scope="props">
                        <base-button
                          @click.native="viewEvent(props.row._id)"
                          class="btn-simple btn-link"
                          type="info"
                          size="sm"
                        >
                        View
                        </base-button>
                      </div>
                    </el-table-column>
                  </data-tables>
                  <!-- <div class="row">
                    <div class="col-form-label ml-auto">
                      {{ eventsFrom + 1 }}-{{ eventsTo }} of {{ eventsTotal }}
                    </div>
                    <base-pagination
                      class="pagination-no-border"
                      v-model="eventsPagination.currentPage"
                      :per-page="eventsPagination.perPage"
                      :total="eventsTotal"
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane :label="tribesLabel">
            <div class="form-horizontal"> 
              <div class="row">  
                <div class="col-md-11">
                  <h4>Search Results</h4>
                  <i class="tim-icons icon-alert-circle-exc"></i> Click on a column title to sort (click twice to change between ascending and decending)
                  <data-tables :data="tribesData" :page-size="1" :pagination-props="{ pageSizes: [10], hideOnSinglePage: true, background: true, layout: &quot;prev, pager, next, slot&quot; }" layout="table, pagination">
                    <el-table-column
                      v-for="column in tribesColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                      sortable
                    />
                    <el-table-column :min-width="135" align="right" label="">
                      <div slot-scope="props">
                        <base-button
                          @click.native="viewTribe(props.row._id)"
                          class="btn-simple btn-link"
                          type="info"
                          size="sm"
                        >
                        View
                        </base-button>
                      </div>
                    </el-table-column>
                  </data-tables>
                  <!-- <div class="row">
                    <div class="col-form-label ml-auto">
                      {{ tribesFrom + 1 }}-{{ tribesTo }} of {{ tribesTotal }}
                    </div>
                    <base-pagination
                      class="pagination-no-border"
                      v-model="tribesPagination.currentPage"
                      :per-page="tribesPagination.perPage"
                      :total="tribesTotal"
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane :label="specificLocationsLabel">
            <div class="form-horizontal"> 
              <div class="row">  
                <div class="col-md-11">
                  <h4>Search Results</h4>
                  <i class="tim-icons icon-alert-circle-exc"></i> Click on a column title to sort (click twice to change between ascending and decending)
                  <data-tables :data="specificLocationsData" :page-size="1" :pagination-props="{ pageSizes: [10], hideOnSinglePage: true, background: true, layout: &quot;prev, pager, next, slot&quot; }" layout="table, pagination">
                    <el-table-column
                      v-for="column in specificLocationsColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                      sortable
                    />
                    <el-table-column :min-width="135" align="right" label="">
                      <div slot-scope="props">
                        <base-button
                          @click.native="viewSpecificLocation(props.row._id)"
                          class="btn-simple btn-link"
                          type="info"
                          size="sm"
                        >
                        View
                        </base-button>
                      </div>
                    </el-table-column>
                  </data-tables>
                  <!-- <div class="row">
                    <div class="col-form-label ml-auto">
                      {{ specificLocationsFrom + 1 }}-{{ specificLocationsTo }} of {{ specificLocationsTotal }}
                    </div>
                    <base-pagination
                      class="pagination-no-border"
                      v-model="specificLocationsPagination.currentPage"
                      :per-page="specificLocationsPagination.perPage"
                      :total="specificLocationsTotal"
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane :label="sourcesLabel">
            <div class="form-horizontal"> 
              <div class="row">  
                <div class="col-md-11">
                  <h4>Search Results</h4>
                  <i class="tim-icons icon-alert-circle-exc"></i> Click on a column title to sort (click twice to change between ascending and decending)
                  <data-tables :data="sourcesData" :page-size="1" :pagination-props="{ pageSizes: [10], hideOnSinglePage: true, background: true, layout: &quot;prev, pager, next, slot&quot; }" layout="table, pagination">
                    <el-table-column
                      v-for="column in sourcesColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                      sortable
                    />
                    <el-table-column :min-width="135" align="right" label="">
                      <div slot-scope="props">
                        <base-button
                          @click.native="viewSource(props.row._id)"
                          class="btn-simple btn-link"
                          type="info"
                          size="sm"
                        >
                        View
                        </base-button>
                      </div>
                    </el-table-column>
                  </data-tables>
                  <!-- <div class="row">
                    <div class="col-form-label ml-auto">
                      {{ sourcesFrom + 1 }}-{{ sourcesTo }} of {{ sourcesTotal }}
                    </div>
                    <base-pagination
                      class="pagination-no-border"
                      v-model="sourcesPagination.currentPage"
                      :per-page="sourcesPagination.perPage"
                      :total="sourcesTotal"
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </tab-pane>
        </tabs>
      </card>
    </div>
  </div>
</template>

<script>
import { TabPane, Tabs, BasePagination } from 'src/components';
import { Table, TableColumn, Select, Option, Button, Pagination } from 'element-ui';
import inputCard from 'src/components/Cards/inputCard';
import StatsCard from 'src/components/Cards/StatsCard';
import swal from 'sweetalert2';
import POIsService from '@/services/POIsService'
import ReportsService from '@/services/ReportsService'
import SoulsService from '@/services/SoulsService'
import OrganisationsService from '@/services/OrganisationsService'
import EventsService from '@/services/EventsService'
import SourcesService from '@/services/SourcesService'
import TribesService from '@/services/TribesService'
import SearchService from '@/services/SearchService'
import Card from 'src/components/Cards/Card.vue';
import { Loading } from 'element-ui';
import { Modal, BaseAlert } from 'src/components'
import { DataTables, DataTablesServer } from 'vue-data-tables'

export default {
  name: 'Search',
  components: {
    TabPane,
    Tabs,
    Modal,
    BaseAlert,
    inputCard,
    StatsCard,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
    DataTables,
  },
  // computed: {
  //   reports() {
  //     return this.reportsData.slice(this.reportsFrom, this.reportsTo);
  //   },
  //   reportsTo() {
  //     let highBound = this.reportsFrom + this.reportsPagination.perPage;
  //     if (this.reportsTotal < highBound) {
  //       highBound = this.reportsTotal;
  //     }
  //     return highBound;
  //   },
  //   reportsFrom() {
  //     return this.reportsPagination.perPage * (this.reportsPagination.currentPage - 1);
  //   },
  //   reportsTotal() {
  //     return this.reportsData.length;
  //   },
  //   souls() {
  //     return this.soulsData.slice(this.soulsFrom, this.soulsTo);
  //   },
  //   soulsTo() {
  //     let highBound = this.soulsFrom + this.soulsPagination.perPage;
  //     if (this.soulsTotal < highBound) {
  //       highBound = this.soulsTotal;
  //     }
  //     return highBound;
  //   },
  //   soulsFrom() {
  //     return this.soulsPagination.perPage * (this.soulsPagination.currentPage - 1);
  //   },
  //   soulsTotal() {
  //     return this.soulsData.length;
  //   },
  //   organisations() {
  //     return this.organisationsData.slice(this.organisationsFrom, this.organisationsTo);
  //   },
  //   organisationsTo() {
  //     let highBound = this.organisationsFrom + this.organisationsPagination.perPage;
  //     if (this.organisationsTotal < highBound) {
  //       highBound = this.organisationsTotal;
  //     }
  //     return highBound;
  //   },
  //   organisationsFrom() {
  //     return this.organisationsPagination.perPage * (this.organisationsPagination.currentPage - 1);
  //   },
  //   organisationsTotal() {
  //     return this.organisationsData.length;
  //   },
  //   events() {
  //     return this.eventsData.slice(this.eventsFrom, this.eventsTo);
  //   },
  //   eventsTo() {
  //     let highBound = this.eventsFrom + this.eventsPagination.perPage;
  //     if (this.eventsTotal < highBound) {
  //       highBound = this.eventsTotal;
  //     }
  //     return highBound;
  //   },
  //   eventsFrom() {
  //     return this.eventsPagination.perPage * (this.eventsPagination.currentPage - 1);
  //   },
  //   eventsTotal() {
  //     return this.eventsData.length;
  //   },
  //   tribes() {
  //     return this.tribesData.slice(this.tribesFrom, this.tribesTo);
  //   },
  //   tribesTo() {
  //     let highBound = this.tribesFrom + this.tribesPagination.perPage;
  //     if (this.tribesTotal < highBound) {
  //       highBound = this.tribesTotal;
  //     }
  //     return highBound;
  //   },
  //   tribesFrom() {
  //     return this.tribesPagination.perPage * (this.tribesPagination.currentPage - 1);
  //   },
  //   tribesTotal() {
  //     return this.tribesData.length;
  //   },
  //   specificLocations() {
  //     return this.specificLocationsData.slice(this.specificLocationsFrom, this.specificLocationsTo);
  //   },
  //   specificLocationsTo() {
  //     let highBound = this.specificLocationsFrom + this.specificLocationsPagination.perPage;
  //     if (this.specificLocationsTotal < highBound) {
  //       highBound = this.specificLocationsTotal;
  //     }
  //     return highBound;
  //   },
  //   specificLocationsFrom() {
  //     return this.specificLocationsPagination.perPage * (this.specificLocationsPagination.currentPage - 1);
  //   },
  //   specificLocationsTotal() {
  //     return this.specificLocationsData.length;
  //   },
  //   sources() {
  //     return this.sourcesData.slice(this.sourcesFrom, this.sourcesTo);
  //   },
  //   sourcesTo() {
  //     let highBound = this.sourcesFrom + this.sourcesPagination.perPage;
  //     if (this.sourcesTotal < highBound) {
  //       highBound = this.sourcesTotal;
  //     }
  //     return highBound;
  //   },
  //   sourcesFrom() {
  //     return this.sourcesPagination.perPage * (this.sourcesPagination.currentPage - 1);
  //   },
  //   sourcesTotal() {
  //     return this.sourcesData.length;
  //   },
  // },
  data() {
    return {
      searchLoading: false,
      modalCountry: false,
      searchBarMust: '',
      searchBarShould: '',
      searchBarMustNot: '',
      searchButton: '',
      test:'',
      newCountries: [],
      countries: {},
      newCountry: window.GLOBALCOUNTRY,
      country: {
        title: window.GLOBALCOUNTRY, 
        subTitle: 'My Country', 
        type: 'warning', 
        icon:'tim-icons icon-world', 
      },
      reportsPagination: { perPage: 10,currentPage: 1,total: 0 },
      soulsPagination: { perPage: 25,currentPage: 1,total: 0 },
      organisationsPagination: { perPage: 25,currentPage: 1,total: 0 },
      eventsPagination: { perPage: 25,currentPage: 1,total: 0 },
      tribesPagination: { perPage: 25,currentPage: 1,total: 0 },
      specificLocationsPagination: { perPage: 25,currentPage: 1,total: 0 },
      sourcesPagination: { perPage: 25,currentPage: 1,total: 0 },
      soulsData:[],
      selectFuzzy: '',
      fuzzyOptions: [
          { value: 'high', label: 'High' },
          { value: 'low', label: 'Low (Default)' },
        ],
      reportsData:[],
      organisationsData:[],
      eventsData:[],
      tribesData:[],
      specificLocationsData:[],
      sourcesData:[],
      specificLocationsLabel: 'Specific Locations',
      sourcesLabel: 'Sources',
      soulsLabel: 'People',
      organisationsLabel: 'Organisations',
      tribesLabel: 'Tribes',
      eventsLabel: 'Events',
      reportsLabel: 'Reports',
      reportsColumns: [
        { prop: 'name', label: 'Name', minWidth: 200},
        { prop: 'highlights', label: 'Text', minWidth: 400},
      ],
      soulsColumns: [
        { prop: 'nameEnglish', label: 'English Name', minWidth: 200},
        { prop: 'nameArabic', label: 'Arabic Name', minWidth: 200},
        { prop: 'aka[0].englishAKA', label: 'Also Known As', minWidth: 200},
        { prop: 'position[0].positionNameEnglish', label: 'Position', minWidth: 200},
      ],
      organisationsColumns: [
        { prop: 'org_en', label: 'English Name', minWidth: 200},
        { prop: 'org_ar', label: 'Arabic Name', minWidth: 200},
      ],
      eventsColumns: [
        { prop: 'date', label: 'Date', minWidth: 100},
        { prop: 'type', label: 'Type', minWidth: 100},
        { prop: 'title', label: 'Title', minWidth: 500},
        { prop: 'country', label: 'Location', minWidth: 100},
      ],
      tribesColumns: [
        { prop: 'tribe_en', label: 'Tribe (English)', minWidth: 200},
        { prop: 'tribe_ar', label: 'Tribe (Arabic)', minWidth: 200},
      ],
      specificLocationsColumns: [
        { prop: 'name_en', label: 'Name (English)', minWidth: 200},
        { prop: 'name_ar', label: 'Name (Arabic)', minWidth: 200},
        { prop: 'latitude', label: 'Latitude', minWidth: 200},
        { prop: 'longitude', label: 'Longitude', minWidth: 200},
      ],
      sourcesColumns: [
        { prop: 'name', label: 'Name', minWidth: 200},
        { prop: 'type', label: 'Type', minWidth: 200},
      ],
      searchBarCards: [
        {
          title: '',
          subTitle: 'Hello',
          type: 'info',
          icon: 'tim-icons icon-zoom-split',
          footer: '<i class="tim-icons icon-alert-circle-exc"></i> For advanced search use "+" for AND, "|" for OR, "-" for NOT'
        },
      ],
    };
  },
  mounted(){
    this.onLoadMethod()
  },
  // activated() {
  //   this.onLoadMethod()
  // },
  methods:{
    async onLoadMethod() {
      let loading = Loading.service()
      this.getNations()
      document.getElementById("advancedSearchMustNot").style.display = "none"
      document.getElementById("advancedSearchShould").style.display = "none"
      document.getElementById("fuzzy").style.display = "none"
      this.country.title = window.GLOBALCOUNTRY
      loading.close()
    },
    changeCountry() {
      this.country.title = this.newCountry
      this.modalCountry = false
      // swal({
      //     title: 'Change Country',
      //     text: 'Select a country below',
      //     input: 'select',
      //     inputOptions: this.countries,
      //     showCancelButton: true,
      //     confirmButtonText: 'Save',
      //     cancelButtonText: 'Cancel',
      //     confirmButtonClass: 'btn btn-success btn-fill',
      //     cancelButtonClass: 'btn btn-info btn-fill',
      //     buttonsStyling: false,
      //     allowOutsideClick: false,
      //     inputValue: 'this',
      //     inputValidator: (value) => {
      //       this.country.title = value
      //     }
      //   })
    },
    changeSearch() {
      var mustNot = document.getElementById("advancedSearchMustNot")
        if (mustNot.style.display === "none") {
          mustNot.style.display = "block"
        } else {
          mustNot.style.display = "none"
        }
      var should = document.getElementById("advancedSearchShould")
        if (should.style.display === "none") {
          should.style.display = "block"
        } else {
          should.style.display = "none"
        }
      var fuzzy = document.getElementById("fuzzy")
        if (fuzzy.style.display === "none") {
          fuzzy.style.display = "block"
        } else {
          fuzzy.style.display = "none"
        }
    },
    async getSpecificLocations() {
      this.specificLocationsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let specificLocations = await SearchService.fetchPOIs().catch((e) => {loading.close().then(this.notifyFailure())})
      this.specificLocationsData = specificLocations.data
      this.specificLocationsLabel = "Specific Locations (" + specificLocations.data.length + ")"
    },
    async searchSpecificLocations(must, mustNot, should, fuzziness) {
      this.specificLocationsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let specificLocations = await SearchService.searchMainSpecificLocations({must: must, mustNot: mustNot, should: should, fuzziness: fuzziness}).catch((e) => {this.notifyFailure()})
      this.specificLocationsData = specificLocations.data
      this.specificLocationsLabel = "Specific Locations (" + specificLocations.data.length + ")"
    },
    async getSources(countryToSearch) {
      this.sourcesLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let sources = await SearchService.fetchSources({country: countryToSearch}).catch((e) => {this.notifyFailure()})
      this.sourcesData = sources.data
      this.sourcesLabel = "Sources (" + sources.data.length + ")"
    },
    async getReports(countryToSearch) {
      this.reportsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>//<span class='tim-icons icon-atom'></span>
      let reports = await SearchService.fetchReports().catch((e) => {loading.close().then(this.notifyFailure())})
      this.reportsData = reports.data
      this.reportsLabel = "Reports (" + reports.data.length + ")"
    },
    async searchReports(must, mustNot, should, fuzziness) {
      this.reportsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let reports = await SearchService.searchReports({must: must, mustNot: mustNot, should: should, fuzziness: fuzziness}).catch((e) => {this.notifyFailure()})
      this.reportsLabel = "Reports (" + reports.data.length + ")"
      reports.data.forEach(report => {
        let totalHightlights = ""
        report.highlights.forEach(highlight => {
          let fullString = ""
          highlight.texts.forEach(text => {
            if (text.type == "hit") {
              fullString = fullString + "<mark>" + text.value + "</mark>"
            } else {
              fullString = fullString + text.value
            }
          })
          totalHightlights = totalHightlights + fullString
        })
        let fullRecord = {_id: report._id, name: report.name, highlights: totalHightlights}
        this.reportsData.push(fullRecord)
      })
    },
    async searchSouls(country, must, mustNot, should, fuzziness) {
      this.soulsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let souls = await SearchService.searchSouls({country: country, must: must, mustNot: mustNot, should: should, fuzziness: fuzziness}).catch((e) => {loading.close().then(this.notifyFailure())})
      this.soulsData = souls.data
      this.soulsLabel = "People (" + souls.data.length + ")"
    },
    async searchOrganisations(country, must, mustNot, should, fuzziness) {
      this.organisationsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let organisations = await SearchService.searchOrganisations({country: country, must: must, mustNot: mustNot, should: should, fuzziness: fuzziness}).catch((e) => {loading.close().then(this.notifyFailure())})
      this.organisationsData = organisations.data
      this.organisationsLabel = "Organisations (" + organisations.data.length + ")"
    },
    async searchEvents(country, must, mustNot, should, fuzziness) {
      this.eventsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let events = await SearchService.searchEvents({country: country, must: must, mustNot: mustNot, should: should, fuzziness: fuzziness}).catch((e) => {loading.close().then(this.notifyFailure())})
      events.data.forEach(event => {
        event.date = event.date.substring(0, 10)
      });
      this.eventsData = events.data
      this.eventsLabel = "Events (" + events.data.length + ")"
    },
    async getTribes(countryToSearch) {
      this.tribesLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let tribes = await SearchService.fetchTribes({country : countryToSearch}).catch((e) => {loading.close().then(this.notifyFailure())})
      this.tribesData = tribes.data
      this.tribesLabel = "Tribes (" + tribes.data.length + ")"
    },
    async getEvents(countryToSearch) {
      this.eventsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let events = await SearchService.fetchEvents({country : countryToSearch}).catch((e) => {loading.close().then(this.notifyFailure())})
      events.data.forEach(event => {
        event.date = event.date.substring(0, 10)
      });
      this.eventsData = events.data
      this.eventsLabel = "Events (" + events.data.length + ")"
    },
    async getSouls(countryToSearch) {
      this.soulsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let souls = await SearchService.fetchSouls({country: countryToSearch}).catch((e) => {loading.close().then(this.notifyFailure())})
      this.soulsData = souls.data
      this.soulsLabel = "People (" + souls.data.length + ")"
    },
    async getOrganisations(countryToSearch) {
      this.organisationsLabel = <img src="/img/OptioGifLoader.gif" width="20" alt="loading"/>
      let organisations = await SearchService.fetchOrganisations({country: countryToSearch}).catch((e) => {loading.close().then(this.notifyFailure())})
      this.organisationsData = organisations.data
      this.organisationsLabel = "Organisations (" + organisations.data.length + ")"
    },
    async getNations() {
      this.newCountries = window.GLOBALNATIONS
      this.newCountries.forEach(country => {
        const id = country.search
        this.countries[id] = country.search
      });
    },
    async startSearch() {
      // hide the advanced fields if they were used
      //document.getElementById("advancedSearchMustNot").style.display = "none"
      //document.getElementById("advancedSearchShould").style.display = "none"
      //document.getElementById("fuzzy").style.display = "none"
      // need to clear all the results in case not the first search
      this.searchLoading = true
      this.soulsData=[]
      this.reportsData=[]
      this.organisationsData=[]
      this.eventsData=[]
      this.tribesData=[]
      this.specificLocationsData=[]
      this.sourcesData=[]
      // // and clear all the labels
      // this.specificLocationsLabel = 'Specific Locations'
      // this.sourcesLabel = 'Sources'
      // this.soulsLabel = 'People'
      // this.organisationsLabel = 'Organisations'
      // this.tribesLabel = 'Tribes'
      // this.eventsLabel = 'Events'
      // this.reportsLabel = 'Reports'
      // need to check what fields are in use and replace those that aren't with 'NOTinUSEforSEARCH' cause we have a pass a value
      if (this.searchBarMustNot == ""){
        this.searchBarMustNot = "NOTinUSEforSEARCH"
      }
      if (this.searchBarShould == ""){
        this.searchBarShould = "NOTinUSEforSEARCH"
      }
      if (this.selectFuzzy == ""){
        this.selectFuzzy = "low"
      }
      // depending on whether the search bar is used or not there will be two different searches
      // if it is not used then just get records:
      if (this.searchBarMust == "" || this.searchBarMust == null) {
        // Full return search by Country, handle all countries in lambda function
        this.getSources(this.country.title)
        this.getTribes(this.country.title)
        this.getEvents(this.country.title)
        this.getSouls(this.country.title)
        this.getOrganisations(this.country.title)
        this.getReports()
        this.getSpecificLocations()
      } else {
        // Specifc search based on keywords and country
        this.searchReports(encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
        this.searchSouls(this.country.title, encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
        this.searchOrganisations(this.country.title, encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
        this.searchEvents(this.country.title, encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
        this.searchSpecificLocations(encodeURI(this.searchBarMust), encodeURI(this.searchBarMustNot), encodeURI(this.searchBarShould), this.selectFuzzy)
        this.getSources(this.country.title)
        this.getTribes(this.country.title)
      }
      //and if we changed these values lets change em back
      if (this.searchBarMustNot == "NOTinUSEforSEARCH"){
        this.searchBarMustNot = ""
      }
      if (this.searchBarShould == "NOTinUSEforSEARCH"){
        this.searchBarShould = ""
      }
      this.searchLoading = false
    },
    async viewSoul(soulID) {
      this.$router.push({ name: 'View Person', params: { id: soulID}})
    },
    async viewEvent(eventID) {
      this.$router.push({ name: 'View Event', params: { id: eventID}})
    },
    async viewOrganisation(organisationID) {
      this.$router.push({ name: 'View Organisation', params: { id: organisationID}})
    },
    async viewSpecificLocation(specificLocationID) {
      this.$router.push({ name: 'View Specific Location', params: { id: specificLocationID}})
    },
    async viewReport(reportID) {
      this.$router.push({ name: 'View Report', params: { id: reportID}})
    },
    async viewTribe(tribeID) {
      this.$router.push({ name: 'View Tribe', params: { id: tribeID}})
    },
    async viewSource(sourceID) {
      this.$router.push({ path: `/viewSource/${sourceID}`})
    },
    notifyFailure() {
      this.$notify({
        message:
          'Action Failed',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "danger"
      });
    },
    
    
  }
};
</script>

<style lang="scss">
.pagination-select, .search-input {
  width: 200px;}
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: rgb(175, 32, 32);
  border: 1px solid #ddd;
}
</style>