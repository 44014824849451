<template>
  <div class="row justify-content-center">
    <div class="col-md-6 justify-content-center">
      <h5 class="info-text">
        Add more details
      </h5>
    </div>

    <div class="col-md-12 justify-content-center">
      <div class="form-horizontal justify-content-center"> 
        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Ethnicity</label> 

          <div class="col-md-6">
            <base-input
              name="ethnicity"
              id="ethnicity"
              placeholder=""
              v-model="ethnicity"
            />
          </div>
        </div>

      <div class="row justify-content-center">
        <label class="col-md-2 col-form-label">Confederation - English</label> 
        <div class="col-md-4">
          <base-input
            name="confederationEnglish"
            id="confederationEnglish"
            placeholder=""
            v-model="confederationEnglish"
          />
        </div>
        <label class="col-md-2 col-form-label">Confederation - Arabic</label> 
        <div class="col-md-4">
          <base-input
            name="confederationArabic"
            id="confederationArabic"
            placeholder=""
            v-model="confederationArabic"
          />
        </div>
      </div>

      <div class="row justify-content-center">
        <label class="col-md-2 col-form-label">Sub Tribe 1 - English</label> 
        <div class="col-md-4">
          <base-input
            name="subTribeOneEnglish"
            id="subTribeOneEnglish"
            placeholder=""
            v-model="subTribeOneEnglish"
          />
        </div>
        <label class="col-md-2 col-form-label">Sub Tribe 1 - Arabic</label> 
        <div class="col-md-4">
          <base-input
            name="subTribeOneArabic"
            id="subTribeOneArabic"
            placeholder=""
            v-model="subTribeOneArabic"
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <label class="col-md-2 col-form-label">Sub Tribe 2 - English</label> 
        <div class="col-md-4">
          <base-input
            name="subTribeTwoEnglish"
            id="subTribeTwoEnglish"
            placeholder=""
            v-model="subTribeTwoEnglish"
          />
        </div>
        <label class="col-md-2 col-form-label">Sub Tribe 2 - Arabic</label> 
        <div class="col-md-4">
          <base-input
            name="subTribeTwoArabic"
            id="subTribeTwoArabic"
            placeholder=""
            v-model="subTribeTwoArabic"
          />
        </div>
      </div>
            <div class="row justify-content-center">
        <label class="col-md-2 col-form-label">Sub Tribe 3 - English</label> 
        <div class="col-md-4">
          <base-input
            name="subTribeThreeEnglish"
            id="subTribeThreeEnglish"
            placeholder=""
            v-model="subTribeThreeEnglish"
          />
        </div>
        <label class="col-md-2 col-form-label">Sub Tribe 3 - Arabic</label> 
        <div class="col-md-4">
          <base-input
            name="subTribeThreeArabic"
            id="subTribeThreeArabic"
            placeholder=""
            v-model="subTribeThreeArabic"
          />
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ethnicity: '',
      confederationEnglish: '',
      confederationArabic: '',
      subTribeOneEnglish: '',
      subTribeOneArabic: '',
      subTribeTwoEnglish: '',
      subTribeTwoArabic: '',
      subTribeThreeEnglish: '',
      subTribeThreeArabic: '',
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$emit("child-ethnicity", this.ethnicity)
      this.$emit("child-confederationenglish", this.confederationEnglish)
      this.$emit("child-confederationarabic", this.confederationArabic)
      this.$emit("child-subtribeoneenglish", this.subTribeOneEnglish)
      this.$emit("child-subtribeonearabic", this.subTribeOneArabic)
      this.$emit("child-subtribetwoenglish", this.subTribeTwoEnglish)
      this.$emit("child-subtribetwoarabic", this.subTribeTwoArabic)
      this.$emit("child-subtribethreeenglish", this.subTribeThreeEnglish)
      this.$emit("child-subtribethreearabic", this.subTribeThreeArabic)
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model)
        return res;
      });
    }
  },
};
</script>
<style></style>
