// This Service is CRU for events (fetch for all)

import Api from '@/services/Api'

const options = {
  headers: {'Authorization': window.GLOBALIDTOKEN}
}

export default {
  addEvent (params) {
    return Api().post('event/add'+ '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  updateEvent (params) {
    return Api().put('event/update/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getEvent (params) {
    return Api().get('event/get/' + params.id + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  createNote (params) {
    return Api().put('event/create/note/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  replaceNote (params) {
    return Api().put('event/replace/note/' + params.id + '/' + params.sid + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  updateEventImage (params) {
    return Api().put('event/create/image/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
}