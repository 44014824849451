<template>
  <div class="row justify-content-center">
    <div class="col-md-6 justify-content-center">
      <h5 class="info-text">
        Add more details
      </h5>
    </div>

    <div class="col-md-12 justify-content-center">
      <div class="form-horizontal justify-content-center"> 
        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Actor Group</label> 
          <div class="col-md-6">
            <base-input>
              <el-select
                name="actorGroup"
                id="actorGroup"
                class="select-info"
                size="medium"
                placeholder=""
                filterable
                v-model="selects.actorGroup"
              >
                <el-option
                  v-for="option in actorGroups"
                  class="select-info"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import { TimeSelect, DatePicker, Select, Option } from 'element-ui'
import SearchService from '@/services/SearchService'
import POIsService from '@/services/POIsService'

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props:['propCountry'],
  data() {
    return {
      selects: {
        actorGroup: null,
      },
      actorGroups: [
          { value: 'Civilian', label: 'Civilian' },
          { value: 'NGO', label: 'NGO' },
          { value: 'Commercial Entities', label: 'Commercial Entities' },
          { value: 'State Actors (Non-Mil / Sy)', label: 'State Actors (Non-Mil / Sy)' },
          { value: 'State Forces (Mil / Sy)', label: 'State Forces (Mil / Sy)' },
          { value: 'Foreign Forces', label: 'Foreign Forces' },
          { value: 'VEO – AQ Aligned', label: 'VEO – AQ Aligned' },
          { value: 'VEO – IS Aligned', label: 'VEO – IS Aligned' },
          { value: 'Armed groups (unidentified / aligned)', label: 'Armed groups (unidentified / aligned)' },
          { value: 'UN', label: 'UN' },
          { value: 'Mercenary groups', label: 'Mercenary groups' },
          { value: 'Other/Unknown', label: 'Other/Unknown' },
          { value: 'Hadi government', label: 'Hadi government' },
          { value: 'Hadi-affiliated Yemeni forces', label: 'Hadi-affiliated Yemeni forces' },
          { value: 'SLC', label: 'SLC' },
          { value: 'Houthi forces', label: 'Houthi forces' },
          { value: 'IRGC/Iranian', label: 'IRGC/Iranian' },
          { value: 'GNU (non-mil)', label: 'GNU (non-mil)' },
          { value: 'GNU military and security forces', label: 'GNU military and security forces' },
          { value: 'LNA military and security forces', label: 'LNA military and security forces' },
          { value: 'LNA (non-mil)', label: 'LNA (non-mil)' },
          { value: 'Signatory armed groups', label: 'Signatory armed groups' },
          { value: 'Polisario', label: 'Polisario' },
        ],
    };
  },
  // watch: {
  //   propCountry:function(value){
  //     this.getGeneralLocations(value)
  //   }
  // },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$emit("child-generallocation", this.selects.generalLocation)
      this.$emit("child-actorgroup", this.selects.actorGroup)
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model)
        return res;
      });
    },
    async getGeneralLocations(countrySelected) {
      const activeGeneralLocations = await SearchService.fetchLocations({country: countrySelected})
      this.generalLocations = activeGeneralLocations.data
    },
  },
};
</script>
<style></style>
