<template>
  <div class="row">
    <div class="col-lg-4 col-md-4" >
      <stats-card
        :title='specificLocation.title'
        :sub-title='specificLocation.subTitle'
        :type="specificLocation.type"
        :icon="specificLocation.icon">
        <div slot="footer" class="row">
          <div class="col-md-10">
            <a class="nav-link" @click="changeName()">
              <i class="tim-icons icon-refresh-02" ></i> {{titleSwap}}
            </a>
          </div>
          <div class="col-md-2 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
            <div v-if="$can('update', 'mandatory')">
              <a class="dropdown-item" @click="editEnglishName">Edit English Name</a>
            </div>
              <a class="dropdown-item" @click="editArabicName">Edit Arabic Name</a>
              <div v-if="$can('delete', 'all')">
              <a class="dropdown-item" @click="deleteRecord">Delete Specific Location</a>
              </div>
            
            </base-dropdown>
          </div>
        </div>
      </stats-card>
    </div>

    <div class="col-lg-4 col-md-4">
      <updated-card
        :title='specificLocationDate.title'
        :sub-title='specificLocationDate.subTitle'
        :second-sub-title='specificLocationDate.secondSubTitle'
        :second-title='specificLocationDate.secondTitle'
        :type="specificLocationDate.type"
        :icon="specificLocationDate.icon">
      </updated-card>
    </div>

    <div class="col-md-8">
      <card >
        <div class="row">
          <div class="col-md-11">
            <h5>Details</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto" v-if="$can('update', 'mandatory')">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
              <h6 class="dropdown-header">Details Menu</h6>
              <a class="dropdown-item" href="#" @click="editDetails">Edit Details</a>
              <a class="dropdown-item" href="#" @click="saveDetails">Save Details</a>
            </base-dropdown>
          </div>
        </div>
  
        <div class="form-horizontal"> 
          <div class="row">  
            <label class="col-md-4 col-form-label">Latitude</label>
            <div class="col-md-6">
              <base-input
                name="latitude"
                id="latitude"
                placeholder=""
                v-model="latitude"
                :readonly="isReadOnly"
              />
            </div>
          </div>

          <div class="row">   
            <label class="col-md-4 col-form-label">Longitude</label> 
            <div class="col-md-6">
              <base-input
                name="longitude"
                placeholder=""
                v-model="longitude"
                :readonly="isReadOnly"
              />
            </div>
          </div>
        </div>
      </card>
    </div>

    <modal :show.sync="modalNote" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Note</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <textarea class="form-control" rows=4 v-model="notes"></textarea>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalNote = false">Cancel</base-button>
            <base-button type="success" @click.native="updateNotes()">Save</base-button>
          </div>
        </modal>

    <div class="col-md-8">
      <card >
        <div class="row">
          <div class="col-md-11">
            <h5>Notes</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
              <h6 class="dropdown-header">Details Menu</h6>
              <a class="dropdown-item" href="#" @click="modalNote = true">Edit Note</a>
            </base-dropdown>
          </div>
        </div>
  
        <div class="form-horizontal"> 
          <div class="row">  
            <label class="col-md-4 col-form-label">Notes</label>
            <div class="col-md-8">
              <base-input
              >
              <textarea autosize rows=5 class="form-control" aria-label="With textarea" readonly v-model="notes"></textarea>
              </base-input>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import StatsCard from 'src/components/Cards/StatsCard';
import UpdatedCard from 'src/components/Cards/updatedCard';
import swal from 'sweetalert2';
import POIsService from '@/services/POIsService'
import { Modal, BaseAlert } from 'src/components';
import { Loading } from 'element-ui';
import DeleteService from '@/services/DeleteService'

export default {
  components: {
    StatsCard,
    UpdatedCard,
    Modal,
    BaseAlert,
  },
  data() {
    return {
      modalNote: false,
      titleSwap: 'Arabic Name',
      latitude: '',
      longitude: '',
      isReadOnly: true,
      isReadOnlyNotes: true,
      englishName: '',
      arabicName: '',
      specificLocation: 
        {
          title: '',
          subTitle: 'English Name',
          type: 'primary',
          icon: 'tim-icons icon-paper',
        },
      specificLocationDate:
      {
          title: '',
          subTitle: 'Date Entered',
          secondTitle: '',
          secondSubTitle: 'Last Updated',
          type: 'warning',
          icon: 'tim-icons icon-time-alarm',
      },
      notes: '',
    }
  },
  mounted() {
    let loading = Loading.service()
    this.getSpecificLocation()
    loading.close()
  },
  // watch: {
  //   $route(to, from) {
  //     // react to route changes...
  //     let loading = Loading.service()
  //     this.getSpecificLocation()
  //     loading.close()
  //   }
  // },
  methods: {
    async getSpecificLocation () {
      const specificLocation = await POIsService.getPOI({
        id: this.$route.params.id
      })
      this.englishName = specificLocation.data.name_en
      this.arabicName = specificLocation.data.name_ar
      if (specificLocation.data.lastupdated != null) {
        this.specificLocationDate.secondTitle = new Date(specificLocation.data.lastupdated.substring(0, 10)).toDateString()
      }
      
      this.specificLocationDate.title = new Date(parseInt(specificLocation.data._id.substring(0, 8), 16) * 1000).toDateString()
      this.latitude = specificLocation.data.latitude
      this.longitude = specificLocation.data.longitude
      this.notes = specificLocation.data.notes
      if (this.titleSwap == 'Arabic Name') {
        this.specificLocation.title = specificLocation.data.name_en
      } else {
        this.specificLocation.title = specificLocation.data.name_ar
      }
    },
    
    changeName(){
      if (this.titleSwap == 'Arabic Name') {
        // change to arabic
        this.specificLocation.title = this.arabicName
        this.specificLocation.subTitle = "Arabic Name"
        this.titleSwap = "English Name"
      }
      else if (this.titleSwap == 'English Name') {
        // change to english
        this.specificLocation.title = this.englishName
        this.specificLocation.subTitle = "English Name"
        this.titleSwap = "Arabic Name"
      }
    },
    editDetails() {
      this.isReadOnly = false
    },
    editEnglishName() {
        swal({
          title: 'Edit Specific Location',
          text: 'Change English Name',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValue: this.englishName,
          inputValidator: (value) => {
            if (!value) {
              return 'You need to write something!'
            }
            if (value != this.englishName) {
              this.updateEnglishName(value)
            }
          }
        })
    },
    editArabicName() {
        swal({
          title: 'Edit Specific Location',
          text: 'Change Arabic Name',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValue: this.arabicName,
          inputValidator: (value) => {
            if (!value) {
              return 'You need to write something!'
            }
            if (value != this.arabicName) {
              this.updateArabicName(value)
            }
          }
        })
    },
    saveDetails() {
      this.updateDetails()
      this.isReadOnly = true
    },
    async updateEnglishName(newName) {
      let loading = Loading.service()
      let newSpecificLocation = await POIsService.updatePOI({
        id: this.$route.params.id,
        name_en: newName,
        lastupdated: new Date()
      })
      this.getSpecificLocation()
      loading.close()
      if (newSpecificLocation.status != 200) {
        this.notifyFailure()
      } else {
        this.notifySuccess()
      }
    },
    async updateArabicName(newName) {
      let loading = Loading.service()
      let newSpecificLocation = await POIsService.updatePOI({
        id: this.$route.params.id,
        name_ar: newName,
        lastupdated: new Date()
      })
      this.getSpecificLocation()
      loading.close()
      if (newSpecificLocation.status != 200) {
        this.notifyFailure()
      } else {
        this.notifySuccess()
      }
    },
    async updateDetails() {
      let loading = Loading.service()
      let newSpecificLocation = await POIsService.updatePOI({
        id: this.$route.params.id,
        latitude: this.latitude,
        longitude: this.longitude,
        lastupdated: new Date()
      })
      this.getSpecificLocation()
      loading.close()
      if (newSpecificLocation.status != 200) {
        this.notifyFailure()
      } else {
        this.notifySuccess()
      }
    },
    async updateNotes() {
      let loading = Loading.service()
      let newSpecificLocation = await POIsService.updatePOI({
        id: this.$route.params.id,
        notes: this.notes,
        lastupdated: new Date()
      })
      this.getSpecificLocation()
      this.modalNote = false
      loading.close()
      if (newSpecificLocation.status != 200) {
        this.notifyFailure()
      } else {
        this.notifySuccess()
      }
    },
    notifySuccess() {
      this.$notify({
        message:
          'Record Updated',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "info"
      });
    },
    notifyFailure() {
      this.$notify({
        message:
          'Update Failed',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "danger"
      });
    },
    deleteRecord() {
      swal({
          title: 'Delete Specific Location',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove this record from the database'
            }
            if (value == 'DELETE'){
              this.deletePOI()
            }
          }
        })
    },
    async deletePOI() {
      await DeleteService.deletePOI({
        id: this.$route.params.id,
      })
      this.$router.push({ name: 'Search'})
    },
  }
}
</script>
<style lang="scss"></style>
