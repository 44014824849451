<template><div>
  <div class="row">
    <div class="col-lg-4 col-md-3" >
      <stats-card
        :title='organisation.title'
        :sub-title='organisation.subTitle'
        :type="organisation.type"
        :icon="organisation.icon">
        <div slot="footer" class="row">
          <div class="col-md-10">
            <a class="nav-link" @click="changeName()">
              <i class="tim-icons icon-refresh-02" ></i> {{titleSwap}}
            </a>
          </div>
          <div class="col-md-2 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
            <div v-if="$can('update', 'mandatory')">
              <a class="dropdown-item" @click="modalEnglishName = true">Edit English Name</a>
            </div>
              <a class="dropdown-item" @click="modalArabicName = true">Edit Arabic Name</a>
              <div v-if="$can('delete', 'all')">
              <a class="dropdown-item" @click="deleteRecord">Delete Organisation</a>
              </div>
            
            </base-dropdown>
          </div>
        </div>
      </stats-card>
    </div>

    <modal :show.sync="modalEnglishName" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit English Name</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="englishName"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalEnglishName = false">Cancel</base-button>
            <base-button type="success" @click.native="updateEnglishName(englishName)">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalArabicName" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Arabic Name</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="arabicName"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalArabicName = false">Cancel</base-button>
            <base-button type="success" @click.native="updateArabicName(arabicName)">Save</base-button>
          </div>
        </modal>

    <div class="col-lg-3 col-md-3">
      <updated-card
        :title='organisationDateCard.title'
        :sub-title='organisationDateCard.subTitle'
        :second-sub-title='organisationDateCard.secondSubTitle'
        :second-title='organisationDateCard.secondTitle'
        :type="organisationDateCard.type"
        :icon="organisationDateCard.icon">
      </updated-card>
    </div>
    
    <div class="col-md-2">
      <el-carousel :interval="10000" height="150px" indicator-position="none">
        <el-carousel-item v-for="image in images" :key="image._id">
          <img :src="image.image" style="max-height: 150px; width: auto;">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="col-md-1">
    </div>

    <div class="col-md-2">
      <image-upload @child-change="imageToUpload" @child-save="saveImage" select-text="Add Picture" />
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <card>
        <div class="row">
          <div class="col-md-11">
            <h5>Details</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
              <h6 class="dropdown-header">Details Menu</h6>
              <div v-if="$can('update', 'mandatory')">
              <a class="dropdown-item" @click="modalBasedIn = true">Add Based In</a>
              </div>
              <a class="dropdown-item" @click="modalSocialMedia = true">Add Social Media</a>
              <a class="dropdown-item" @click="modalActorGroup = true">Edit Actor Group</a>
              <a class="dropdown-item" @click="modalIdeology = true">Edit Ideology</a>
            </base-dropdown>
          </div>
        </div>

        <modal :show.sync="modalBasedIn" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Add Based In</h4>
          <p>
            Enter location below and click search
          </p>
<br>
              <div class="row">
            <div class="col-md-9">
              <base-input v-model="searchBarGeneralLocation"/>
            </div> 
            <div class="col-md-3">
              <base-button size="sm" type="info" @click.native="searchGeneralLocations()">Search</base-button>
            </div>
            <div class="col-md-12">
              <el-table :data="searchGeneralLocationData" height="350">
                <el-table-column
                  v-for="column in searchGeneralLocationColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="addGeneralLocation(props.row.search)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-link-72"></i>
                  </base-button>
                </div>
              </el-table-column>
              </el-table>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalBasedIn = false">Cancel</base-button>
          </div>
        </modal>

        <modal :show.sync="modalSocialMedia" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Add Social Media</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="newSocialMedia"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalSocialMedia = false">Cancel</base-button>
            <base-button type="success" @click.native="createSocialMedia()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalActorGroup" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Actor Group</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input>
              <el-select
                name="actorGroup"
                id="actorGroup"
                class="select-info"
                size="medium"
                placeholder=""
                filterable
                v-model="organisation.actorGroup"
              >
                <el-option
                  v-for="option in actorGroups"
                  class="select-info"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                >
                </el-option>
              </el-select>
            </base-input>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalActorGroup = false">Cancel</base-button>
            <base-button type="success" @click.native="updateOrganisationDetails()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalIdeology" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Ideology</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="organisation.ideology"/>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalIdeology = false">Cancel</base-button>
            <base-button type="success" @click.native="updateOrganisationDetails()">Save</base-button>
          </div>
        </modal>
  
        <div class="form-horizontal justify-content-center"> 
          <div class="row">
            <div class="col-md-6">
              <el-table :data="basedInData">
                <div slot="empty">No Based In</div>
                <el-table-column
                  v-for="column in basedInColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
              </el-table>
            </div>
          

          <div class="col-md-6">
              <el-table :data="socialMediaData">
                <div slot="empty">No Social Media</div>
                <el-table-column
                  v-for="column in socialMediaColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
              </el-table>
          </div>
          </div>

          <br>

          <div class="row">
            <label class="col-md-3 col-form-label">Actor Group</label> 
            <div class="col-md-8">
              <base-input readonly v-model="organisation.actorGroup"/>
            </div>
          </div>

          <div class="row">
            <label class="col-md-3 col-form-label">Ideology</label> 
            <div class="col-md-8">
              <base-input readonly v-model="organisation.ideology"/>
            </div>
          </div>

          
            
        </div>
      </card>

      <card>
        <div class="row">
          <div class="col-md-11">
            <h5>Notes</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-button
                    @click.native="modalNote = true"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-add"></i>
                  </base-button>
            <!--<base-button class="btn tim-icons icon-pencil" @click.native="modalNote = true" />
            <base-dropdown title-classes="dropdown-toggle btn tim-icons icon-pencil">
              <h6 class="dropdown-header">Notes Menu</h6>
              <a class="dropdown-item" @click="modalNote = true">Add Note</a>
            </base-dropdown>-->
          </div>
        </div>

        <modal :show.sync="modalNote" headerClasses="justify-content-center" class="modal-mini modal-black">
          <h4 slot="header" class="title title-up">Add Note</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <textarea class="form-control" rows=8 v-model="newNote"></textarea>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalNote = false">Cancel</base-button>
            <base-button type="success" @click.native="createNote()">Save</base-button>
          </div>
        </modal>

        <modal :show.sync="modalNoteReplace" headerClasses="justify-content-center" class="modal-mini modal-black">
          <h4 slot="header" class="title title-up">Edit Note</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <textarea class="form-control" rows=8 v-model="replaceNoteText"></textarea>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalNoteReplace = false">Cancel</base-button>
            <base-button type="success" @click.native="replaceNote()">Save</base-button>
          </div>
        </modal>
  
        <div class="form-horizontal"> 
          <div class="row">  
            <div class="col-md-11">
              <el-table :data="notes" :show-header="false">
                <div slot="empty">No Notes</div>
                <el-table-column
                  v-for="column in notesColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="editNote(props.$index, props.row)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="col-form-label ml-auto">
                {{ notesFrom + 1 }}-{{ notesTo }} of {{ notesTotal }}
            </div>
          
                <base-pagination
                  class="pagination-no-border"
                  v-model="notesPagination.currentPage"
                  :per-page="notesPagination.perPage"
                  :total="notesTotal"
                >
                </base-pagination>
          </div>
        </div>      
      </div>
    </card>
    </div>

    <div class="col-md-6">
      <card>
        <div class="row">
          <div class="col-md-11">
            <h5>Links</h5>
          </div>
          <div class="col-md-1 ml-auto mr-auto">
            <base-button
                    @click.native="addLink()"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-link-72"></i>
                  </base-button>
          </div>
        </div>

        <div class="form-horizontal"> 
          <div class="row">
            <label class="col-md-3 col-form-label">Parent Organisation</label> 
            <div class="col-md-7">
              <el-table :data="parentOrganisation" :show-header="false">
                <div slot="empty">None</div>
                <el-table-column
                  v-for="column in parentOrganisationsColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewOrganisation(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkSubParent(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              </el-table>
            </div>
          </div>
          <div class="row">  
            <div class="col-md-6">
              <el-table :data="linkSubOrganisations">
                <div slot="empty">None</div>
                <el-table-column
                  v-for="column in linkSubOrganisationsColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewOrganisation(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkParentSub(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              </el-table>
              <div id="linkSubOrganisations" class="row">
                <div class="col-form-label ml-auto">
                  {{ linkSubOrganisationsFrom + 1 }}-{{ linkSubOrganisationsTo }} of {{ linkSubOrganisationsTotal }}
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="linkSubOrganisationsPagination.currentPage"
                  :per-page="linkSubOrganisationsPagination.perPage"
                  :total="linkSubOrganisationsTotal"
                />
              </div>
            </div>
            <div class="col-md-6">
              <el-table :data="linkOrganisations">
                <div slot="empty">None</div>
                <el-table-column
                  v-for="column in linkOrganisationsColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewOrganisation(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkOrgOrg(props.row.orgID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              </el-table>
              <div id="linkOrganisations" class="row">
                <div class="col-form-label ml-auto">
                  {{ linkOrganisationsFrom + 1 }}-{{ linkOrganisationsTo }} of {{ linkOrganisationsTotal }}
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="linkOrganisationsPagination.currentPage"
                  :per-page="linkOrganisationsPagination.perPage"
                  :total="linkOrganisationsTotal"
                />
              </div>
            </div>

            

            
          </div>  
        </div>

        <div class="form-horizontal"> 
          <div class="row">  
            <div class="col-md-6">
              <el-table :data="linkEvents">
                <div slot="empty">None</div>
                <el-table-column
                  v-for="column in linkEventsColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewEvent(props.row.eventID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkEventOrg(props.row.eventID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              </el-table>
              <div id="linkEvents" class="row">
                <div class="col-form-label ml-auto">
                  {{ linkEventsFrom + 1 }}-{{ linkEventsTo }} of {{ linkEventsTotal }}
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="linkEventsPagination.currentPage"
                  :per-page="linkEventsPagination.perPage"
                  :total="linkEventsTotal"
                />
              </div>
            </div>
            <div class="col-md-6">
              <el-table :data="linkSouls">
                <div slot="empty">None</div>
                <el-table-column
                  v-for="column in linkSoulsColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                />
                <el-table-column :min-width="45" align="right">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewSoul(props.row.soulID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>
                </div>
              </el-table-column>
              <div v-if="$can('delete', 'all')">
              
              
              <el-table-column :min-width="45">
                <div slot-scope="props">
                  <base-button
                    @click.native="removeLinkSoulOrg(props.row.soulID._id)"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </el-table-column>
              </div>
              </el-table>
              <div id="linkSouls" class="row">
                <div class="col-form-label ml-auto">
                  {{ linkSoulsFrom + 1 }}-{{ linkSoulsTo }} of {{ linkSoulsTotal }}
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="linkSoulsPagination.currentPage"
                  :per-page="linkSoulsPagination.perPage"
                  :total="linkSoulsTotal"
                />
              </div>
            </div>
          </div>  
          
        </div>
      </card>
    </div>
  </div>
</div>

</template>

<script>
import StatsCard from 'src/components/Cards/StatsCard';
import UpdatedCard from 'src/components/Cards/updatedCard';
import swal from 'sweetalert2';
import { DatePicker, TimeSelect, Table, TableColumn, Select, Option, Carousel, CarouselItem } from 'element-ui';
import { BasePagination, TabPane, Tabs, Modal, BaseAlert } from 'src/components';
import { ImageUpload } from 'src/components/index';
import OrganisationsService from '@/services/OrganisationsService'
import SearchService from '@/services/SearchService'
import POIsService from '@/services/POIsService'
import { Loading } from 'element-ui'
import DeleteService from '@/services/DeleteService'

export default {
  components: {
    TabPane,
    Tabs,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    StatsCard,
    UpdatedCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    BasePagination,
    ImageUpload,
    Modal,
    BaseAlert,
  },
  computed: {
    country() {
      let recentBasedIn = this.basedInData[this.basedInData.length - 1].basedIn
      let country = recentBasedIn.split(';')
      let countryString = country[0]
      return countryString
    },
    notes() {
      return this.notesData.slice(this.notesFrom, this.notesTo);
    },
    notesTo() {
      let highBound = this.notesFrom + this.notesPagination.perPage;
      if (this.notesTotal < highBound) {
        highBound = this.notesTotal;
      }
      return highBound;
    },
    notesFrom() {
      return this.notesPagination.perPage * (this.notesPagination.currentPage - 1);
    },
    notesTotal() {
      return this.notesData.length;
    },
    linkOrganisations() {
      return this.linkOrganisationsData.slice(this.linkOrganisationsFrom, this.linkOrganisationsTo);
    },
    linkOrganisationsTo() {
      let highBound = this.linkOrganisationsFrom + this.linkOrganisationsPagination.perPage;
      if (this.linkOrganisationsTotal < highBound) {
        highBound = this.linkOrganisationsTotal;
      }
      return highBound;
    },
    linkOrganisationsFrom() {
      return this.linkOrganisationsPagination.perPage * (this.linkOrganisationsPagination.currentPage - 1);
    },
    linkOrganisationsTotal() {
      return this.linkOrganisationsData.length;
    },
    linkSouls() {
      return this.linkSoulsData.slice(this.linkSoulsFrom, this.linkSoulsTo);
    },
    linkSoulsTo() {
      let highBound = this.linkSoulsFrom + this.linkSoulsPagination.perPage;
      if (this.linkSoulsTotal < highBound) {
        highBound = this.linkSoulsTotal;
      }
      return highBound;
    },
    linkSoulsFrom() {
      return this.linkSoulsPagination.perPage * (this.linkSoulsPagination.currentPage - 1);
    },
    linkSoulsTotal() {
      return this.linkSoulsData.length;
    },

    linkEvents() {
      return this.linkEventsData.slice(this.linkEventsFrom, this.linkEventsTo);
    },
    linkEventsTo() {
      let highBound = this.linkEventsFrom + this.linkEventsPagination.perPage;
      if (this.linkEventsTotal < highBound) {
        highBound = this.linkEventsTotal;
      }
      return highBound;
    },
    linkEventsFrom() {
      return this.linkEventsPagination.perPage * (this.linkEventsPagination.currentPage - 1);
    },
    linkEventsTotal() {
      return this.linkEventsData.length;
    },
    linkSubOrganisations() {
      return this.linkSubOrganisationsData.slice(this.linkSubOrganisationsFrom, this.linkSubOrganisationsTo);
    },
    linkSubOrganisationsTo() {
      let highBound = this.linkSubOrganisationsFrom + this.linkSubOrganisationsPagination.perPage;
      if (this.linkSubOrganisationsTotal < highBound) {
        highBound = this.linkSubOrganisationsTotal;
      }
      return highBound;
    },
    linkSubOrganisationsFrom() {
      return this.linkSubOrganisationsPagination.perPage * (this.linkSubOrganisationsPagination.currentPage - 1);
    },
    linkSubOrganisationsTotal() {
      return this.linkSubOrganisationsData.length;
    },
  },
  // mounted() {
  //   this.getOrganisation()
  // },
  activated() {
    this.getOrganisation()
  },
  data() {
    return {
      modalEnglishName: false,
      modalArabicName: false,
      modalBasedIn: false,
      modalActorGroup: false,
      modalIdeology: false,
      modalNote: false,
      modalNoteReplace: false,
      modalSocialMedia: false,
      newNote: '',
      newSocialMedia: '',
      replaceNoteText: '',
      replaceNoteID: '',
      item:'', //delete this eventually
      searchBarGeneralLocation: '',
      searchGeneralLocationData: null,
      titleSwap: 'Arabic Name',
      newImage: '',
      englishName: '',
      arabicName: '',
      parentOrganisation: [],
      basedInData: [],
      images: [],
      selects: {
        generalLocation: null,
      },
      organisation: {
        title: '',
        subTitle: 'Organisation Title',
        type: 'primary',
        icon: 'tim-icons icon-molecule-40',
        organisationDate: '',
        type: '',
        actorGroup: '',
        ideology: '',
      },
      actorGroups: [
          { value: 'Civilian', label: 'Civilian' },
          { value: 'NGO', label: 'NGO' },
          { value: 'Commercial Entities', label: 'Commercial Entities' },
          { value: 'State Actors (Non-Mil / Sy)', label: 'State Actors (Non-Mil / Sy)' },
          { value: 'State Forces (Mil / Sy)', label: 'State Forces (Mil / Sy)' },
          { value: 'Foreign Forces', label: 'Foreign Forces' },
          { value: 'VEO – AQ Aligned', label: 'VEO – AQ Aligned' },
          { value: 'VEO – IS Aligned', label: 'VEO – IS Aligned' },
          { value: 'Armed groups (unidentified / aligned)', label: 'Armed groups (unidentified / aligned)' },
          { value: 'UN', label: 'UN' },
          { value: 'Mercenary groups', label: 'Mercenary groups' },
          { value: 'Other/Unknown', label: 'Other/Unknown' },
          { value: 'Hadi government', label: 'Hadi government' },
          { value: 'Hadi-affiliated Yemeni forces', label: 'Hadi-affiliated Yemeni forces' },
          { value: 'SLC', label: 'SLC' },
          { value: 'Houthi forces', label: 'Houthi forces' },
          { value: 'IRGC/Iranian', label: 'IRGC/Iranian' },
          { value: 'GNU (non-mil)', label: 'GNU (non-mil)' },
          { value: 'GNU military and security forces', label: 'GNU military and security forces' },
          { value: 'LNA military and security forces', label: 'LNA military and security forces' },
          { value: 'LNA (non-mil)', label: 'LNA (non-mil)' },
          { value: 'Signatory armed groups', label: 'Signatory armed groups' },
          { value: 'Polisario', label: 'Polisario' },
        ],
      organisationCountry: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      organisationDateCard:{
        title: '',
        subTitle: 'Date Entered',
        secondTitle: '',
        secondSubTitle: 'Last Updated',          
        type: 'warning',
        icon: 'tim-icons icon-time-alarm',
      },
      notesData: [],
      socialMediaData: [],
      linkOrganisationsData: [],
      linkSoulsData: [],
      linkEventsData: [],
      linkSubOrganisationsData: [],
      generalLocations: [],
      notesPagination:                { perPage: 5, currentPage: 1, total: 0 },
      linkOrganisationsPagination:    { perPage: 5, currentPage: 1, total: 0 },
      linkSoulsPagination:            { perPage: 5, currentPage: 1, total: 0 },
      linkEventsPagination:           { perPage: 5, currentPage: 1, total: 0 },
      linkSubOrganisationsPagination: { perPage: 5, currentPage: 1, total: 0 },
      parentOrganisationsColumns:   [{ prop: 'orgID.org_en', label: 'Organisations', minWidth: 200 }],
      linkOrganisationsColumns:     [{ prop: 'orgID.org_en', label: 'Linked Organisations', minWidth: 200 }],
      linkSoulsColumns:             [{ prop: 'soulID.nameEnglish', label: 'People', minWidth: 200 }],
      linkEventsColumns:            [{ prop: 'eventID.title', label: 'Events', minWidth: 200 }],
      linkSubOrganisationsColumns:  [{ prop: 'orgID.org_en', label: 'Sub Organisations', minWidth: 200 }],
      notesColumns:                 [{ prop: 'note', label: '', minWidth: 250 }],
      socialMediaColumns:           [{ prop: 'media', label: 'Social Media', minWidth: 250 }],
      basedInColumns:               [{ prop: 'basedIn', label: 'Based In', minWidth: 250 }],
      searchGeneralLocationColumns: [{ prop: 'search', label: 'Based In', minWidth: 250 }],
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    changeName(){
      if (this.titleSwap == 'Arabic Name') {
        // change to arabic
        this.organisation.title = this.arabicName
        this.organisation.subTitle = "Arabic Name"
        this.titleSwap = "English Name"
      }
      else if (this.titleSwap == 'English Name') {
        // change to english
        this.organisation.title = this.englishName
        this.organisation.subTitle = "English Name"
        this.titleSwap = "Arabic Name"
      }
    },
    async getOrganisation () {
      let loading = Loading.service()
      const organisation = await OrganisationsService.getOrganisation({
        id: this.$route.params.id
      })
      this.organisationDateCard.secondTitle = new Date(organisation.data.lastupdated.substring(0, 10)).toDateString()
      this.organisationDateCard.title = new Date(parseInt(organisation.data._id.substring(0, 8), 16) * 1000).toDateString()
      this.englishName = organisation.data.org_en
      this.arabicName = organisation.data.org_ar
      this.images = organisation.data.image
      this.basedInData = organisation.data.basedin
      this.notesData = organisation.data.notes
      this.notesData.reverse()
      this.socialMediaData = organisation.data.socialmedia
      this.organisation.actorGroup = organisation.data.actorGroup
      this.organisation.ideology = organisation.data.ideology
      this.linkSubOrganisationsData = organisation.data.suborg
      this.linkOrganisationsData = organisation.data.linkedorganisations
      this.parentOrganisation = organisation.data.parent
      this.linkSoulsData = organisation.data.linkedsouls
      this.linkEventsData = organisation.data.linkedevents
      if (this.titleSwap == 'Arabic Name') {
        this.organisation.title = organisation.data.org_en
      } else {
        this.organisation.title = organisation.data.org_ar
      }
      if (organisation.data.suborg.length == 0){
        document.getElementById("linkSubOrganisations").style.display = "none"
      } else{
        document.getElementById("linkSubOrganisations").style.display = "flex"
      }
      if (organisation.data.linkedorganisations.length == 0){
        document.getElementById("linkOrganisations").style.display = "none"
      } else{
        document.getElementById("linkOrganisations").style.display = "flex"
      }
      if (organisation.data.linkedsouls.length == 0){
        document.getElementById("linkSouls").style.display = "none"
      } else{
        document.getElementById("linkSouls").style.display = "flex"
      }
      if (organisation.data.linkedevents.length == 0){
        document.getElementById("linkEvents").style.display = "none"
      } else{
        document.getElementById("linkEvents").style.display = "flex"
      }
      loading.close()
    },
    async updateOrganisationDetails(){
      this.modalActorGroup = false
      this.modalIdeology = false
      let loading = Loading.service()
      await OrganisationsService.updateOrganisation({
        id: this.$route.params.id,
        actorGroup: this.organisation.actorGroup, 
        ideology: this.organisation.ideology, 
        lastupdated: new Date(),
      }).catch(err => loading.close().then(this.notifyFailure()))
      this.getOrganisation()
      loading.close()
      this.notifySuccess()
    },
    async updateEnglishName(newName){
      let loading = Loading.service()
      await OrganisationsService.updateOrganisation({
        id: this.$route.params.id,
        org_en: newName, 
        lastupdated: new Date(),
      }).catch(err => loading.close().then(this.notifyFailure()))
      this.modalEnglishName = false
      this.getOrganisation()
      loading.close()
      this.notifySuccess()
    },
    async updateArabicName(newName){
      let loading = Loading.service()
      await OrganisationsService.updateOrganisation({
        id: this.$route.params.id,
        org_ar: newName, 
        lastupdated: new Date(),
      }).catch(err => loading.close().then(this.notifyFailure()))
      this.modalArabicName = false
      this.getOrganisation()
      loading.close()
      this.notifySuccess()
      
    },
    async removeLinkSoulOrg(soulID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkSoulOrgDB(soulID)
            }
          }
        })
    },
    async removeLinkSoulOrgDB(soulID) {
      let loading = Loading.service()
      await DeleteService.removeLinkSoulOrg({soul : soulID, org: this.$route.params.id})
      await this.getOrganisation()
      loading.close()
    },
    async removeLinkOrgOrg(orgID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkOrgOrgDB(orgID)
            }
          }
        })
    },
    async removeLinkOrgOrgDB(orgID) {
      let loading = Loading.service()
      await DeleteService.removeLinkOrgOrg({org1 : this.$route.params.id, org2: orgID})
      await this.getOrganisation()
      loading.close()
    },
    async removeLinkParentSub(orgID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkParentSubDB(orgID)
            }
          }
        })
    },
    async removeLinkParentSubDB(orgID) {
      let loading = Loading.service()
      await DeleteService.removeLinkParentSub({parent : this.$route.params.id, sub: orgID})
      await this.getOrganisation()
      loading.close()
    },
    async removeLinkSubParent(orgID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkSubParentDB(orgID)
            }
          }
        })
    },
    async removeLinkSubParentDB(orgID) {
      let loading = Loading.service()
      await DeleteService.removeLinkParentSub({sub : this.$route.params.id, parent: orgID})
      await this.getOrganisation()
      loading.close()
    },
    async removeLinkEventOrg(eventID) {
      swal({
          title: 'Delete Link',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove the link'
            }
            if (value == 'DELETE'){
              this.removeLinkEventOrgDB(eventID)
            }
          }
        })
    },
    async removeLinkEventOrgDB(eventID) {
      let loading = Loading.service()
      await DeleteService.removeLinkEventOrg({org : this.$route.params.id, event: eventID})
      await this.getOrganisation()
      loading.close()
    },
    async searchGeneralLocations(countrySelected) {
      const searchedLocations = await SearchService.searchGeneralLocations({searchTerms: this.searchBarGeneralLocation})
      this.searchGeneralLocationData = searchedLocations.data
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    imageToUpload: function(params) {
      let reader = new FileReader()
      reader.readAsDataURL(params)
      reader.onload = e => {
        this.selectedFile = e.target.result
      }
    },
    saveImage: function(params) {
      this.uploadImage()
    },
    async uploadImage() {
      await OrganisationsService.updateOrganisationImage({
        id: this.$route.params.id,
        image: this.selectedFile
      }).catch(err => loading.close().then(this.notifyFailure()))
      this.updateOrganisationDetails()
      this.notifySuccess()
      this.getOrganisation()
    },
    async addGeneralLocation(newBasedIn) {
      let loading = Loading.service()
      let newOrganisationBasedIn = await OrganisationsService.updateBasedIn({
        id: this.$route.params.id,
        basedin: newBasedIn, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      this.getOrganisation()
      this.updateOrganisationDetails()
      loading.close()
      this.notifySuccess()
      this.modalBasedIn = false
    },
    saveDetails() {
      this.updateOrganisationDetails()
    },
    async viewSoul(soulID) {
      this.$router.push({ name: 'View Person', params: { id: soulID}})
    },
    async viewEvent(eventID) {
      this.$router.push({ name: 'View Event', params: { id: eventID}})
    },
    async viewOrganisation(organisationID) {
      this.$router.push({ name: 'View Organisation', params: { id: organisationID}})
    },
    async createSocialMedia(){
      this.modalSocialMedia = false
      let loading = Loading.service()
      let newOrganisationSocialMedia = await OrganisationsService.createSocialMedia({
        id: this.$route.params.id,
        media: this.newSocialMedia, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      this.updateOrganisationDetails()
      this.getOrganisation()
      loading.close()
      this.notifySuccess()
    },
    async createNote(){
      this.modalNote = false
      let loading = Loading.service()
      let newOrganisationNote = await OrganisationsService.createNote({
        id: this.$route.params.id,
        note: this.newNote, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      loading.close()
      this.updateOrganisationDetails()
      this.notifySuccess()
      this.getOrganisation()
      this.newNote = ""
      
      
    },
    async replaceNote(){
      this.modalNoteReplace = false
      let loading = Loading.service()
      let newOrganisationNote = await OrganisationsService.replaceNote({
        id: this.$route.params.id,
        sid: this.replaceNoteID,
        note: this.replaceNoteText, 
      }).catch(err => loading.close().then(this.notifyFailure()))
      loading.close()
      this.updateOrganisationDetails()
      this.notifySuccess()
      this.getOrganisation()
      
    },
    addLink() {
      this.$router.push({name: 'Link', params: {id: this.$route.params.id, country: this.country, recordType: 'organisation', recordName: this.organisation.title}})
    },
    editNote(index, row) {
      this.modalNoteReplace = true
      this.replaceNoteText = this.notesData[index].note
      this.replaceNoteID = this.notesData[index]._id
      // swal({
      //     title: 'Edit Note',
      //     input: 'textarea',
      //     showCancelButton: true,
      //     confirmButtonText: 'Save',
      //     cancelButtonText: 'Cancel',
      //     confirmButtonClass: 'btn btn-success btn-fill',
      //     cancelButtonClass: 'btn btn-info btn-fill',
      //     buttonsStyling: false,
      //     allowOutsideClick: false,
      //     inputValue: this.notesData[index].note,
      //     inputValidator: (value) => {
      //       if (!value) {
      //         return 'You need to write something!'
      //       }
      //       if (value != this.notesData[index].note) {
      //         // its different so we need to update it
      //         this.replaceNote(this.notesData[index]._id,value)
      //       }
      //     }
      //   })
    },
    notifySuccess() {
      this.$notify({
        message:
          'Record Updated',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "info"
      });
    },
    notifyFailure() {
      this.$notify({
        message:
          'Update Failed',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "danger"
      });
    },
    deleteRecord() {
      swal({
          title: 'Delete Organisation',
          text: 'You will not be able to revert this. Please type DELETE to confirm',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-danger btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value !== 'DELETE') {
              return 'Please type DELETE to remove this record from the database'
            }
            if (value == 'DELETE'){
              this.deleteOrganisation()
            }
          }
        })
    },
    async deleteOrganisation() {
      await DeleteService.deleteOrganisation({
        id: this.$route.params.id,
      })
      this.$router.push({ name: 'Search'})
    },
  }


};
</script>
<style lang="scss">
.pagination-select, .search-input {
  width: 200px;

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
}</style>
