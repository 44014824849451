<template>
  <div class="row justify-content-center">
    <div class="col-md-6 justify-content-center">
      <h5 class="info-text">
        Did you mean?
      </h5>
    </div>

    <div class="col-md-12 justify-content-center">
      <div class="form-horizontal justify-content-center"> 
        <div class="row justify-content-center">  
          <div class="col-md-10">
            <el-table :data="propValidateData">
              <div slot="empty">No Similar records found</div>
              <el-table-column
                v-for="column in soulsColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              />
              <el-table-column :min-width="135" align="right" label="">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewSoul(props.row._id)"
                    class="btn-simple btn-link"
                    type="info"
                    size="sm"
                  >
                  View
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props:['propValidateData'],
  data() {
    return {
      soulsColumns: [
        { prop: 'nameEnglish', label: 'English Name', minWidth: 200},
        { prop: 'nameArabic', label: 'Arabic Name', minWidth: 200},
        { prop: 'position[0].positionNameEnglish', label: 'Position', minWidth: 200},
      ],
    };
  },
  methods: {
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model)
        return res;
      });
    },
    async viewSoul(soulID) {
      this.$router.push({ name: 'View Person', params: { id: soulID}})
    },
  },
};
</script>
<style></style>
