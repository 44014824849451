<template>
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <h5 class="info-text">All done?</h5>
    </div>
    <div class="col-sm-8">
      <base-input
        name="title"
        placeholder="title"
        v-model="propTitle"
        :error="getError('title')"
        v-validate="modelValidations.title"
      >
      </base-input>
    </div>
    <div class="col-sm-8">
      <base-input
        name="content"
        v-model="propContent"
        :error="getError('content')"
        v-validate="modelValidations.content"
      >
      <textarea class="form-control" rows=8 v-model="propContent"></textarea>
      </base-input>
    </div>

  </div>
</template>
<script>
import { Select, Option } from 'element-ui';
import ReportsService from '@/services/ReportsService'
import { Loading } from 'element-ui'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  props:['propTitle', 'propContent'],
  data() {
    return {
      modelValidations: {
        title: {
          required: true,
          min: 1
        },
        content: {
          required: true,
          min: 1
        }
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async validate() {
      await this.addReport()
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async addReport () {
      let loading = Loading.service()
      const newReport = await ReportsService.addReport({
        name: this.propTitle,
        text: this.propContent,
        lastupdated: new Date(),
      })
      this.$emit("child-newReport", newReport.data._id)
      loading.close()
    },
  }
};
</script>
<style></style>
