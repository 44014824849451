<template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <h5 class="info-text">
        Lets start with mandatory details:
      </h5>
    </div>
    <div class="col-md-8">
      <base-input
        name="title"
        id="title"
        placeholder="Title"
        v-model="title"
        v-validate="modelValidations.title"
        :error="getError('title')"
      />
    </div>

    <div class="col-md-8">
      <base-input :error="getError('country')">
        <el-select
          name="country"
          id="country"
          class="select-info"
          filterable
          size="medium"
          placeholder="Country"
          v-model="selects.country"
          v-validate="modelValidations.country"
        >
          <el-option
            v-for="option in countries"
            class="select-info"
            :value="option.search"
            :label="option.search"
            :key="option.search"
          >
          </el-option>
        </el-select>
      </base-input>
    </div>
  </div>
</template>

<script>
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import SearchService from '@/services/SearchService'
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      selects: {
        country:'',
      },
      countries: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      title: '',
      modelValidations: { 
        title: {required: true, min: 1},
        country: {required: true}
      }
    };
  },
  mounted () {
    this.getNations()
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$emit("child-title", this.title);
      this.$emit("child-country", this.selects.country);
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async getNations() {
      this.countries = window.GLOBALNATIONS
    }
  },
};
</script>
<style></style>
