<template>
  <div class="row justify-content-center">
    <div class="col-md-6 justify-content-center">
      <h5 class="info-text">
        All done?
      </h5>
    </div>

    <div class="col-md-12 justify-content-center">
      <div class="form-horizontal justify-content-center"> 

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input
              name="englishName"
              id="englishName"
              v-model="propEnglishName"
              v-validate="modelValidations.englishName"
              :error="getError('englishName')">
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input
              name="arabicName"
              id="arabicName"
              v-model="propArabicName"
              v-validate="modelValidations.arabicName"
              :error="getError('arabicName')">
            </base-input>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input :error="getError('country')">
              <el-select
                name="country"
                id="country"
                class="select-info"
                size="medium"
                filterable
                v-model="propCountry"
                v-validate="modelValidations.country">
                <el-option
                  v-for="option in countries"
                  class="select-info"
                  :value="option.search"
                  :label="option.search"
                  :key="option.search">
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>
      </div>

        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Based In</label> 
          <div class="col-md-6">
            <base-input>
              <el-select
                name="basedIn"
                id="basedIn"
                class="select-info"
                size="medium"
                placeholder=""
                filterable
                v-model="propGeneralLocation"
              >
                <el-option
                  v-for="option in generalLocations"
                  class="select-info"
                  :value="option.search"
                  :label="option.search"
                  :key="option.search"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div> -->

        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Actor Group</label> 
          <div class="col-md-6">
            <base-input>
              <el-select
                name="actorGroup"
                id="actorGroup"
                class="select-info"
                size="medium"
                placeholder=""
                filterable
                v-model="propActorGroup"
              >
                <el-option
                  v-for="option in actorGroups"
                  class="select-info"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import OrganisationsService from '@/services/OrganisationsService'
import SearchService from '@/services/SearchService'
import { Loading } from 'element-ui'

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props:['propEnglishName', 'propArabicName', 'propCountry', 'propGeneralLocation', 'propActorGroup'],
  mounted() {
    this.getNations()
  },
  data() {
    return {
      selects: {
        country:'',
      },
      countries: [],
      englishName: '',
      arabicName: '',
      actorGroup: '',
      basedIn: 'empty',
      actorGroups: [
          { value: 'Civilian', label: 'Civilian' },
          { value: 'NGO', label: 'NGO' },
          { value: 'Commercial Entities', label: 'Commercial Entities' },
          { value: 'State Actors (Non-Mil / Sy)', label: 'State Actors (Non-Mil / Sy)' },
          { value: 'State Forces (Mil / Sy)', label: 'State Forces (Mil / Sy)' },
          { value: 'Foreign Forces', label: 'Foreign Forces' },
          { value: 'VEO – AQ Aligned', label: 'VEO – AQ Aligned' },
          { value: 'VEO – IS Aligned', label: 'VEO – IS Aligned' },
          { value: 'Armed groups (unidentified / aligned)', label: 'Armed groups (unidentified / aligned)' },
          { value: 'UN', label: 'UN' },
          { value: 'Mercenary groups', label: 'Mercenary groups' },
          { value: 'Other/Unknown', label: 'Other/Unknown' },
          { value: 'Hadi government', label: 'Hadi government' },
          { value: 'Hadi-affiliated Yemeni forces', label: 'Hadi-affiliated Yemeni forces' },
          { value: 'SLC', label: 'SLC' },
          { value: 'Houthi forces', label: 'Houthi forces' },
          { value: 'IRGC/Iranian', label: 'IRGC/Iranian' },
          { value: 'GNU (non-mil)', label: 'GNU (non-mil)' },
          { value: 'GNU military and security forces', label: 'GNU military and security forces' },
          { value: 'LNA military and security forces', label: 'LNA military and security forces' },
          { value: 'LNA (non-mil)', label: 'LNA (non-mil)' },
          { value: 'Signatory armed groups', label: 'Signatory armed groups' },
          { value: 'Polisario', label: 'Polisario' },
        ],
      modelValidations: {
        englishName: {required: true, min: 1},
        arabicName: {required: false}, 
        country: {required: true},
      }
    }
  },
  // watch: {
  //   propCountry:function(value){
  //     this.getGeneralLocations(value)
  //   }
  // },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async validate() {
      await this.addOrganisation()
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async addOrganisation () {
      let loading = Loading.service()
      if (this.propGeneralLocation == "" || this.propGeneralLocation == null){
        this.basedIn = this.propCountry
      } else {
        this.basedIn = this.propGeneralLocation
      }
      let newOrganisation = null
      let count = 0
      while (true) {
        try {
          newOrganisation = await OrganisationsService.addOrganisation({
        org_en: this.propEnglishName, 
        org_ar: this.propArabicName, 
        actorGroup: this.propActorGroup, 
        basedin: [{basedIn: this.basedIn, basedInAdded: new Date()}],
        lastupdated: new Date(),
      })
          break
        } catch (error) {
          count++
          if (error.response.status != 500 && error.response.status != 502) {
                loading.close()
                this.notifyFailure()
                break
          }
          if (count == 2){
            loading.close()
            this.notifyFailure()
            break
          }
        }
      }

     

      
      
      loading.close()
      this.$emit("child-newOrganisation", newOrganisation.data._id)
    },
    async getNations() {
      this.countries = window.GLOBALNATIONS
    },
    async getGeneralLocations(countrySelected) {
      const activeGeneralLocations = await SearchService.fetchLocations({country: countrySelected})
      this.generalLocations = activeGeneralLocations.data
    },
    notifyFailure() {
      this.$notify({
        message:
          'Action Failed',
        timeout: 2000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: "danger"
      });
    },
  }
};
</script>

<style></style>
