<template>
  <card class="card-user">
    <p class="card-text"></p>
    <div class="author">

      <a href="javascript:void(0)">
        <img class="avatar" src="img/default-avatar.png" alt="..." />
        <h5 class="title">First Last</h5>
      </a>
      <p class="description">User Type</p>
    </div>
    <p></p>


    <div slot="footer" class="button-container">
      <!--<base-button native-type="submit" type="info" class="btn-fill">
        Add Avatar
      </base-button>-->
    </div>
  </card>
</template>
<script>
export default {};
</script>
<style></style>