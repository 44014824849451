import Api from '@/services/Api'

const options = {
  headers: {'Authorization': window.GLOBALIDTOKEN}
}

export default {
  // Single function to create both sides of links - iupdating event and soul records
  createLinkEventSoul (params) {
    return Api().put('link/create/event/soul/' + params.eventID + '/' + params.soulID + '/' + params.linkType + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // Single function to create both sides of links - updating event and organisation records
  createLinkEventOrganisation (params) {
    return Api().put('link/create/event/organisation/' + params.eventID + '/' + params.orgID + '/' + params.linkType + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // Single function to create both sides of links - 
  createLinkSoulSoul (params) {
    return Api().put('link/create/soul/soul/' + params.soulID1 + '/' + params.soulID2 + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // Single function to create both sides of links - 
  createLinkOrganisationOrganisation (params) {
    return Api().put('link/create/organisation/organisation/' + params.parentID + '/' + params.subID + '/' + params.linkType + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // Single function to create both sides of links - 
  createLinkSoulOrganisation (params) {
    return Api().put('link/create/soul/organisation/' + params.soulID + '/' + params.orgID + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
}