<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard >
          <template slot="header">
            <h3 class="card-title">Add a New Organisation</h3>
            <h3 class="description">
              Add a new organisation to the database.
            </h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-caps-small"></i>
              <p>Organisation</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
              @child-englishname="englishNameValue"
              @child-arabicname="arabicNameValue"
              @child-country="countryValue"
            ></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-atom"></i>
              <p>Validation</p>
            </template>
            <second-step
              ref="step2"
              v-bind:propValidateData="this.validateData" 
              @on-validated="onStepValidated"
            ></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-pencil"></i>
              <p>Details</p>
            </template>
            <third-step
              ref="step3"
              nextButtonText="this"
              @on-validated="onStepValidated"
              @child-generallocation="generalLocationValue"
              @child-actorgroup="actorGroupValue"
              v-bind:propCountry="this.country"
            ></third-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p>Save</p>
            </template>
            <fourth-step ref="step4" 
              v-bind:propEnglishName="this.englishName" 
              v-bind:propArabicName="this.arabicName"
              v-bind:propCountry="this.country"
              v-bind:propGeneralLocation="this.generalLocation"
              v-bind:propActorGroup="this.actorGroup"
              @child-newOrganisation="newOrganisationValue"
              @on-validated="wizardComplete" ></fourth-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from './FirstStep.vue'
import SecondStep from './SecondStep.vue'
import ThirdStep from './ThirdStep.vue'
import FourthStep from './FourthStep.vue'
import swal from 'sweetalert2'
import { SimpleWizard, WizardTab } from 'src/components'
import LinkService from '@/services/LinkService'
import OrganisationsService from '@/services/OrganisationsService'
import SearchService from '@/services/SearchService'
import { Loading } from 'element-ui';

export default {
  data() {
    return {
      wizardModel: {},
      englishName: '',
      country:'',
      arabicName:'',
      generalLocation:'',
      actorGroup:'',
      validateData: [],
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    SimpleWizard,
    WizardTab,
  },
  methods: {
    validateStep(ref) {
      if (ref == 'step1') {
        this.validateOrganisation(this.$refs[ref]._data.selects.country, encodeURI(this.$refs[ref]._data.englishName))
      }
      scroll(0,0)
      return this.$refs[ref].validate()
    },
    englishNameValue: function(params) {
      this.englishName = params;
    },
    countryValue: function(params) {
      this.country = params;
    },
    arabicNameValue: function(params) {
      this.arabicName = params;
    },
    generalLocationValue: function(params) {
      this.generalLocation = params;
    },
    actorGroupValue: function(params) {
      this.actorGroup = params;
    },
    newOrganisationValue: function(params) {
      this.newOrganisation = params;
    },
    async validateOrganisation(country, englishName) {
      let loading = Loading.service()
      let newData = await SearchService.validateOrganisation({
        country: country,
        englishName: englishName
      })
      this.validateData = newData.data
      loading.close()
      if (newData.data.length == 0) {
        // if no results were returned we can skip the next step
        this.onStepValidated(true, "step2")
      }
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      if(this.$route.params.type == "soul"){
        this.createLinkSoulOrganisation(this.$route.params.id, this.newOrganisation)
        swal({
          title: 'Organisation Created',
          text: 'Would you like to view this Organisation or the original Person?',
          type: 'success',
          showCancelButton: true,
          confirmButtonText: 'View Original Person',
          cancelButtonText: 'View New Organisation',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false
      }).then((result) => {
        // need to link the souls
        if (result.value) {
          this.$router.push({ name: 'View Person', params: { id: this.$route.params.id}})
        } else if (result.dismiss === swal.DismissReason.cancel) {
          this.$router.push({ name: 'View Organisation', params: { id: this.newOrganisation}})
          }
      })
      }
      if(this.$route.params.type == "organisation"){
        swal({
          title: 'Link',
          text: 'Choose Below',
          input: 'radio',
          inputOptions: ({"parent":"Parent", "sub":"Sub Organisation", "linked": "Linked Organisation"}),
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == null){
              return "Please select one"
            }
            this.createLinkOrganisationOrganisation(this.$route.params.id, this.newOrganisation, value)
          }
        }).then((result) =>
          swal({
            title: 'Organisation Created',
            text: 'Would you like to view this Organisation or the original Organisation?',
            type: 'success',
            showCancelButton: true,
            confirmButtonText: 'View Original Organisation',
            cancelButtonText: 'View New Organisation',
            confirmButtonClass: 'btn btn-success btn-fill',
            cancelButtonClass: 'btn btn-info btn-fill',
            buttonsStyling: false,
            allowOutsideClick: false
          })).then((result) => {
            if (result.value) {
              this.$router.push({ name: 'View Organisation', params: { id: this.$route.params.id}})
            } else if (result.dismiss === swal.DismissReason.cancel) {
              this.$router.push({ name: 'View Organisation', params: { id: this.newOrganisation}})
            }
        })
      }
      if(this.$route.params.type == "event"){
        swal({
          title: 'Link',
          text: 'Choose Below',
          input: 'radio',
          inputOptions: ({"actor":"Actor", "target":"Target"}),
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (value == null){
              return "Please select one"
            }
            this.createLinkEventOrganisation(this.$route.params.id, this.newOrganisation, value)
          }
        }).then((result) =>
          swal({
            title: 'Organisation Created',
            text: 'Would you like to view this Organisation or the original Event?',
            type: 'success',
            showCancelButton: true,
            confirmButtonText: 'View Original Event',
            cancelButtonText: 'View New Organisation',
            confirmButtonClass: 'btn btn-success btn-fill',
            cancelButtonClass: 'btn btn-info btn-fill',
            buttonsStyling: false,
            allowOutsideClick: false
          })).then((result) => {
            if (result.value) {
              this.$router.push({ name: 'View Event', params: { id: this.$route.params.id}})
            } else if (result.dismiss === swal.DismissReason.cancel) {
              this.$router.push({ name: 'View Organisation', params: { id: this.newOrganisation}})
            }
        })
      }
      if(this.$route.params.type == undefined){
        swal({
          title: 'Organisation Created',
          text: 'Would you like to view this Organisation or add another to the database?',
          type: 'success',
          showCancelButton: true,
          confirmButtonText: 'Add Another',
          cancelButtonText: 'View Organisation',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            let randomNumber = Math.floor(Math.random() * 100000000001)
            this.$router.push({ name: 'Add Organisation', params: { id: randomNumber}})
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === swal.DismissReason.cancel
          ) {
          //do something else
          this.$router.push({ name: 'View Organisation', params: { id: this.newOrganisation}})
          }
        })
      }
    },
    async createLinkEventOrganisation(eventID, organisationID, linkType) {
      await LinkService.createLinkEventOrganisation({
        eventID: eventID,
        orgID: organisationID,
        linkType: linkType
      })
    },
    async createLinkOrganisationOrganisation(parentID, subID, linkType) {
      await LinkService.createLinkOrganisationOrganisation({
        parentID: parentID,
        subID: subID,
        linkType: linkType
      })
    },
    async createLinkSoulOrganisation(soulID, organisationID) {
      await LinkService.createLinkSoulOrganisation({
        soulID: soulID,
        orgID: organisationID
      })
    },




  }
}
</script>
