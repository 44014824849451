<template>
  <div class="row justify-content-center">
    <div class="col-md-6 justify-content-center">
      <h5 class="info-text">
        Add more details
      </h5>
    </div>

    <div class="col-md-12 justify-content-center">
      <div class="form-horizontal justify-content-center"> 
        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Location Area</label> 
          <div class="col-md-6">
            <base-input>
              <el-select
                name="generalLocation"
                id="generalLocation"
                class="select-info"
                size="medium"
                placeholder=""
                filterable
                v-model="selects.generalLocation"
              >
                <el-option
                  v-for="option in generalLocations"
                  class="select-info"
                  :value="option._id"
                  :label="option.search"
                  :key="option._id"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div> -->

        <!-- <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Specific Location</label> 
          <div class="col-md-6">
            <base-input>
              <el-select
                name="specificLocation"
                id="specificLocation"
                class="select-info"
                size="medium"
                placeholder=""
                filterable
                v-model="selects.specificLocation"
              >
                <el-option
                  v-for="option in specificLocations"
                  class="select-info"
                  :value="option._id"
                  :label="option.name_en"
                  :key="option._id"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div> -->

        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Source Reference</label> 

          <div class="col-md-6">
            <base-input
              name="sourceReference"
              id="sourceReference"
              placeholder=""
              v-model="sourceReference"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <label class="col-md-2 col-form-label">Report Reference</label> 

          <div class="col-md-6">
            <base-input
              name="reportReference"
              id="reportReference"
              placeholder=""
              v-model="reportReference"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { TimeSelect, DatePicker, Select, Option } from 'element-ui'
import SearchService from '@/services/SearchService'
import POIsService from '@/services/POIsService'

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props:['propCountry'],
  data() {
    return {
      sourceReference: '',
      reportReference: '',
      eventDescription: '',
      selects: {
        generalLocation: null,
        specificLocation: null,
      },
      generalLocations: [],
      specificLocations: [],
    };
  },
  mounted() {
    //this.getGeneralLocations()
    //this.getSpecificLocations()
  },
  // watch: {
  //   propCountry:function(value){
  //     this.getGeneralLocations(value)
  //   }
  // },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$emit("child-generallocation", this.selects.generalLocation)
      this.$emit("child-specificlocation", this.selects.specificLocation)
      this.$emit("child-sourcereference", this.sourceReference)
      this.$emit("child-reportreference", this.reportReference)
      this.$emit("child-eventdescription", this.eventDescription)
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model)
        return res;
      });
    },
    async getGeneralLocations(countrySelected) {
      const activeGeneralLocations = await SearchService.fetchLocations({country: countrySelected})
      this.generalLocations = activeGeneralLocations.data
    },
    async getSpecificLocations() {
      const activeSpecificLocations = await SearchService.fetchPOIs()
      this.specificLocations = activeSpecificLocations.data
    },
  },
};
</script>
<style></style>
