<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard >
          <template slot="header">
            <h3 class="card-title">Export Builder</h3>
            <h3 class="description">
              Build a file for export.
            </h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-caps-small"></i>
              <p>Determine Type</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
              @child-type="typeValue"
            ></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-pencil"></i>
              <p>Select Data</p>
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
              @child-eventdatestart="eventDateStartValue"
              @child-eventdateend="eventDateEndValue"
              @child-country="countryValue"
            ></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p>Generate File</p>
            </template>
            <third-step ref="step3" 
              @child-singlefile="oneFileValue"
              @on-validated="wizardComplete" ></third-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from './FirstStep.vue'
import SecondStep from './SecondStep.vue'
import ThirdStep from './ThirdStep.vue'
import swal from 'sweetalert2'
import { SimpleWizard, WizardTab } from 'src/components'
import ExportsService from '@/services/ExportsService'
const Json2csvParser = require('json2csv').Parser
import { Loading } from 'element-ui';

export default {
  data() {
    return {
      wizardModel: {},
      eventDateStart: '',
      eventDateEnd: '',
      countriesSelected: '',
      type:'',
      oneFile:'',
      events: [],
      orgs: [],
      souls: [],
      reports: [],
      sources: [],
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab,
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    countryValue: function(params) {
      this.countriesSelected = params;
    },
    typeValue: function(params) {
      this.type = params;
    },
    eventDateStartValue: function(params) {
      this.eventDateStart = params;
    },
    eventDateEndValue: function(params) {
      this.eventDateEnd = params;
    },
    oneFileValue: function(params) {
      this.oneFile = params;
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      if (this.type == 'Event') {
        if (this.eventDateStart == '' || this.eventDateEnd == '') {
          this.eventDateStart = new Date()
          this.eventDateEnd = new Date()
          this.exportEventsDate()
        } else {
          this.exportEventsDate()
        }
      }
      if (this.type == 'Event Audit'){
        if (this.eventDateStart == '' || this.eventDateEnd == '') {
          this.eventDateStart = new Date()
          this.eventDateEnd = new Date()
          this.exportEventsLastUpdated()
        } else {
          this.exportEventsLastUpdated()
        }
      }
      if (this.type == 'People'){
        if (this.eventDateStart == '' || this.eventDateEnd == '') {
          this.exportAllSoulsCountry()
        } else {
          this.exportAllSoulsDateCountry()
        }
      }
      if (this.type == 'Organisations'){
        if (this.eventDateStart == '' || this.eventDateEnd == '') {
          this.exportAllOrganisationsCountry()
        } else {
          this.exportAllOrganisationsDateCountry()
        }
      }
      if (this.type == 'Source'){
        if (this.eventDateStart == '' || this.eventDateEnd == '') {
          this.exportAllSourcesCountry()
        } else {
          this.exportAllSourcesDateCountry()
        }
      }
      if (this.type == 'Report'){
        if (this.eventDateStart == '' || this.eventDateEnd == '') {
          this.exportAllReports()
        } else {
          this.exportAllReportsDate()
        }
        
      }
      
    },
    async exportEvents() {
      let loading = Loading.service()
      const fields = [
        {label:'Date', 
          value: (row) => { 
            let newDate = row.date.substr(0,10)
            return newDate
          }
        }, 
        {label:'Title', 
          value: (row) => { 
            let linkTitle
            if (row.title) {
                linkTitle = row.title.replace(/#/g, "")
            }
            return linkTitle
          }
        }, 
        {label:'Type', value:'type'}, 
        {label:'Actor (Org)', value:'actorOrg[0].orgID.org_en'},
        {label:'Actor (Org)', value:'actorOrg[1].orgID.org_en'},
        {label:'Actor (Org)', value:'actorOrg[2].orgID.org_en'},
        {label:'Actor (Org)', value:'actorOrg[3].orgID.org_en'}, 
        {label:'Target (Org)', value:'targetOrg[0].orgID.org_en'},
        {label:'Target (Org)', value:'targetOrg[1].orgID.org_en'},
        {label:'Target (Org)', value:'targetOrg[2].orgID.org_en'},
        {label:'Target (Org)', value:'targetOrg[3].orgID.org_en'},
        {label:'Country', value:'country'}, 
        {label:'Region', value:'general_location.region'},
        {label:'Province', value:'general_location.province'},
        {label:'District', value:'general_location.district'},
        {label:'City', value:'general_location.city'},
        {label:'Location Area Latitude', value:'general_location.latitude'},
        {label:'Location Area Longitude', value:'general_location.longitude'}, 
        {label:'Specific Location', value:'specific_location.name_en'}, 
        {label:'Specific Location Latitude', value:'specific_location.latitude'}, 
        {label:'Specific Location Longitude', value:'specific_location.longitude'},
        {label: 'Event Description', 
          value: (row) => { let newNote = ''
            row.notes.forEach(note => {
              if (note.note != undefined) {
                newNote += note.note.replace(/#\r?\n|\r/g, "")  
              }
            });
            let returnNote = newNote.replace(/\r?\n|\r/g, '');
            return returnNote
          }
        },
        //{label:'Source URL', value:'sourceref'}, 
        {label:'Source URL', 
          value: (row) => { 
            let linkSource
            if (row.sourceref) {
                linkSource = row.sourceref.replace(/#/g, "")
            }
            return linkSource
          }
        }, 
        {label:'Report Reference', value:'reportref'}, 
        {label:'Deaths', value:'dead'}, 
        {label:'Injured', value:'injured'}, 
        {label:'Arrested', value:'arrested'}, 
        {label:'Surrendered', value:'surrendered'}, 
        {label:'Kidnapped', value:'kidnapped'}, 
        {label:'Protestors', value:'protestors'}, 
        {label:'Migrants', value:'migrants'}, 
        {label:'IEDs', value:'ieds'}, 
        {label:'Weapons Found', value:'weaponsFound'}, 
        {label:'Locations Targeted', value:'locationsTargeted'},
      ]
      const opts = { fields, encoding: 'utf8'}
      const parser = new Json2csvParser(opts)
      const csv = parser.parse(this.events)
      var hiddenElement = document.createElement('a')
      //code check
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      hiddenElement.target = '_blank'
      hiddenElement.setAttribute("type", "hidden")
      hiddenElement.download = 'events.csv'
      document.body.appendChild(hiddenElement)
      hiddenElement.click()
      hiddenElement.remove()
      loading.close()
    },
    async exportSouls() {
      let loading = Loading.service()
      const fields = [
        {label:'Country', value:'basedin[0].basedIn'}, 
        {label:'Rank', value:'ranks.rankName'}, 
        {label:'Name (English)', value:'nameEnglish'}, 
        {label:'AKA', value:'aka[0].englishAKA'}, 
        {label:'Position', value:'position[0].positionNameEnglish'}, 
        {label:'Position', value:'position[1].positionNameEnglish'}, 
        {label:'Actor Group', value:'actorGroup'}, 
        {label:'Tribe', value:'tribe[0].tribe'}, 
        {label:'Based In', value:'basedin[0].basedIn'}, 
        {label:'Notes', 
          value: (row) => { let newNote = ''
            row.notes.forEach(note => {
              if (note.note != undefined) {
                newNote += note.note.replace(/#\r?\n|\r/g, "")  
              }
            });
            let returnNote = newNote.replace(/\r?\n|\r/g, '');
            return returnNote
          }
        }, 
        {label:'Linked Organisation', value:'linkedorganisations[0].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[1].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[2].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[3].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[4].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[5].orgID.org_en'},
        {label:'Linked Person', value:'linkedsouls[0].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[1].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[2].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[3].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[4].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[5].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[6].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[7].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[8].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[9].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[10].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[11].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[12].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[13].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[14].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[15].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[16].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[17].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[18].soulID.nameEnglish'},
        {label:'Linked Person', value:'linkedsouls[19].soulID.nameEnglish'},
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[0]) {
              if (row.linkedevents[0].eventID){
                linkEvent = row.linkedevents[0].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[1]) {
              if (row.linkedevents[1].eventID){
                linkEvent = row.linkedevents[1].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[2]) {
              if (row.linkedevents[2].eventID){
                linkEvent = row.linkedevents[2].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[3]) {
              if (row.linkedevents[3].eventID){
                linkEvent = row.linkedevents[3].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[4]) {
              if (row.linkedevents[4].eventID){
                linkEvent = row.linkedevents[4].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[5]) {
              if (row.linkedevents[5].eventID){
                linkEvent = row.linkedevents[5].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[6]) {
              if (row.linkedevents[6].eventID){
                linkEvent = row.linkedevents[6].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[7]) {
              if (row.linkedevents[7].eventID){
                linkEvent = row.linkedevents[7].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[8]) {
              if (row.linkedevents[8].eventID){
                linkEvent = row.linkedevents[8].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[9]) {
              if (row.linkedevents[9].eventID){
                linkEvent = row.linkedevents[9].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[10]) {
              if (row.linkedevents[10].eventID){
                linkEvent = row.linkedevents[10].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[11]) {
              if (row.linkedevents[11].eventID){
                linkEvent = row.linkedevents[11].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[12]) {
              if (row.linkedevents[12].eventID){
                linkEvent = row.linkedevents[12].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[13]) {
              if (row.linkedevents[13].eventID){
                linkEvent = row.linkedevents[13].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[14]) {
              if (row.linkedevents[14].eventID){
                linkEvent = row.linkedevents[14].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[15]) {
              if (row.linkedevents[15].eventID){
                linkEvent = row.linkedevents[15].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[16]) {
              if (row.linkedevents[16].eventID){
                linkEvent = row.linkedevents[16].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[17]) {
              if (row.linkedevents[17].eventID){
                linkEvent = row.linkedevents[17].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[18]) {
              if (row.linkedevents[18].eventID){
                linkEvent = row.linkedevents[18].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[19]) {
              if (row.linkedevents[19].eventID){
                linkEvent = row.linkedevents[19].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
      ]
      const opts = { fields, unwind: ['ranks', 'basedin']}
      const parser = new Json2csvParser(opts)
      const csv = parser.parse(this.souls)
      var hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'people.csv'
      hiddenElement.click()
      loading.close()
    },
    async exportOrgs() {
      let loading = Loading.service()
      const fields = [
        {label:'Country', value:'basedin[0].basedIn'}, 
        {label:'Name', value:'org_en'}, 
        {label:'Actor Group', value:'actorGroup'}, 
        {label:'Ideology', value:'ideology'}, 
        {label:'Based In', value:'basedin[0].basedIn'}, 
        {label:'Social Media', 
          value: (row) => { 
            let newSocial
            if (row.socialmedia[0]) {
              newSocial = row.socialmedia[0].media.replace(/#/g, "")
            }
            return newSocial
          }
        }, 
        {label:'Notes', 
          value: (row) => { let newNote = ''
            row.notes.forEach(note => {
              if (note.note != undefined) {
                newNote += note.note.replace(/#\r?\n|\r/g, "")  
              }
            });
            let returnNote = newNote.replace(/\r?\n|\r/g, '');
            return returnNote
          }
        }, 
        {label:'Parent Organisation', value:'parent[0].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[0].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[1].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[2].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[3].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[4].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[5].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[6].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[7].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[8].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[9].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[10].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[11].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[12].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[13].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[14].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[15].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[16].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[17].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[18].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[19].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[20].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[21].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[22].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[23].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[24].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[25].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[26].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[27].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[28].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[29].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[30].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[31].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[32].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[33].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[34].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[35].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[36].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[37].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[38].orgID.org_en'}, 
        {label:'Sub Organisation', value:'suborg[39].orgID.org_en'}, 
        {label:'Linked Person', value:'linkedsouls[0].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[1].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[2].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[3].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[4].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[5].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[6].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[7].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[8].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[9].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[10].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[11].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[12].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[13].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[14].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[15].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[16].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[17].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[18].soulID.nameEnglish'}, 
        {label:'Linked Person', value:'linkedsouls[19].soulID.nameEnglish'}, 
        {label:'Linked Organisation', value:'linkedorganisations[0].orgID.org_en'}, 
        {label:'Linked Organisation', value:'linkedorganisations[1].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[2].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[3].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[4].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[5].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[6].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[7].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[8].orgID.org_en'},
        {label:'Linked Organisation', value:'linkedorganisations[9].orgID.org_en'},
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[0]) {
              if (row.linkedevents[0].eventID){
                linkEvent = row.linkedevents[0].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[1]) {
              if (row.linkedevents[1].eventID){
                linkEvent = row.linkedevents[1].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[2]) {
              if (row.linkedevents[2].eventID){
                linkEvent = row.linkedevents[2].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[3]) {
              if (row.linkedevents[3].eventID){
                linkEvent = row.linkedevents[3].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[4]) {
              if (row.linkedevents[4].eventID){
                linkEvent = row.linkedevents[4].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[5]) {
              if (row.linkedevents[5].eventID){
                linkEvent = row.linkedevents[5].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[6]) {
              if (row.linkedevents[6].eventID){
                linkEvent = row.linkedevents[6].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[7]) {
              if (row.linkedevents[7].eventID){
                linkEvent = row.linkedevents[7].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[8]) {
              if (row.linkedevents[8].eventID){
                linkEvent = row.linkedevents[8].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[9]) {
              if (row.linkedevents[9].eventID){
                linkEvent = row.linkedevents[9].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[10]) {
              if (row.linkedevents[10].eventID){
                linkEvent = row.linkedevents[10].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[11]) {
              if (row.linkedevents[11].eventID){
                linkEvent = row.linkedevents[11].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[12]) {
              if (row.linkedevents[12].eventID){
                linkEvent = row.linkedevents[12].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[13]) {
              if (row.linkedevents[13].eventID){
                linkEvent = row.linkedevents[13].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[14]) {
              if (row.linkedevents[14].eventID){
                linkEvent = row.linkedevents[14].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[15]) {
              if (row.linkedevents[15].eventID){
                linkEvent = row.linkedevents[15].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[16]) {
              if (row.linkedevents[16].eventID){
                linkEvent = row.linkedevents[16].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[17]) {
              if (row.linkedevents[17].eventID){
                linkEvent = row.linkedevents[17].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[18]) {
              if (row.linkedevents[18].eventID){
                linkEvent = row.linkedevents[18].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
        {label:'Linked Event', 
          value: (row) => { 
            let linkEvent
            if (row.linkedevents[19]) {
              if (row.linkedevents[19].eventID){
                linkEvent = row.linkedevents[19].eventID.title.replace(/#/g, "")
              }
            }
            return linkEvent
          }
        }, 
      ]
      const opts = { fields, unwind: ['basedin']}
      const parser = new Json2csvParser(opts)
      const csv = parser.parse(this.orgs)
      var hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'organisations.csv'
      hiddenElement.click()
      loading.close()
    },
    async exportReports() {
      let loading = Loading.service()
      const fields = [
        {label:'Created', value:'_id'}, 
        {label:'Name', value:'name'}, 
      ]
      const opts = { fields }
      const parser = new Json2csvParser(opts)
      const csv = parser.parse(this.reports)
      var hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'reports.csv'
      hiddenElement.click()
      loading.close()
    },
    async exportSources() {
      let loading = Loading.service()
      const fields = [
        {label:'Country', value:'country'}, 
        {label:'Name', value:'name'}, 
        {label:'Type', value:'type'}, 
        {label:'Affiliation', value:'affiliation'}, 
        {label:'YouTube', value:'youtube'}, 
        {label:'Instagram', value:'instagram'}, 
        {label:'Twitter', value:'twitter'}, 
        {label:'Website', value:'website'}, 
        {label:'Facebook', value:'facebook'}, 
        {label:'Notes', value:'notes'}, 
      ]
      const opts = { fields }
      const parser = new Json2csvParser(opts)
      const csv = parser.parse(this.sources)
      var hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'sources.csv'
      hiddenElement.click()
      loading.close()
    },
    async exportEventsLastUpdated() { // Date range is based on last updated
    let loading = Loading.service()
      if (this.oneFile == true) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
          let searchDates = this.getDates(this.eventDateStart, this.eventDateEnd)
          const promises = searchDates.map(oneDate => ExportsService.exportEventsLastUpdated({country: singleCountry, date: oneDate}))
          const eventResults = await Promise.all(promises)
          for (let index = 0; index < eventResults.length; index++) {
            this.events = this.events.concat(eventResults[index].data)
          }
        }
        this.exportEvents()
      }
      if (this.oneFile == false) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
          let searchDates = this.getDates(this.eventDateStart, this.eventDateEnd)
          const promises = searchDates.map(oneDate => ExportsService.exportEventsLastUpdated({country: singleCountry, date: oneDate}))
          const eventResults = await Promise.all(promises)
          for (let index = 0; index < eventResults.length; index++) {
            this.events = this.events.concat(eventResults[index].data)
          }
          this.exportEvents()
        }
      }
      this.events = []
      loading.close()
    },
    async exportEventsDate() { // Where a date range is not specifed - export just today
    let loading = Loading.service()
      if (this.oneFile == true) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
          let searchDates = this.getDates(this.eventDateStart, this.eventDateEnd)
          const promises = searchDates.map(oneDate => ExportsService.exportEventsDate({country: singleCountry, date: oneDate}))
          const eventResults = await Promise.all(promises)
          for (let index = 0; index < eventResults.length; index++) {
            this.events = this.events.concat(eventResults[index].data)
          }
        }
        this.exportEvents()
      }
      if (this.oneFile == false) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
          let searchDates = this.getDates(this.eventDateStart, this.eventDateEnd)
          const promises = searchDates.map(oneDate => ExportsService.exportEventsDate({country: singleCountry, date: oneDate}))
          const eventResults = await Promise.all(promises)
          for (let index = 0; index < eventResults.length; index++) {
            this.events = this.events.concat(eventResults[index].data)
          }
          this.exportEvents()
        }
      }
      this.events = []
      loading.close()
    },
    async exportAllSoulsCountry() { // Where a date range is not specifed - all from countries selected
    let loading = Loading.service()
      if (this.oneFile == true) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
        const response = await ExportsService.getSoulsByCountry({country: singleCountry})
        this.souls = this.souls.concat(response.data)
        }
        this.exportSouls()
      }
      if (this.oneFile == false) {    
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
        const response = await ExportsService.getSoulsByCountry({country: singleCountry})
        
        this.souls = response.data
        this.exportSouls()
        }
      }
      this.souls = []
      loading.close()
    },
    async exportAllSoulsDateCountry() {
      let loading = Loading.service()
      if (this.oneFile == true) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
        const response = await ExportsService.getSoulsByCountryLastUpdated({country: singleCountry, startDate: this.eventDateStart, endDate: this.eventDateEnd})
        this.souls = this.souls.concat(response.data)
        }
        this.exportSouls()
      }
      if (this.oneFile == false) {    
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
        const response = await ExportsService.getSoulsByCountryLastUpdated({country: singleCountry, startDate: this.eventDateStart, endDate: this.eventDateEnd})
        this.souls = response.data
        this.exportSouls()
        }
      }
      this.souls = []
      loading.close()
    },
    async exportAllOrganisationsCountry() {
      let loading = Loading.service()
      if (this.oneFile == true) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
        const singleCountry = this.countriesSelected[index];
      const response = await ExportsService.getOrganisationsByCountry({country: singleCountry})
      this.orgs = this.orgs.concat(response.data)
      
      }
      this.exportOrgs()
      }
      if (this.oneFile == false) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
        const singleCountry = this.countriesSelected[index];
      const response = await ExportsService.getOrganisationsByCountry({country: singleCountry})
      
      this.orgs = response.data
      this.exportOrgs()
      }
      }
      this.orgs = []
      loading.close()
    },
    async exportAllOrganisationsDateCountry() {
      let loading = Loading.service()
      if (this.oneFile == true) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
        const response = await ExportsService.getOrganisationsByCountryLastUpdated({country: singleCountry, startDate: this.eventDateStart, endDate: this.eventDateEnd})
        this.orgs = this.orgs.concat(response.data)
        }
        this.exportOrgs()
      }
      if (this.oneFile == false) {    
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
        const response = await ExportsService.getOrganisationsByCountryLastUpdated({country: singleCountry, startDate: this.eventDateStart, endDate: this.eventDateEnd})
        
        this.orgs = response.data
        this.exportOrgs()
        }
      }
      loading.close
      this.souls = []
    },
    async exportAllReports() {
      let loading = Loading.service()
      const response = await ExportsService.getReports()
      this.reports = response.data
      this.parseReports()
      this.reports = []
      loading.close()
    },
    async exportAllReportsDate() { //Date Created
      let loading = Loading.service()
      const response = await ExportsService.getReportsByDate({startDate: this.eventDateStart, endDate: this.eventDateEnd})
      this.reports = response.data
      this.parseReports()
      this.reports = []
      loading.close()
    },
    async parseReports() {
      for (let index = 0; index < this.reports.length; index++) {
        let record = this.reports[index]
        record._id = new Date(parseInt(record._id.substring(0, 8), 16) * 1000)
      }
      this.exportReports()
    },
    async exportAllSourcesCountry() { // Where a date range is not specifed - all from countries selected
    let loading = Loading.service()
      if (this.oneFile == true) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
          const response = await ExportsService.getSourcesByCountry({country: singleCountry})
          this.sources = this.sources.concat(response.data)
        }
        this.exportSources()
      }
      if (this.oneFile == false) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
          const response = await ExportsService.getSourcesByCountry({country: singleCountry})
          this.sources = response.data
          this.exportSources()
        }
      }
      this.sources = []
      loading.close()
    },
    async exportAllSourcesDateCountry() { // By date record created and country
    let loading = Loading.service()
      if (this.oneFile == true) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
          const response = await ExportsService.getSourcesByCountryAndDate({country: singleCountry, startDate: this.eventDateStart, endDate: this.eventDateEnd})
          this.sources = this.sources.concat(response.data)
        }
        this.exportSources()
      }
      if (this.oneFile == false) {
        for (let index = 0; index < this.countriesSelected.length; index++) {
          const singleCountry = this.countriesSelected[index];
          const response = await ExportsService.getSourcesByCountryAndDate({country: singleCountry, startDate: this.eventDateStart, endDate: this.eventDateEnd})
          this.sources = response.data
          this.exportSources()
        }
      }
      this.events = []
      loading.close()
    },
    getDates(startDate, endDate) {
      let dates = []
      //to avoid modifying the original date
      let theDate = new Date(startDate)
      let stopDate = new Date(endDate)
      while (theDate < stopDate) {
        dates.push(new Date(theDate))
        theDate.setDate(theDate.getDate() + 1)
      }
      dates.push(new Date(stopDate))
      return dates
}





  }
}
</script>
