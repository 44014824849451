<template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
    <h5 class="info-text">
      Lets start with mandatory details:
    </h5>
    </div>
    <div class="col-md-8">
      <base-input :error="getError('eventDate')">
        <el-date-picker
          name="eventDate"
          id="eventDate"
          type="datetime"
          placeholder="Date Time Picker"
          v-model="eventDate"
          format="yyyy/MM/dd"
          value-format="yyyy-MM-dd"
          v-validate="modelValidations.eventDate"            
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </base-input>
    </div>
    <div class="col-md-8">
      <base-input
        name="title"
        id="title"
        placeholder="Title"
        v-model="title"
        v-validate="modelValidations.title"
        :error="getError('title')"
      >
      </base-input>
    </div>
    <div class="col-md-8">
      <base-input :error="getError('type')">
        <el-select
          name="type"
          id="type"
                  class="select-info"
                  size="medium"
                  placeholder="Type"
                  filterable
                  v-model="selects.type"
                  v-validate="modelValidations.type"
                >
                  <el-option
                    v-for="option in types"
                    class="select-info"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value"
                  >
                  </el-option>
                </el-select>
        </base-input>
    </div>
    <div class="col-md-8">
      <base-input :error="getError('country')">
        <el-select
          name="country"
          id="country"
          class="select-info"
          size="medium"
          placeholder="Country"
          filterable
          v-model="selects.country"
          v-validate="modelValidations.country"
        >
          <el-option
            v-for="option in countries"
            class="select-info"
            :value="option.search"
            :label="option.search"
            :key="option.search"
          >
          </el-option>
        </el-select>
      </base-input>
    </div>
  </div>
</template>

<script>
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import SearchService from '@/services/SearchService'

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  mounted() {
    this.getNations()
  },
  data() {
    return {
      eventDate: '',
      datetime:'',
      selects: {
        type:'',
        country:'',
      },
      types: [
          { value: 'Air Strike', label: 'Air Strike' },
          { value: 'Armed Attack', label: 'Armed Attack' },
          { value: 'Arrest', label: 'Arrest' },
          { value: 'IED', label: 'IED' },
          { value: 'VBIED', label: 'VBIED' },
          { value: 'Event', label: 'Event' },
          { value: 'Communication', label: 'Communication' },
          { value: 'Sighting', label: 'Sighting' },
          { value: 'Migration', label: 'Migration' },
          { value: 'Protest', label: 'Protest' },
          { value: 'Worker Strike', label: 'Worker Strike' },
          { value: 'Weapons Find', label: 'Weapons Find' },
          { value: 'Surrender', label: 'Surrender' },
          { value: 'Kidnapping', label: 'Kidnapping' },
          { value: 'Smuggling', label: 'Smuggling' },
          { value: 'Attack Planning', label: 'Attack Planning' },
          { value: 'Movement', label: 'Movement' },
          { value: 'Human Rights', label: 'Human Rights' },
          { value: 'Military Operation', label: 'Military Operation' },
          { value: 'Military Training', label: 'Military Training' },
          { value: 'Ground Control', label: 'Ground Control' },
        ],
      countries: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      title: '',
      modelValidations: {
        title: {required: true, min: 1},
        type: {required: true}, 
        eventDate: {required: true},
        country: {required: true},
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$emit("child-eventdate", this.eventDate);
      this.$emit("child-title", this.title);
      this.$emit("child-type", this.selects.type);
      this.$emit("child-country", this.selects.country);
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async getNations() {
      this.countries = window.GLOBALNATIONS
    },
  },
};
</script>
<style></style>
