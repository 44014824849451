// This Service provides CRU DB operations for Tribes

import Api from '@/services/Api'

const options = {
  headers: {'Authorization': window.GLOBALIDTOKEN}
}

export default {
  // Create new Tribe
  addTribe (params) {
    return Api().post('tribe/add' + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // Get all data for a Tribe
  getTribe (params) {
    return Api().get('tribe/get/' + params.id + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // Update a Tribe
  updateTribe (params) {
    return Api().put('tribe/update/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
}