import Api from '@/services/Api'

const options = {
  headers: {'Authorization': window.GLOBALIDTOKEN}
}

export default {
  // add me a person
  addSoul (params) {
    return  Api().post('soul/add' + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // update the person
  updateSoul (params) {
    return Api().put('soul/update/details/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // get the person
  getSoul (params) {
    return Api().get('soul/get/' + params.id + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // push a new rank
  updateRank (params) {
    return Api().put('soul/update/rank/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // push a new tribe
  updateTribe (params) {
    return Api().put('soul/update/tribe/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // push a new position
  updatePosition (params) {
    return Api().put('soul/update/position/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // replace a position that already exists
  replacePosition (params) {
    return Api().put('soul/replace/position/' + params.id + '/' + params.sid + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // replace an AKA that already exists
  replaceAKA (params) {
    return Api().put('soul/replace/aka/' + params.id + '/' + params.sid + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // replace a note that already exists
  replaceNote (params) {
    return Api().put('soul/replace/note/' + params.id + '/' + params.sid + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // replace a social media that already exists
  replaceSocialMedia (params) {
    return Api().put('soul/replace/socialmedia/' + params.id + '/' + params.sid + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // add a new image to the record
  updateSoulImage (params) {
    return Api().put('soul/create/image/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // add a new AKA to the record
  updateAKA (params) {
    return Api().put('soul/update/aka/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // add a new note to the record
  createNote (params) {
    return Api().put('soul/create/note/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // push new value to social media
  updateSocialMedia (params) {
    return Api().put('soul/update/socialmedia/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  // add a new basedIn to the record
  updateBasedIn (params) {
    return Api().post('soul/update/basedin/' + params.id + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
}