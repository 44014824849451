<template>
  <div class="row justify-content-center">
    <h5 class="info-text">
      What is the content of the report?
    </h5>
      <div class="col-sm-10">
        <base-input
          name="content"
          placeholder="Enter Report Here"
          v-model="content"
          v-validate="modelValidations.content"
          :error="getError('content')"
        >
        <textarea class="form-control" rows=8 v-model="content"></textarea>
        </base-input>

      </div>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
        content: '',
      modelValidations: {
        content: {
          required: true,
          min: 1
        },
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    }
  },
  watch: {
    content: function() {
      // Emit this information to the parent component
      this.$emit("child-content", this.content);
    }
  }
};
</script>
<style></style>
