<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard >
          <template slot="header">
            <h3 class="card-title">Add a New Report</h3>
            <h3 class="description">
              File a new report within the database. 
            </h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-caps-small"></i>
              <p>Name</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
              @child-title="titleValue"
            ></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-pencil"></i>
              <p>Contents</p>
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
              @child-content="contentValue"
            ></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p>Save</p>
            </template>
            <third-step ref="step3" 
              v-bind:propTitle="this.title" 
              v-bind:propContent="this.content" 
              @child-newReport="newReportValue"
              @on-validated="wizardComplete" ></third-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from './FirstStep.vue';
import SecondStep from './SecondStep.vue';
import ThirdStep from './ThirdStep.vue';
import swal from 'sweetalert2';
import { SimpleWizard, WizardTab } from 'src/components';

export default {
  data() {
    return {
      wizardModel: {},
      content: '',
      title: '',
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab,
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    titleValue: function(params) {
      this.title = params;
    },
    contentValue: function(params) {
      this.content = params;
    },
    newReportValue: function(params) {
      this.newReport = params;
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      swal({
          title: 'Report Created',
          text: 'Would you like to view this record or add another to the database?',
          type: 'success',
          showCancelButton: true,
          confirmButtonText: 'Add Another',
          cancelButtonText: 'View Report',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false
        }).then((result) => {
  if (result.value) {
    //do one thing
    this.$router.push({ name: 'Add Report', params: { id: randomNumber}})
  } else if (
    /* Read more about handling dismissals below */
    result.dismiss === swal.DismissReason.cancel
  ) {
    //do something else
    this.$router.push({ name: 'View Report', params: { id: this.newReport}})
  }
})
    }
  }
};
</script>
