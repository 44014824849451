<template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <h5 class="info-text">
        Lets start with mandatory details:
      </h5>
    </div>

    <div class="col-md-8">
      <base-input
        name="englishName"
        id="englishName"
        placeholder="English Name"
        v-model="englishName"
        v-validate="modelValidations.englishName"
        :error="getError('englishName')"
      />
    </div>

    <div class="col-md-8">
      <base-input
        name="arabicName"
        id="arabicName"
        placeholder="Arabic Name"
        v-model="arabicName"
        v-validate="modelValidations.arabicName"
        :error="getError('arabicName')"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      englishName: '',
      arabicName: '',
      modelValidations: {
        englishName: {required: true, min: 1},
        arabicName: {required: false}, 
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$emit("child-englishName", this.englishName);
      this.$emit("child-arabicName", this.arabicName);
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    }
  },
};
</script>
<style></style>
