<template>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <h5 class="info-text">
        What are the details of the source?
      </h5>
    </div>
    
    <div class="col-md-12 justify-content-center">

      <div class="form-horizontal justify-content-center"> 
        <div class="row justify-content-center">
          <div class="col-md-8">
            <base-input :error="getError('type')">
              <el-select
                name="type"
                id="type"
                class="select-info"
                size="medium"
                filterable
                placeholder="Type"
                v-model="selects.type"
                v-validate="modelValidations.type"
              >
                <el-option
                  v-for="option in types"
                  class="select-info"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="row  justify-content-center">
          <div class="col-md-8">
            <base-input
              name="affiliation"
              id="affiliation"
              placeholder="Affiliation"
              v-model="affiliation"
              v-validate="modelValidations.affiliation"
              :error="getError('affiliation')"
            />
          </div>
        </div>

        <div class="row justify-content-center">   
          <label class="col-md-2 col-form-label">YouTube</label> 
          <div class="col-md-6">
            <base-input
              name="text"
              placeholder=""
              v-model="youtube"
              addon-left-icon="fab fa-youtube"
            />
          </div>
        </div>

        <div class="row justify-content-center">   
          <label class="col-md-2 col-form-label">Instagram</label> 
          <div class="col-md-6">
            <base-input
              name="text"
              placeholder=""
              v-model="instagram"
              addon-left-icon="fab fa-instagram"
            />
          </div>
        </div>

        <div class="row justify-content-center">   
          <label class="col-md-2 col-form-label">Twitter</label> 
          <div class="col-md-6">
            <base-input
              name="text"
              placeholder=""
              v-model="twitter"
              addon-left-icon="fab fa-twitter"
            />
          </div>
        </div>

        <div class="row justify-content-center">   
          <label class="col-md-2 col-form-label">Website</label> 
          <div class="col-md-6">
            <base-input
              name="text"
              placeholder=""
              v-model="website"
              addon-left-icon="tim-icons icon-laptop"
            />
          </div>
        </div>

        <div class="row justify-content-center">   
          <label class="col-md-2 col-form-label">Facebook</label> 
          <div class="col-md-6">
            <base-input
              name="text"
              placeholder=""
              v-model="facebook"
              addon-left-icon="fab fa-facebook"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      affiliation: '',
      youtube: '',
      twitter: '',
      instagram: '',
      website: '',
      facebook: '',
      modelValidations: {
        content: {
          required: true,
          min: 1
        },
      },
      selects: {
        type:'',
      },
      types: [
          { value: 'News Outlet', label: 'News Outlet' },
          { value: 'Pro-Government Social Media', label: 'Pro-Government Social Media' },
          { value: 'Anti-Government Social Media', label: 'Anti-Government Social Media' },
          { value: 'Opposition Party', label: 'Opposition Party' },
          { value: 'Government', label: 'Government' },
          { value: 'Military', label: 'Military' },
          { value: 'Extremist', label: 'Extremist' },
          { value: 'Social Activist/NGO', label: 'Social Activist/NGO' },
          { value: 'Religous', label: 'Religous' },
          { value: 'Prominent Figure', label: 'Prominent Figure' },
        ],
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$emit("child-type", this.selects.type);
      this.$emit("child-affiliation", this.affiliation);
      this.$emit("child-youtube", this.youtube);
      this.$emit("child-facebook", this.facebook);
      this.$emit("child-instagram", this.instagram);
      this.$emit("child-website", this.website);
      this.$emit("child-twitter", this.twitter);
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    }
  },
};
</script>
<style></style>
