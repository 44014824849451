import Api from '@/services/Api'

const options = {
  headers: {'Authorization': window.GLOBALIDTOKEN}
}

export default {
  addReport (params) {
    return Api().post('report/add' + '/' + window.GLOBALUSERNAME, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getReport (params) {
    return Api().get('report/get/' + params.id + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
}

