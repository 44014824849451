<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard >
          <template slot="header">
            <h3 class="card-title">Add a New Source</h3>
            <h3 class="description">
              Add a new source to the database
            </h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-caps-small"></i>
              <p>Source</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
              @child-title="titleValue"
              @child-country="countryValue"
            ></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-pencil"></i>
              <p>Details</p>
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
              @child-type="typeValue"
              @child-affiliation="affiliationValue"
              @child-youtube="youtubeValue"
              @child-instagram="instagramValue"
              @child-twitter="twitterValue"
              @child-website="websiteValue"
              @child-facebook="facebookValue"
            ></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p>Save</p>
            </template>
            <third-step ref="step3" 
              v-bind:propTitle="this.title" 
              v-bind:propCountry="this.country" 
              v-bind:propType="this.type" 
              v-bind:propAffiliation="this.affiliation" 
              v-bind:propWebsite="this.website" 
              v-bind:propYoutube="this.youtube" 
              v-bind:propInstagram="this.instagram" 
              v-bind:propFacebook="this.facebook" 
              v-bind:propTwitter="this.twitter" 
              @on-validated="wizardComplete" 
              @child-newSource="newSourceValue"
            ></third-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from './FirstStep.vue';
import SecondStep from './SecondStep.vue';
import ThirdStep from './ThirdStep.vue';
import swal from 'sweetalert2';
import { SimpleWizard, WizardTab } from 'src/components';
import SourcesService from '@/services/SourcesService'

export default {
  data() {
    return {
      wizardModel: {},
      country: '',
      title: '',
      type: '',
      affiliation: '',
      youtube: '',
      instagram: '',
      website: '',
      twitter: '',
      facebook: '',
      newSource: '',
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab,
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    titleValue: function(params) {
      this.title = params;
    },
    countryValue: function(params) {
      this.country = params;
    },
    affiliationValue: function(params) {
      this.affiliation = params;
    },
    typeValue: function(params) {
      this.type = params;
    },
    youtubeValue: function(params) {
      this.youtube = params;
    },
    twitterValue: function(params) {
      this.twitter = params;
    },
    websiteValue: function(params) {
      this.website = params;
    },
    instagramValue: function(params) {
      this.instagram = params;
    },
    facebookValue: function(params) {
      this.facebook = params;
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    newSourceValue: function(params) {
      this.newSource = params;
    },
    wizardComplete() {
      if (this.newSource.status != 200) {
        swal({
          title: 'Creation Failed',
          text: 'Creation Failed. Please check your connection.',
          type: 'error',
          confirmButtonText: 'OK',
          confirmButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false
        })
      } else {
      swal({
          title: 'Source Created',
          text: 'Would you like to view this record or add another to the database?',
          type: 'success',
          showCancelButton: true,
          confirmButtonText: 'Add Another',
          cancelButtonText: 'View Source',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-info btn-fill',
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            //do one thing
            this.$router.push({ name: 'Add Source', params: { id: randomNumber}})
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === swal.DismissReason.cancel
          ) {
          //do something else
          this.$router.push({ name: 'View Source', params: { id: this.newSource.data._id}})
    
          }
      })
      }
    }
  }
}
</script>
